import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  Container,
  Grid,
  GridList,
  GridListTile,
  Paper,
  LinearProgress,
  Typography,
  Select,
  MenuItem,
  Badge,
} from "@material-ui/core";
import { webStore } from "../../stores/webStore";
import { DataGrid, ColDef, ValueGetterParams } from "@material-ui/data-grid";

import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useReadUserByGender } from "../../services/api/dashboard_report/useReadUserByGender";

import { useReadUserByAge } from "../../services/api/dashboard_report/useReadUserByAge";
import { useReadUser } from "../../services/api/user/useReadUser";
import { useListUser } from "../../services/api/user/useListUser";
import { useReadLengthOfStay } from "../../services/api/dashboard_report/useReadLengthOfStay";
import {
  Chart,
  PieSeries,
  Title,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";

import { Animation } from "@devexpress/dx-react-chart";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { CardTotalUserByGender } from "./CardTotalUserByGender";
import { CardNationality } from "./CardNationality";
import { CardAge } from "./CardAge";
import { CardLengthOfStay } from "./CardLengthOfStay";
import { useHistory } from "react-router-dom";
import { useListSOSUser } from "../../services/api/user/useListSOSUser";
import { useListSOSUserComplete } from "../../services/api/user/useListSOSUserComplete";
import { GetUsersNotSubmitDailyForm } from "../../services/api/user/GetUsersNotSubmit";
import { dateStr } from "../../utils";
import { User, UserSOS } from "../../types/user";
import WarningIcon from "@material-ui/icons/Warning";
import { DeviceValueTab, OnlineTab } from "./CardUserList";
import { userDevice } from "../../types/device";
import CheckIcon from "@material-ui/icons/Check";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Pagination from "@material-ui/lab/Pagination";
import { Places } from "../../types/places";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import DoneIcon from '@material-ui/icons/Done';
import { UserInfo } from "../../types/NotSubmitDaily";
import LuxonUtils from "@date-io/luxon";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import PhoneIcon from '@material-ui/icons/Phone';
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customBadge: {
      backgroundColor: "green",
      border: "2px solid #fff",
      width: 15,
      height: 15,
      borderRadius: "100%",
    },
  })
);
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const UserListItem = observer(
  ({
    user,
    border,
    updated_at,
    created_at
  }: {
    user: UserInfo;
    border?: boolean;
    updated_at?: any;
    created_at?: any;
  }) => {
    const history = useHistory();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
      
      <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py={2}
            borderBottom={"1px solid #ddd"}
            css={{
              cursor: "pointer",
              "@media (max-width: 992px)": { borderTop: 0 },
              "&:hover": {
                backgroundColor: "rgba(3,169,244,0.08) !important",
                borderTop: 0,
              },
            }}
          >
            {/* <a href={"/user/" + user?.id + "/detail"} onClick={() => {history.push("/user/" + user?.id + "/detail");}}> */}
            <Link to={"/user/" + user?.id + "/detail"} target="_blank">
              <Box display="flex" padding="2%" width="120%">
                  
                  <Avatar css={{ width: 32, height: 32 }} src={user?.avatar}>
                    {user?.name.substring(0, 1)}
                  </Avatar>

                <Box mx={3}>
                  <Box fontWeight={600} fontSize={13} display="flex">
                    {`${user?.name ? user?.name : "-"} ${user?.surname ? user?.surname : "-"}`}
                  </Box>
                  
                  <Box display="flex" fontSize={12} flexWrap="wrap">
                    <Box css={{ color: "#5B5B62" }}>
                      {`${
                        user?.gender ? 
                          user?.gender === "m"
                            ? "Male"
                          :user?.gender === "M"
                          ? "Male"
                          : user?.gender === "f"
                          ? "Female"
                          : user?.gender === "F"
                          ? "Female"
                          : "-"
                        : "-"
                      }
                  |
                  ${user?.onboard?.hotel ? user?.onboard?.hotel : user?.onboard?.hospital ? user?.onboard?.hospital :  "-"}
                  | 
                  `} <PhoneIcon style={{fontSize: '14px'}}/> {`${user?.phone_number ? user.phone_number : "-"}`}
                  
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Link>
                
            {/* </a> */}
            
            
            <Box>
                {/* <WarningIcon css={{ color: "#C83728" , marginRight: '20px'}} /> */}
                
                {/* <a href={"http://localhost:3000/daily-form?projectKey=" + localStorage.getItem("projectKey") + 
                '&userId='+ user?.id + "&projectType=isolation&language=th&user_status=dashboard"} target="_blank"
                > */}
                {/* <Link to={"https://form.pmhsolution.com/daily-form?projectKey=" + localStorage.getItem("projectKey") + 
                '&userId='+ user?.id + "&projectType=isolation&language=th"} target="_blank"> */}
                <a href={"https://form.pmhsolution.com/daily-form?projectKey=" + localStorage.getItem("projectKey") + 
                '&userId='+ user?.id + "&projectType=isolation&language=th&redirect=https://app.pmhsolution.com"} target="_blank"
                >
                  <Button  variant="contained" 
                    color="primary" onClick={()=>{}} 
                    style={{marginRight: '10px'}}>
                    Daily
                  </Button>
                </a> 
            </Box>
          </Box>
      </>
    );
  }
);

export const CardUserListNotDoForms = observer(({ fullDetail }: { fullDetail?: boolean }) => {
  const history = useHistory();
  const classes = useStyles();
  const [startDate, setStartDate] = useState<Date | null>(webStore.startDate || new Date());
  const [page, setPage] = useState<number>(1);
  const [pageAfteroon, setPageAfternoon] = useState<number>(1);
  const [pageEvening, setPageEvening] = useState<number>(1);
  const [pageComplete, setPageComplete] = useState<number>(1);
  const [pageCount, setPageCont] = useState<number>(0);
  const [pageCountComplete, setPageContComplete] = useState<number>(0);
  const [pageCountEvening, setPageCountEvening] = useState<number>(0);

  const [value, setValue] = React.useState(0);
  const [perPage] = useState<number>(4);
  const { result_userList, loading_userList} = GetUsersNotSubmitDailyForm(startDate);
  const [total, setTotal] = useState(0);
  const [totalAfternoon, setTotalAfternoon] = useState(0);
  const [totalEvening, setTotalEvening] = useState(0);
  useEffect(() => {
    if(result_userList){
      setTotal(result_userList?.morning?.length);
      setPageCont(Math.ceil(result_userList?.morning?.length / (perPage)));
      setTotalAfternoon(result_userList?.afternoon?.length);
      setPageContComplete(Math.ceil(result_userList?.afternoon?.length / (perPage)));
      setTotalEvening(result_userList?.evening?.length);
      setPageCountEvening(Math.ceil(result_userList?.evening?.length/(perPage)));
    }
    
  }, [result_userList]);

  console.log("userlist that don't submit form", result_userList);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeAfternoon = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageAfternoon(value);
  };

  const handleChangeEvening = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageEvening(value);
  };

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const onSearch = () => {
    webStore.setStartDate(startDate);
  };

  return (
    <Box
      css={{
        display: "flex",
        flex: 1,
        minHeight: "100%",
        flexFlow: "column",
        // flexDirection: "column",
        borderRadius: 10,
        padding: "0 !important",
        "@media (min-width: 991px)": { backgroundColor: "#fff" },
      }}
      className="relative"
    >
      <Box 
          style={{
          width: "100%",
          display: "flex",
          backgroundColor: "#fff",
        }}>
          <AppBar position="static" css={{zIndex: 0, backgroundColor: "unset !important", color: "black !important", boxShadow:"0px 0px 0px !important"}}>
            <Tabs variant="fullWidth" value={value} onChange={handleChangeTab} aria-label="simple tabs example">
              
                <Tab label="Morning" {...a11yProps(0)} />
                {
                  localStorage.getItem("projectKey") === 'r65nxurwl4rnoqlbv6n8jh2gxnddamhej11t6xml' || localStorage.getItem("projectKey") === 'xrsponolu71cznf0zr4pc9g3le5qghobbyigowog' 
                  || localStorage.getItem("projectKey") === 'g9cntq4kj1ylkuc812i4bf4qrv4fmk90b2jpf7zl' || localStorage.getItem("projectKey") === 'qk6oyhtc7c9ptbnffkd90xdpji2iye11v5lhw4qy'
                  || localStorage.getItem("projectKey") === 'pi7iotuircb2x90nqqizi4v91x0whrd5xgm42cro' || localStorage.getItem("projectKey") === '7zwp8sxengxpq37stixi4za1asmtm3fvx8dvkrzj'
                  ?
                  <Tab label="Evening" {...a11yProps(1)} />
                  :
                  localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                  <></>
                  :
                  <Tab label="Afternoon" {...a11yProps(1)} />
                }
               
              
                {
                  localStorage.getItem("projectKey") === 'r65nxurwl4rnoqlbv6n8jh2gxnddamhej11t6xml' || localStorage.getItem("projectKey") === 'xrsponolu71cznf0zr4pc9g3le5qghobbyigowog' 
                  || localStorage.getItem("projectKey") === 'g9cntq4kj1ylkuc812i4bf4qrv4fmk90b2jpf7zl' || localStorage.getItem("projectKey") === 'qk6oyhtc7c9ptbnffkd90xdpji2iye11v5lhw4qy'
                  || localStorage.getItem("projectKey") === 'pi7iotuircb2x90nqqizi4v91x0whrd5xgm42cro' || localStorage.getItem("projectKey") === '7zwp8sxengxpq37stixi4za1asmtm3fvx8dvkrzj'
                  || localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23'
                  ?
                  <></>
                  :
                  <Tab label="Evening" {...a11yProps(2)} />
                }

            </Tabs>
          </AppBar>
      </Box>
      <br/>
      <Box>
        <Grid container>
        <Grid item md={6} xs={12}>
              {/* <Button startIcon={<SaveAltIcon />} 
                  color="primary" size="medium" variant="contained" style={{float: 'right'}}
                  // onClick={()=> {
                  //   history.push("/user/" + userId + "/vitalSigns");
                  // }}
                  >
                      Export
              </Button> */}
          </Grid> 
          <Grid item md={6} xs = {12}> 
             <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <KeyboardDatePicker
                      style={{
                      margin: 0,
                      marginLeft: '20px',
                      width: "90%",
                      backgroundColor: "#fff",
                      }}
                      
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="from"
                      inputVariant="outlined"
                      label="Choose Date"
                      autoOk={true}
                      value={startDate}
                      onChange={(date) => {
                        setStartDate(date ? new Date(date.toString()) : null);
                      // onSearch();
                      }}
                      KeyboardButtonProps={{
                      "aria-label": "change date",
                      }}
                  />
              </MuiPickersUtilsProvider>
          </Grid>

          {/* <Grid item md={6} xs={12} css={{ paddingRight: "20px" }}>
              <Button startIcon={<SaveAltIcon />} 
                  color="primary" size="medium" variant="contained" style={{float: 'right'}}
                  // onClick={()=> {
                  //   history.push("/user/" + userId + "/vitalSigns");
                  // }}
                  >
                      Export
              </Button>
          </Grid>   */}
          {/* <Grid item xs={2} css={{ paddingRight: "20px" }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <IconButton onClick={onSearch} color="primary">
                <SearchIcon fontSize="large" />
              </IconButton>
            </Box>
          </Grid> */}
        </Grid>
        
      </Box>
      <TabPanel value={value} index={0}>
      {loading_userList && (
        <LinearProgress
          style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
        />
      )}
      <Box px={{ xs: 0, md: 0 }} css={{padding: "0px !important"}}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          // mb={2}
        >
          <Box fontSize={14} padding={0} fontWeight={600}>
            Users who don't submit Daily Forms
          </Box>
          <Box display="flex" alignItems="center" fontSize={14} fontWeight={600}>
              {total ? `Total ${total} Users` : ""}
          </Box>
        </Box>
        <Box
          css={{
            "@media (max-width: 992px)": {
              backgroundColor: "#fff",
              borderRadius: 5,
            },
          }}
          p={{ xs: 2, md: 0 }}
        >
        <Grid container>
            {(result_userList?.morning || []).slice((page - 1) * perPage, page * perPage)
            .map((userData, i) => {
              // sos 

                  return (
                    <Grid item md={12} xs={12}>
                      
                      <UserListItem 
                        user={userData}
                      />
                        
                      
                    </Grid>
                  );
                
            })}
          </Grid>
        </Box>
        {pageCount > 1 && (
          <Box mt={2} css={{marginTop: "8px !important"}}>
            <Pagination
              count={pageCount}
              page={page || 1}
              onChange={handleChange}
              size="small"
            />
          </Box>
        )}
      </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box px={{ xs: 0, md: 0 }} css={{padding: "0px !important"}}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            // mb={2}
          >
            <Box fontSize={14} padding={0} fontWeight={600}>
              Users who don't submit Daily Forms
            </Box>
            <Box display="flex" alignItems="center" fontSize={14} fontWeight={600}>
                {totalAfternoon ? `Total ${totalAfternoon} Users` : ""}
            </Box>
          </Box>
          <Box
            css={{
              "@media (max-width: 992px)": {
                backgroundColor: "#fff",
                borderRadius: 5,
              },
            }}
            p={{ xs: 2, md: 0 }}
          >
          <Grid container>
            {(result_userList?.afternoon || []).slice((pageAfteroon - 1) * perPage, pageAfteroon * perPage)
            .map((userData, i) => {
                
                return (
                  <Grid item md={12} xs={12}>
                    
                    <UserListItem 
                      user={userData}
                    />
                    
                  </Grid>
                );
                
            })}
          </Grid>
          </Box>
          {pageCountComplete > 1 && (
            <Box mt={2} css={{marginTop: "8px !important"}}>
              <Pagination
                count={pageCountComplete}
                page={pageAfteroon || 1}
                onChange={handleChangeAfternoon}
                size="small"
              />
            </Box>
          )}
        </Box>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Box px={{ xs: 0, md: 0 }} css={{padding: "0px !important"}}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            // mb={2}
          >
            <Box fontSize={14} padding={0} fontWeight={600}>
              Users who don't submit Daily Forms
            </Box>
            <Box display="flex" alignItems="center" fontSize={14} fontWeight={600}>
                {totalEvening ? `Total ${totalEvening} Users` : ""}
            </Box>
          </Box>
          <Box
            css={{
              "@media (max-width: 992px)": {
                backgroundColor: "#fff",
                borderRadius: 5,
              },
            }}
            p={{ xs: 2, md: 0 }}
          >
          <Grid container>
            {(result_userList?.evening || []).slice((pageEvening - 1) * perPage, pageEvening * perPage)
            .map((userData, i) => {
                
                return (
                  <Grid item md={12} xs={12}>
                    
                    <UserListItem 
                      user={userData}
                    />
                    
                  </Grid>
                );
                
            })}
          </Grid>
          </Box>
          {pageCountEvening > 1 && (
            <Box mt={2} css={{marginTop: "8px !important"}}>
              <Pagination
                count={pageCountEvening}
                page={pageEvening || 1}
                onChange={handleChangeEvening}
                size="small"
              />
            </Box>
          )}
        </Box>
      </TabPanel>
    </Box>
  );
});
