import { TempHistoryReport } from "../../../types/personalReport";
import { Agencies } from "../../../types/agencies";
import { useCallAPISpacetrax } from "../../useCallAPI";
import { UserNotSubmitDaily } from "../../../types/NotSubmitDaily";
import { dateStrFormat } from "../../../utils";


export const GetUsersNotSubmitDailyForm = (date?: any) => {
  var dd = String(date.getDate() - 1).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0');
  var yyyy = date.getFullYear();
  var dateParam =  dd + '/' + mm + '/' + yyyy;
      const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        data: UserNotSubmitDaily;
      }>
    ({
      url: `/v2/dashboard/user/daily/not-submit?start_date=` + dateStrFormat(date),
      method: "GET"
    });
    return {
      result_userList: res?.data || null,
      loading_userList: loading,
      error_userList: error,
    };
};
