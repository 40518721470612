import { SymptomsReport } from "../../../types/overviewReport";
import { useCallAPISpacetrax } from "../../useCallAPI";

export const GetSymptomCase = () => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: {
        symptoms_case: SymptomsReport
    };
  }>({
    url: `/v2/dashboard/symptoms-case`,
    method: "GET",
    // data: {
    //   startDate,
    //   endDate,
    // },
  });
  
  return {
    result_symptomsCase: res?.data?.symptoms_case,
    loading_symptomsCase: loading,
    error_symptomsCase: error,
  };
};

export const GetSymptomCasePrevious = () => {
  var new_date = new Date();
  var dd = String(new_date.getDate() - 1).padStart(2, '0');
  var mm = String(new_date.getMonth() + 1).padStart(2, '0');
  var yyyy = new_date.getFullYear();
  var get_yesterday =  dd + '/' + mm + '/' + yyyy;

  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: {
        symptoms_case: SymptomsReport
    };
  }>({
    url: `/v2/dashboard/symptoms-case?search_start=` + get_yesterday + `&search_end=` + get_yesterday,
    method: "GET",
    // data: {
    //   startDate,
    //   endDate,
    // },
  });
  return {
    result_symptomsCase_previous: res?.data?.symptoms_case,
    loading_symptomsCase_previous: loading,
    error_symptomsCase_previous: error,
  };
};
