import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { webStore } from "../../../stores/webStore";
import { Profile,Projects } from "../../../types/profile";
import { resolveMockup } from "../../../utils";
import { useCallAPI,useCallAPISpacetrax } from "../../useCallAPI";

// const MOCKUP_PROFILE: Profile = {
//   // id: "id",
//   // username: "username",
//   // display_name: "display_name",
//   // logo: "https://material-ui.com/static/logo_raw.svg",
//   // avatar: "https://material-ui.com/static/images/avatar/1.jpg",
// };
// export const useMe = () => {
//   console.log("me", localStorage.getItem("token"));
//   return {
//     result_me: localStorage.getItem("token") && MOCKUP_PROFILE,
//     loading_me: false,
//     error_me: false,
//   };
// };

export const useMe = () => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: Profile;
  }>({
    url: `/v2/auth/user`,
    // url: `/api/v2/users/me`,
    method: "GET",
  });
  
  if(res?.data) {
    localStorage.setItem("projectType", res?.data?.project[0]?.type);
    if(!localStorage.getItem("userId")){
      localStorage.setItem("userId", res?.data?.id);
    }
  }
  if(res?.data?.project){
    if(!localStorage.getItem("projectKey")){
      localStorage.setItem("projectKey", res?.data?.project[0].project_key);
      // console.log("projectKey:", localStorage.getItem("projectKey"));
    }
  }
  // let user = result_project;
  let user = res?.data;
  if (user) {
    // user.logo = "https://material-ui.com/static/logo_raw.svg";
    // user.avatar = "https://material-ui.com/static/images/avatar/1.jpg";
  }
  return {
    result_me: localStorage.getItem("token") ? user : null,
    loading_me: loading,
    error_me: error,
  };
};

export const GetProject = () => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: Projects;
  }>({
    url: `/v2/dashboard/project`,
    // url: `/v2/auth/user`,
    // url: `/api/v2/users/me`,
    method: "GET",
  });

  let user = res?.data;
  return {
    result_project: res?.data ? user : null,
    loading_project: loading,
    error_project: error,
  };
};

// url: `/api/v2/users/me`,
