import React, { Fragment, useEffect, useState } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  Box,
  Button,
  CardActionArea,
  Container,
  Grid,
  Paper,
  Typography,
  LinearProgress,
  Divider,
  Tab,
  Tabs,
  List,
} from "@material-ui/core";
import { DataGrid, ColDef, ValueGetterParams } from "@material-ui/data-grid";
import { webStore } from "../../stores/webStore";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import classes from "*.module.css";
import { GetSymptomCase, GetSymptomCasePrevious } from "../../services/api/dashboard_report/GetSymptomCase";
import { Doughnut, Pie, Bar } from "react-chartjs-2";
import { GRAPH_COLOR } from "../../data/graphColor";
import { PaginationItem, Pagination, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { CardTopValue } from "./Dashboard";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ContactSupportOutlined } from "@material-ui/icons";
import { columnsTotalWidthSelector } from "@material-ui/data-grid";
import Moment from 'moment';

function tapsStyles() {
  return {
    indicator: {
      backgroundColor: "#fff",
    },
  };
}

export interface customeLabels {
  data: any;
  labels: any;
  color: any;
  showValue?: boolean;
  itemsCountPerPage?: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const CardSymptoms = observer(() => {
  const { result_symptomsCase, loading_symptomsCase } = GetSymptomCase();

  const { result_symptomsCase_previous, loading_symptomsCase_previous } = GetSymptomCasePrevious();
  console.log("result_symptomsCase_previous", result_symptomsCase_previous);

  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  
  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        flex: 1,
        minHeight: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
        backgroundColor: "#fff",
      }}
    >
      {loading_symptomsCase && (
        <LinearProgress
          style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
        />
      )}
      <Box style={{ width: "100%" }} p={2}>
        <Box 
          style={{
          width: "100%",
          display: "flex",
          flex: 1,
          minHeight: "100%",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "center",
          borderRadius: 5,
          backgroundColor: "#fff",
        }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab style={{ minWidth: "72px" }} label="Previous" {...a11yProps(0)} />
            <Tab style={{ minWidth: "72px" }} label="Current" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box display={{ sm: "none", xs: "none", md: "block" }}>
            <Typography gutterBottom>
              <Box textAlign="right" fontSize={14}>
                {Moment().subtract(1, 'd').locale('en').format('MMM DD, YYYY')}
              </Box>
              <Box fontWeight="bold" textAlign="center">
                <p>Symptoms Case</p>
              </Box>
              <Box
                fontWeight="bold"
                textAlign="center"
                lineHeight={"36px"}
                fontSize={14}
              >
                {result_symptomsCase_previous?.all_total} (People)
              </Box>
              <Box
                fontWeight="bold"
                textAlign="center"
                mb={3}
                css={{ color: "#14AF5C" }}
              >
              </Box>
            </Typography>
          </Box>
          <Box display={{ xs: "block", md: "none" }}>
            <Typography style={{ fontWeight: "bold" }}>{result_symptomsCase_previous?.all_total} (People)</Typography>
          </Box>
          <Grid container>
            <Grid item md={12} xs={12}>
            
            {
              localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
              <>
                <Bar
                  width={100}
                  height={50}
                  legend={{ position: "bottom", display: false }}
                  options={{
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              // autoSkip: false,
                              beginAtZero: true,
                              stepSize: 5,
                              max: Math.ceil(Number(result_symptomsCase_previous?.all_total)/5)*5
                            },
                          },
                        ],
                      }
                  }}
                  data={{
                    datasets: [
                      {
                        label: 'Green',
                        backgroundColor: '#4CAF50',
                        borderColor: '#4CAF50',
                        borderWidth: 1,
                        hoverBackgroundColor: '#4CAF50',
                        hoverBorderColor: '#4CAF50',
                        barPercentage: 0.75,
                        categoryPercentage: 0.5,
                        data: [
                          result_symptomsCase_previous?.morning?.green
                        ],
                      },
                      {
                        label: 'Yellow',
                        backgroundColor: '#ffca25',
                        borderColor: '#ffca25',
                        borderWidth: 1,
                        hoverBackgroundColor: '#ffca25',
                        hoverBorderColor: '#ffca25',
                        barPercentage: 0.75,
                        categoryPercentage: 0.5,
                        data: [
                          result_symptomsCase_previous?.morning?.yellow
                        ]
                      },
                    ],
                    labels: ['Morning Period'],
                  }}
                />
              </>
              :
              <Bar
                width={100}
                height={50}
                legend={{ position: "bottom", display: false }}
                options={{
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            // autoSkip: false,
                            beginAtZero: true,
                            stepSize: 5,
                            max: Math.ceil(Number(result_symptomsCase_previous?.all_total)/5)*5
                          },
                        },
                      ],
                    }
                }}
                data={{
                  datasets: [
                    {
                      label: 'Green',
                      backgroundColor: '#4CAF50',
                      borderColor: '#4CAF50',
                      borderWidth: 1,
                      hoverBackgroundColor: '#4CAF50',
                      hoverBorderColor: '#4CAF50',
                      barPercentage: 0.75,
                      categoryPercentage: 0.5,
                      data: [
                        result_symptomsCase_previous?.morning?.green, 
                        result_symptomsCase_previous?.afternoon?.green, 
                        result_symptomsCase_previous?.evening?.green
                      ],
                    },
                    {
                      label: 'Yellow',
                      backgroundColor: '#ffca25',
                      borderColor: '#ffca25',
                      borderWidth: 1,
                      hoverBackgroundColor: '#ffca25',
                      hoverBorderColor: '#ffca25',
                      barPercentage: 0.75,
                      categoryPercentage: 0.5,
                      data: [
                        result_symptomsCase_previous?.morning?.yellow, 
                        result_symptomsCase_previous?.afternoon?.yellow, 
                        result_symptomsCase_previous?.evening?.yellow
                      ]
                    },
                  ],
                  labels: ['Morning Period', 'Afternoon Period', 'Evening Period'],
                }}
              />
            }
            

            </Grid>
            
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box display={{ sm: "none", xs: "none", md: "block" }}>
            <Typography gutterBottom>
              <Box textAlign="right" fontSize={14}>
                {Moment().locale('en').format('MMM DD, YYYY')}
              </Box>
              <Box fontWeight="bold" textAlign="center">
                Symptoms Case
              </Box>
              <Box
                fontWeight="bold"
                textAlign="center"
                lineHeight={"36px"}
                fontSize={14}
              >
                {result_symptomsCase?.all_total} (People)
              </Box>
              <Box
                fontWeight="bold"
                textAlign="center"
                mb={3}
                css={{ color: "#14AF5C" }}
              >
              </Box>
            </Typography>
          </Box>
          <Box display={{ xs: "block", md: "none" }}>
            <Typography style={{ fontWeight: "bold" }}>{result_symptomsCase?.all_total} (People)</Typography>
          </Box>
          <Grid container>
            <Grid item md={12} xs={12}>
              {
                localStorage.getItem("projectKey") === 'nxc36q17q08prg4hkxuda2igf1k701tjame3df23' ?
                  <Bar
                    width={100}
                    height={50}
                    legend={{ position: "bottom", display: false }}
                    options={{
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              stepSize: 5,
                              max: Math.ceil(Number(result_symptomsCase?.all_total)/5)*5
                            },
                          },
                        ],
                      }
                    }}
                    data={{
                      datasets: [
                        {
                          label: 'Green',
                          backgroundColor: '#4CAF50',
                          borderColor: '#4CAF50',
                          borderWidth: 1,
                          hoverBackgroundColor: '#4CAF50',
                          hoverBorderColor: '#4CAF50',
                          barPercentage: 0.75,
                          categoryPercentage: 0.5,
                          data: [
                            result_symptomsCase?.morning?.green
                          ]
                        },
                        {
                          label: 'Yellow',
                          backgroundColor: '#ffca25',
                          borderColor: '#ffca25',
                          borderWidth: 1,
                          hoverBackgroundColor: '#ffca25',
                          hoverBorderColor: '#ffca25',
                          barPercentage: 0.75,
                          categoryPercentage: 0.5,
                          data: [
                            result_symptomsCase?.morning?.yellow
                          ]
                        },
                      ],
                      labels: ['Morning Period'],
                    }}
                  />
                :
                <Bar
                    width={100}
                    height={50}
                    legend={{ position: "bottom", display: false }}
                    options={{
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              stepSize: 5,
                              max: Math.ceil(Number(result_symptomsCase?.all_total)/5)*5
                            },
                          },
                        ],
                      }
                    }}
                    data={{
                      datasets: [
                        {
                          label: 'Green',
                          backgroundColor: '#4CAF50',
                          borderColor: '#4CAF50',
                          borderWidth: 1,
                          hoverBackgroundColor: '#4CAF50',
                          hoverBorderColor: '#4CAF50',
                          barPercentage: 0.75,
                          categoryPercentage: 0.5,
                          data: [
                            result_symptomsCase?.morning?.green, 
                            result_symptomsCase?.afternoon?.green, 
                            result_symptomsCase?.evening?.green
                          ]
                        },
                        {
                          label: 'Yellow',
                          backgroundColor: '#ffca25',
                          borderColor: '#ffca25',
                          borderWidth: 1,
                          hoverBackgroundColor: '#ffca25',
                          hoverBorderColor: '#ffca25',
                          barPercentage: 0.75,
                          categoryPercentage: 0.5,
                          data: [
                            result_symptomsCase?.morning?.yellow, 
                            result_symptomsCase?.afternoon?.yellow, 
                            result_symptomsCase?.evening?.yellow
                          ]
                        },
                      ],
                      labels: ['Morning Period', 'Afternoon Period', 'Evening Period'],
                    }}
                  />

              }
            

            </Grid>
            
          </Grid>
        </TabPanel>
      </Box>
    </Box>
  );
});
