import { MentalReportFilter } from "../../../types/user";
import {MentalSymptoms} from "../../../types/MentalSymptoms";
import { useCallAPISpacetrax } from "../../useCallAPI";

const ChangeDateFormat = (dateData : any) =>{
  let data = dateData.toString();
  let splitData;
  let dateFormat : any;
  let splitDate;
  let month;
  let day;
  let year;
  
    splitData = data.split(" ");
    splitDate = data.split(" ");
    year = splitDate[3];
    day = splitDate[2];
    if (splitDate[1] ===  'Jan'){
      month = '01';
    } else if (splitDate[1] ===  'Feb'){
      month = '02';
    } else if (splitDate[1] ===  'Mar'){
      month = '03';
    } else if (splitDate[1] ===  'Apr'){
      month = '04';
    }  else if (splitDate[1] ===  'May'){
      month = '05';
    }else if (splitDate[1] ===  'Jun'){
      month = '06';
    }else if (splitDate[1] ===  'Jul'){
      month = '07';
    }else if (splitDate[1] ===  'Aug'){
      month = '08';
    }else if (splitDate[1] ===  'Sep'){
      month = '09';
    }else if (splitDate[1] ===  'Oct'){
      month = '10';
    }else if (splitDate[1] ===  'Nov'){
      month = '11';
    }else {
      month = '12';
    }
    let checkYear = parseInt(year);
    if (checkYear< 2564){
      let buddishtYear = checkYear + 543;
      year = buddishtYear.toString();
    }
    
  dateFormat = day + "/" + month + "/" + year;
  return dateFormat;
}

export const MentalReportList = (filter?: MentalReportFilter) => {
  if(filter?.filter_assessmentCount === "0"){
    filter.filter_assessmentCount = "";
  }
  if(filter?.filter_result === "0"){
    filter.filter_result = "";
  }
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: {
      total?: number;
      mental_health?: MentalSymptoms[];
    };
  }>({
    url: `/v2/dashboard/user/mental-list`,
    method: "GET",
    params: {
      page: filter?.page,
      limit: filter?.perPage,
      search_start: ChangeDateFormat(filter?.startDate),
      search_end: ChangeDateFormat(filter?.endDate),
      result: filter?.filter_result,
      assesment_number: filter?.filter_assessmentCount
    },
  });
  
  return {
    result_mentalSymptoms: res?.data?.mental_health || [],
    total: res?.data?.total || 0,
    loading_mentalSymptoms: loading,
    error_mentalSymptoms: error,
  };
};
