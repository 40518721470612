import { resolveMockup } from "../../../utils";
import { useCallAPI, useCallAPISpacetrax } from "../../useCallAPI";

export const useRequestOTP_Mock = () => {
  const [{ data, loading, error }, fire] = useCallAPI<{
    data: { phone_number: string; ref_code: string };
  }>(
    {
      url: `/auth/request_otp`,
      method: "POST",
    },
    { manual: true }
  );
  return {
    result_request_otp: data?.data.phone_number,
    result_request_otp_ref: data?.data.ref_code,
    loading_request_otp: loading,
    error_request_otp: error,
    fire_request_otp: (username: string, password: string) => {
      return resolveMockup({
        data: { data: { otpTel: "********95", refCode: "112" } },
      });
      return fire({
        data: {
          username,
          password,
        },
      });
    },
  };
};

// export const useRequestOTP = () => {
//   const [{ data, loading, error }, fire] = useCallAPI<{
//     data?: { phone_number: string; ref_code: string };
//   }>(
//     {
//       url: `/api/v1/login-otp`,
//       method: "POST",
//     },
//     { manual: true }
//   );
//   return {
//     result_request_otp: data?.data?.phone_number,
//     result_request_otp_ref: data?.data?.ref_code,
//     loading_request_otp: loading,
//     error_request_otp: error,
//     fire_request_otp: (username: string, password: string) => {
//       return fire({
//         data: {
//           username,
//           password,
//         },
//       });
//     },
//   };
// };

export const useRequestOTP = () => {
  const [{ data, loading, error }, fire] = useCallAPISpacetrax<{
    data?: { username: string; ref_code: string, secret_code: string };
  }>(
    {
      url: `/v2/auth/login`,
      method: "POST",
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //  },
    },
    { manual: true }
  );
  return {
    result_request_otp: data?.data?.username,
    result_request_otp_ref: data?.data?.ref_code,
    result_request_otp_secret: data?.data?.secret_code,
    loading_request_otp: loading,
    error_request_otp: error,
    fire_request_otp: (username: string, password: string) => {
      var formData = new FormData();
        formData.append('username', username.toString());   //append the values with key, value pair
        formData.append('password', password.toString());
      return fire({
        data: formData
      });
    },
  };
};

// fire_privacy_create: (PrivacyCreateInput: PrivacyCreateInput) => {
//   var formData = new FormData();
//   formData.append('force_status', PrivacyCreateInput.force_status.toString());   //append the values with key, value pair
//   formData.append('is_login', PrivacyCreateInput.is_login.toString());
//   formData.append('is_new_policy', PrivacyCreateInput.is_new_policy.toString());
//   formData.append('message', PrivacyCreateInput.message.toString());
//   formData.append('version', PrivacyCreateInput.version.toString());
// return new Promise((resolve, reject) => {
//   fire({
//     data: formData,
//   })
//     .then(({ data: { data, code, message } }) => {
//       if (code === 0) {
//         reject(message);
//       } else {
//         resolve(data);
//       }
//     })
//     .catch((ex) => {
//       reject(ex.toString());
//     });
// });

// var details = {
//   'username': username,
//   'password': password
// };
// var formBody = [];

// for (var property in details) {
//   var encodedKey = encodeURIComponent(property);
//   var encodedValue = encodeURIComponent(details[property]);
//   formBody.push(encodedKey + "=" + encodedValue);
// }
// formBody = formBody.join("&");
