import useAxios, { Options } from "axios-hooks";
import { API_PATH, API_PATH_GO } from "../utils/const";
// import { API_PATH } from "../utils/const";

import axios, { AxiosRequestConfig } from "axios";

export const callAPI = (config: AxiosRequestConfig, options?: Options) => {
  return axios({
    ...{
      baseURL: API_PATH,
      method: "GET",
      ...config,
      headers: {
        "Content-Type": "application/json",
        "Project-Key": localStorage.getItem("projectKey"),
        Authorization: "Bearer " + localStorage.getItem("token"),
        ...(config?.headers || {}),
      },
      data: config.data || {},
    },
    ...{ ...options, useCache: false },
  });
};

export const useCallAPI = <T>(config: AxiosRequestConfig, options?: Options) =>
  useAxios<T>(
    {
      baseURL: API_PATH,
      method: "GET",
      ...config,
      headers: {
        "Content-Type": "application/json",
        "Project-Key": localStorage.getItem("projectKey"),
        Authorization: "Bearer " + localStorage.getItem("token"),
        ...(config?.headers || {}),
      },
      data: config.data || {},
    },
    { ...options, useCache: false }
  );

  export const useCallAPILocal = <T>(config: AxiosRequestConfig, options?: Options) =>
  useAxios<T>(
    {
      baseURL: API_PATH,
      method: "GET",
      ...config,
      headers: {
        "Content-Type": "application/json",
        "Project-Key": localStorage.getItem("projectKey"),
        Authorization: "Bearer " + localStorage.getItem("token"),
        ...(config?.headers || {}),
      },
      data: config.data || {},
    },
    { ...options, useCache: false }
  );

  export const callAPILocal = (config: AxiosRequestConfig, options?: Options) => {
    return axios({
      ...{
        baseURL: API_PATH,
        method: "GET",
        ...config,
        headers: {
          "Content-Type": "application/json",
          "Project-Key": localStorage.getItem("projectKey"),
          Authorization: "Bearer " + localStorage.getItem("token"),
          ...(config?.headers || {}),
        },
        data: config.data || {},
      },
      ...{ ...options, useCache: false },
    });
  };

  export const useCallAPISpacetrax = <T>(config: AxiosRequestConfig, options?: Options) =>
  useAxios<T>(
    {
      baseURL: API_PATH_GO,
      method: "GET",
      ...config,
      headers: {
         "Content-Type": "application/json",
        "Project-Key": localStorage.getItem("projectKey"),
        Authorization: "Bearer " + localStorage.getItem("token"),
        ...(config?.headers || {}),
      },
      data: config.data || {},
    },
    { ...options, useCache: false }
  );

  export const callAPISpacetrax = (config: AxiosRequestConfig, options?: Options) => {
    return axios({
      ...{
        baseURL: API_PATH_GO,
        method: "GET",
        ...config,
        headers: {
          "Content-Type": "application/json",
          "Project-Key": localStorage.getItem("projectKey"),
          Authorization: "Bearer " + localStorage.getItem("token"),
          ...(config?.headers || {}),
        },
        data: config.data || {},
      },
      ...{ ...options, useCache: false },
    });
  };