import React, { Fragment, useEffect, useState,useRef } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  Container,
  Grid,
  GridList,
  GridListTile,
  Paper,
  LinearProgress,
  Typography,
  Select,
  MenuItem,
  Badge,
} from "@material-ui/core";
import { webStore } from "../../stores/webStore";
import { DataGrid, ColDef, ValueGetterParams } from "@material-ui/data-grid";

import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";

import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useListSOSUser } from "../../services/api/user/useListSOSUser";
import { useListSOSUserComplete } from "../../services/api/user/useListSOSUserComplete";
import { dateStr } from "../../utils";
import WarningIcon from "@material-ui/icons/Warning";
import { DeviceValueTab, OnlineTab } from "./CardUserList";
import { userDevice } from "../../types/device";
import CheckIcon from "@material-ui/icons/Check";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Pagination from "@material-ui/lab/Pagination";
import { Places } from "../../types/places";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useListAllUser } from "../../services/api/user/useListAllUser";
import {UserItem,UserItemDischarge } from "./CardUserListSI";
import { Filter, User, UserSOS, UsersQuarantine,Value,ReloadValue,SmartInfirmaryUserList } from "../../types/user";

import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customBadge: {
      backgroundColor: "green",
      border: "2px solid #fff",
      width: 15,
      height: 15,
      borderRadius: "100%",
    },
  })
);
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export const CardUserListByStatusSI = observer(({
  result_userList,
  loading_userList,
  result_userList_waiting,
  loading_userList_waiting,
  total = 0,
  total_waiting = 0,
  filter,
  mapRef,
  setPage,
  setPageWaiting, 
  setValue,
  setFilterWaiting,
  valueTab
}: {
  result_userList: SmartInfirmaryUserList[];
  loading_userList: boolean;
  result_userList_waiting: SmartInfirmaryUserList[];
  loading_userList_waiting: boolean;
  total?: number;
  total_waiting?: number;
  mapRef?: any;
  filter?: Filter;
  valueTab?: Value;
  setPage: (pn: number) => void;
  setPageWaiting: (pn : number) => void;
  setValue: (pn: number) => void;
  setFilterWaiting: (filter: string) => void;
}) => {
  const history = useHistory();

  const [userLiser, setUserLiser] = useState<SmartInfirmaryUserList[]>([]);
  const [userLiserWaiting, setUserLiserWaiting] = useState<SmartInfirmaryUserList[]>([]);
  const [mapRefLat, setMapRefLat] = useState<String>();
  const [filterUser, setFilterUser] = useState<string>("oldest_first");
  // const [pageWaiting, setPageWaiting] = useState<number>(1);
  // const [ reload_value, setReload ] = useState<boolean>(false);

  // const reloadValue: ReloadValue = {
  //   reload: reload_value
  // }\


  useEffect(() => {
    setUserLiser(result_userList);
    setUserLiserWaiting(result_userList_waiting)
  }, [result_userList,result_userList_waiting]);

  const pageCount = Math.ceil(total / (filter?.perPage || 10));
  const pageCountWaiting = Math.ceil(total_waiting/ 10);
  
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    webStore.setFilterUserListPage(value);
  };

  const handleChangeWaiting = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageWaiting(value);
    webStore.setFilterWaitingListPage(value);
  }

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    webStore.setListValueTab(newValue);
  };

  return (
    <Box
      css={{
        display: "flex",
        flex: 1,
        minHeight: "100%",
        flexFlow: "column",
        // flexDirection: "column",
        borderRadius: 10,
        padding: "0 !important",
        "@media (min-width: 991px)": { backgroundColor: "#fff" },
      }}
      className="relative"
    >
      <AppBar position="static" css={{zIndex: 0, backgroundColor: "unset !important", color: "black !important", boxShadow:"0px 0px 0px !important"}}>
        <Tabs variant="fullWidth" value={valueTab?.value} onChange={handleChangeTab} aria-label="simple tabs example">
          <Tab label="Active" {...a11yProps(0)} />
          <Tab label="Discharge" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={valueTab?.value} index={0}>
      {loading_userList && (
        <LinearProgress
          style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
        />
      )}
      <Box px={{ xs: 0, md: 0 }} css={{padding: "0px !important"}}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          // mb={2}
        >
          {
            total ? 
            <>
              <Box fontSize={14} padding={0} fontWeight={600}>
                {/* {fullDetail ? `Total ${total} User` : `SOS user`} */}
                {`Total ${total} User`}
              </Box>
              <Box display="flex" alignItems="center" fontSize={14}>
                <Box mr={1}>Sort by</Box>
                <Select
                  labelId="label"
                  id="select"
                  value="oldest_first"
                  disableUnderline
                  css={{ fontSize: 14, fontFamily: "inherit", color: "#4684E2" }}
                >
                  <MenuItem value="oldest_first">Oldest first</MenuItem>
                </Select>
              </Box>
            </>:
            <></>
          }
          
        </Box>
        <Box>
        {(userLiser || []).map((user, i) => {
              var index =
                ((filter?.page || 1) - 1) * (filter?.perPage || 10) + i + 1;
              return (
                <div
                  onMouseLeave={() => {
                    mapRef &&
                      mapRef.current &&
                      mapRef.current.reset &&
                      mapRef.current.reset();
                  }}
                >
                  {
                    user ? 
                      <UserItem user={user} index={index} borderTop={i !== 0} />
                    :
                    <>
                      <div
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                          position: "absolute",
                          opacity: "0.5",
                        }}
                      >
                          There is no user info.
                      </div> 
                    </>
                  }
                </div>
              );
            })}
        </Box>
        {pageCount > 1 && (
          <Box mt={2} css={{marginTop: "8px !important"}}>
            <Pagination
              count={pageCount}
              page={filter?.page || 1}
              onChange={handleChange}
              size="small"
            />
          </Box>
        )}
      </Box>
      </TabPanel>
      <TabPanel value={valueTab?.value} index={1}>
          {loading_userList_waiting && (
            <LinearProgress
              style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
            />
          )}
        <Box px={{ xs: 0, md: 0 }} css={{padding: "0px !important"}}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            // mb={2}
          >
            {
              total_waiting ? 
              <>
                <Box fontSize={14} padding={0} fontWeight={600}>
                  {`Total ${total_waiting} User`}
                </Box>
              </>
              :
              <>
                
              </>
            }
            
          </Box>
          <Box
            css={{
              "@media (max-width: 992px)": {
                backgroundColor: "#fff",
                borderRadius: 5,
              },
            }}
            p={{ xs: 2, md: 0 }}
          >
          {(userLiserWaiting || []).map((user, i) => {
              var index =
                ((filter?.waitingPage || 1) - 1) * (filter?.waitingPerPage || 10) + i + 1;
              return (
                <div
                  onMouseLeave={() => {
                    mapRef &&
                      mapRef.current &&
                      mapRef.current.reset &&
                      mapRef.current.reset();
                  }}
                >
                  {
                    user? 
                    <>
                      <UserItemDischarge 
                        user={user} 
                        index={index} 
                        quarantine_result_status= {3}
                        id={user.onboard?.id}
                        borderTop={i !== 0}
                       />
                        
                    </>:
                    <>
                      <div
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                          position: "absolute",
                          opacity: "0.5",
                        }}
                      >
                          There is no user info.
                      </div> 
                    </>
                  }
                  
                </div>
              );
            })}
          </Box>
          {pageCountWaiting > 1 && (
            <Box mt={2} css={{marginTop: "8px !important"}}>
              <Pagination
                count={pageCountWaiting}
                page={filter?.waitingPage || 1}
                onChange={handleChangeWaiting}
                size="small"
              />
            </Box>
          )}
        </Box>
      </TabPanel>
    </Box>
  );
});
