import { DailyReportFilter, User} from "../../../types/user";
import {DailySymptoms} from "../../../types/DailySymptoms";
import { useCallAPISpacetrax } from "../../useCallAPI";

const ChangeDateFormat = (dateData : any) =>{
  let data = dateData.toString();
  let splitData;
  let dateFormat : any;
  let splitDate;
  let month;
  let day;
  let year;
  
    splitData = data.split(" ");
    splitDate = data.split(" ");
    year = splitDate[3];
    day = splitDate[2];
    if (splitDate[1] ===  'Jan'){
      month = '01';
    } else if (splitDate[1] ===  'Feb'){
      month = '02';
    } else if (splitDate[1] ===  'Mar'){
      month = '03';
    } else if (splitDate[1] ===  'Apr'){
      month = '04';
    }  else if (splitDate[1] ===  'May'){
      month = '05';
    }else if (splitDate[1] ===  'Jun'){
      month = '06';
    }else if (splitDate[1] ===  'Jul'){
      month = '07';
    }else if (splitDate[1] ===  'Aug'){
      month = '08';
    }else if (splitDate[1] ===  'Sep'){
      month = '09';
    }else if (splitDate[1] ===  'Oct'){
      month = '10';
    }else if (splitDate[1] ===  'Nov'){
      month = '11';
    }else {
      month = '12';
    }
    let checkYear = parseInt(year);
    if (checkYear< 2564){
      let buddishtYear = checkYear + 543;
      year = buddishtYear.toString();
    }
  dateFormat = day + "/" + month + "/" + year;
  return dateFormat;
}

export const DailyReportList = (filter?: DailyReportFilter) => {
  if(filter?.filter_period === "0"){
    filter.filter_period = "";
  }
  if(filter?.filter_symptomCase === "0"){
    filter.filter_symptomCase = "";
  }
  if(filter?.filter_temperature === "0"){
    filter.filter_temperature = "";
  }
  if(filter?.filter_symptom === "0"){
    filter.filter_symptom = "";
  }
  if(filter?.filter_spo2 === "0"){
    filter.filter_spo2 = "";
  }
  
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: {
      total?: number;
      daily_symptoms?: DailySymptoms[];
    };
  }>({
    url: `/v2/dashboard/user/daily-list`,
    method: "GET",
    params: {
      page: filter?.page,
      limit: filter?.perPage,
      search_start: ChangeDateFormat(filter?.startDate),
      search_end: ChangeDateFormat(filter?.endDate),
      assesment_period: filter?.filter_period,
      symptoms_case: filter?.filter_symptomCase,
      temperature: filter?.filter_temperature,
      is_abnormal: filter?.filter_symptom,
      SPO2: filter?.filter_spo2
    },
  });

 
//   search_start=17/10/2564&search_end=18/10/2564
    // &assesment_period=1&symptoms_case=1&temperature=1&is_abnormal=true&SPO2=1&page=1&limit=100

  return {
    result_dailySymptoms: res?.data?.daily_symptoms || [],
    total: res?.data?.total || 0,
    loading_dailySymptoms: loading,
    error_dailySymptoms: error,
  };
};

export const DailyReport_List = (filter?: DailyReportFilter) => {
  
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
      data?: {
        total?: number;
        daily_symptoms?: DailySymptoms[];
      };
    }>({
      url: `/v2/dashboard/user/daily-list`,
      method: "GET"
    });
  
  //   search_start=17/10/2564&search_end=18/10/2564
      // &assesment_period=1&symptoms_case=1&temperature=1&is_abnormal=true&SPO2=1&page=1&limit=100
  
    return {
      result_dailySymptoms: res?.data?.daily_symptoms || [],
      total: res?.data?.total || 0,
      loading_dailySymptoms: loading,
      error_dailySymptoms: error,
    };
  };
