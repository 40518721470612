import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  TextareaAutosize,
  Avatar
} from "@material-ui/core";
import { Filter } from "../../types/user";
import { webStore } from "../../stores/webStore";
import { useHistory } from "react-router-dom";
import AssignmentIcon from '@material-ui/icons/Assignment';
import {
  withStyles,
  Theme,
  makeStyles
} from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import MentalIcon from "../../components/assets/Mental_0.png";
import TransparentMentalIcon from "../../components/assets/Mental2.png";
import DailyIcon from "../../components/assets/Daily_0.png";
import TransparentDailyIcon from "../../components/assets/Daily2.png";
import { useParams } from "react-router-dom";
import { GetNoteList,ContactTracerHistroyLog } from "../../services/api/user/useReadUser";
import { SubBar } from "../Dashboard/Dashboard";
import Pagination from "@material-ui/lab/Pagination";
import { dateStr, dateStrAPI, timeStr, dateStrAPIFormat,DateTimeString } from "../../utils";
import  BlueUserIcon  from '../../components/assets/BlueUserIcon.jpg';
import  RedUserIcon  from '../../components/assets/RedUserIcon.jpg';

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    // color: theme.palette.getContrastText('#3f51b5'),
    backgroundColor: '#3f51b5',
    color: '#fff',
    
    '&:hover': {
      backgroundColor: '#314993',
      color: '#fff'
    },
  },
}))(Button);



export const ContactHistory = observer((props: any) => {
  const { userId }: any = useParams();
  const { startDate }: any = useParams();
  const { endDate }: any = useParams();

  const { result_historyLog, loading_historyLog } = ContactTracerHistroyLog(userId, startDate, endDate);

  useEffect(() => {
    return () => {
      webStore.resetFilter();
    };
  }, []);
  const mapRef = useRef<any>();
  const [page, setPage] = useState<number>(1);
  const history = useHistory();
  const filter: Filter = {
    startDate: webStore.startDate || new Date(),
    endDate: webStore.endDate || new Date(),
    filter_temp: webStore.filter_temp,
    filter_wear: webStore.filter_wear,
    filter_battery: webStore.filter_battery,
    filter_yacht_name: webStore.filter_yacht_name,
    filter_Keyword: webStore.filter_Keyword,
    page: page,
    perPage: 10,
  };

  return (
    <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16">
        {loading_historyLog && (
          <LinearProgress
            style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
          />
        )}

        <Box mb={2}>
            <Grid item xs={12}>
                <SubBar title={"Contact Tracer History"} reload={false} back={true}  />
            </Grid>
        </Box>
        <Grid container>
        {(result_historyLog?.social || []).map((contactInfo : any) => {
                    
                    return (
                    <Box
                        css={{ backgroundColor: "#fff", 
                        width: "100%", 
                        borderRadius: 40, 
                        border: 'solid 2px #3f51b5',
                        "&:hover": {
                            backgroundColor: "rgba(3,169,244,0.08)",
                            borderTop: 3,
                          },
                       }}
                        p={2}
                        mb={1}
                        mt={1}
                        display="flex"
                        onClick={() => {
                          if(contactInfo?.user?.id){
                            history.push("/user/" + contactInfo?.user?.id + "/preview");
                          }else{
                            history.push("/user/" + "userId_empty" + "/preview");
                          }
                          
                        }}
                    >
                      <Box pr={4}
                      >
                        {
                          contactInfo.distance<=2 ?
                            <Avatar css={{ width: 40, height: 40 }} src={RedUserIcon}>
                            </Avatar>
                            :
                            <Avatar css={{ width: 40, height: 40 }} src={BlueUserIcon}>
                            </Avatar>
                          
                        }
                        
                      </Box>

                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <Box mb={2} position="relative">
                          <Box
                              display="flex"
                              alignItems="center"
                              css={{ color: "#35353F" }}
                            >
                              <Box fontWeight={600} fontSize={14} mr={1}>
                                {`${contactInfo?.user?.name} ${contactInfo?.user.surname}`}
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box>
                              {
                                contactInfo?.distance <= 2 ?
                                 <><p style={{fontSize: '14px', textAlign:"right", color: 'red'}}>Warning ! </p>
                                 <p style={{fontSize: '12px', textAlign:"right", color: 'gray'}}>{DateTimeString(contactInfo?.BTUtcTime)}</p></>
                                :
                                <><p style={{fontSize: '12px', textAlign:"right", color: 'gray'}}>{DateTimeString(contactInfo?.BTUtcTime)}</p></>
                              }
                            </Box>
                            
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <p style={{fontSize: '14px', color:"gray", fontWeight:"bold"}}>IMEI </p>
                          <p style={{fontSize: '14px'}}> {contactInfo?.device?.imei} </p>
                          
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <p style={{fontSize: '14px', color:"gray", fontWeight:"bold"}}>Phone No. </p>
                          <p style={{fontSize: '14px'}}> {contactInfo?.user?.phone_number ? contactInfo?.user?.phone_number : "-"} </p>
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                        <Grid item xs={12} md={4}>
                          <p style={{fontSize: '14px', color:"gray", fontWeight:"bold"}}>Distance </p>
                          <p style={{fontSize: '14px'}}> {contactInfo?.distance} m </p>

                        </Grid>
                        <Grid item xs={12} md={4}>
                          <p style={{fontSize: '14px', color:"gray", fontWeight:"bold"}}>Latitude </p>
                          <p style={{fontSize: '14px'}}> {contactInfo?.device?.latitude} </p>

                        </Grid>
                        <Grid item xs={12} md={4}>
                          <p style={{fontSize: '14px', color:"gray", fontWeight:"bold"}}>Longitude </p>
                          <p style={{fontSize: '14px'}}> {contactInfo?.device?.longitude} </p>
                        </Grid>
                      </Grid>

                  </Box>
                    );
                })}
        </Grid>
    </Container>
  );
});