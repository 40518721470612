import { observer } from "mobx-react-lite";
import { webStore } from "../../../stores/webStore";
import { Filter, User, UserFromAPI, Value, ReloadValue } from "../../../types/user";
import { userDevice } from "../../../types/device";
import { useCallAPI, useCallAPISpacetrax } from "../../useCallAPI";
import { processServerUser } from "./useReadUser";
import { Places } from "../../../types/places";
import { Oximeter } from "../../../types/oximeter";

export const useListAllUser = (filter?: Filter, reloadValue?: ReloadValue, changeState?: boolean) => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: {
      total?: number;
      user_quarantine?: {
        id: string;
        device?: userDevice;
        place?: Places;
        oximeter?: Oximeter;
        length_of_stay: number;
        date_end: Date;
        date_start: Date;
        quarantine_result_status: number;
        user: User;
      }[];
    };
  }>({
    url: `/v2/dashboard/user/list`,
    method: "GET",
    // data: {
    //   filter: filter,
    // },
    params: {
      page: filter?.page || 1,
      limit: filter?.perPage || 10,
      reload: reloadValue?.reload,
      changeState: changeState
    },
  });

  return {
    result_userList: res?.data?.user_quarantine || [],
    total: res?.data?.total || 0,
    loading_userList: loading,
    error_userList: error,
  };
};

export const useListAllUserWaiting = (filter?: Filter, valueTab?: Value, reloadValue?: ReloadValue) => {
  
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: {
      total?: number;
      user_quarantine?: {
        id: string;
        device?: userDevice;
        place?: Places;
        oximeter?: Oximeter;
        length_of_stay: number;
        date_end: Date;
        date_start: Date;
        quarantine_result_status: number;
        user: User;
      }[];
    };
  }>({
    url: `/v2/dashboard/user/list-status/${valueTab?.filter_waiting}`,
    method: "GET",
    // data: {
    //   filter: filter,
    // },
    params: {
      page: filter?.waitingPage || 1,
      limit: filter?.waitingPerPage || 10,
      reload: reloadValue?.reload
    },
  });

  return {
    result_userList_waiting: res?.data?.user_quarantine || [],
    total_waiting: res?.data?.total || 0,
    loading_userList_waiting: loading,
    error_userList_waiting: error,
  };
};

export const AddMacAddressService = () => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
      code: 0 | 1;
      message: string;
      data : any;
    }>(
      {
        url:  `/v2/BTGatewayService/GeoLocBTReceiver`,
        method: "POST",
      },
      {
        manual: true,
      }
    );
  
    return {
      result_addMacAddress: res?.code === 1 ? res?.data : null,
      loading_addMacAddress: loading,
      error_addMacAddress: res?.code === 0 ? res?.message : null,
      fire_addMacAddress: (macAddress: any, lat: any, long: any, accuracy: any) => {
        let inputData = {};
        inputData = { 
          "cbid": macAddress, 
          "accuracy": accuracy,
          "lng": long, 
          "lat": lat, 
          } 
        
        return new Promise((resolve, reject) => {
          fire({
            data: inputData,
          })
            .then(({ data: { data, code, message } }) => {
              if (code === 0) {
                reject(message);
              } else {
                resolve(data);
              }
            })
            .catch((ex) => {
              reject(ex.toString());
            });
        });
      },
    };
}
