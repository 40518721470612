import React, { Fragment, useEffect, useState } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  Avatar,
  Box,
  Button,
  CardActionArea,
  CircularProgress,
  Container,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Modal,
  Typography,
  TextField
} from "@material-ui/core";
import { webStore } from "../../stores/webStore";
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
} from "@material-ui/data-grid";

import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import classes from "*.module.css";
import { useReadUserByGender } from "../../services/api/dashboard_report/useReadUserByGender";
import { useSnackbar } from "notistack";

import { useReadUserByAge } from "../../services/api/dashboard_report/useReadUserByAge";
import { useReadUser } from "../../services/api/user/useReadUser";
import { useListUser } from "../../services/api/user/useListUser";
import { useReadLengthOfStay } from "../../services/api/dashboard_report/useReadLengthOfStay";
import {
  UpdateQuarantineStatus
} from "../../services/api/user/useReadUser";
import {
  Chart,
  PieSeries,
  Title,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";

import { Animation } from "@devexpress/dx-react-chart";
import { makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import { CardTotalUserByGender } from "./CardTotalUserByGender";
import { CardNationality } from "./CardNationality";
import { CardAge } from "./CardAge";
import { CardLengthOfStay } from "./CardLengthOfStay";
import { useHistory } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { dateStr, dateStrAPIFormat,DateTimeString } from "../../utils";
import Pagination from "@material-ui/lab/Pagination";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import WifiIcon from "@material-ui/icons/Wifi";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import FavoriteIcon from "../../components/assets/favorite.svg";
import OxygenIcon from "../../components/assets/Oxygen.svg";
import TemperatureIcon from "../../components/assets/temperature.svg";
import BloodIcon from "../../components/assets/blood.svg";
import BatteryIconFull from "../../components/assets/noun_Battery_3408796.svg";
import BatteryIconHigh from "../../components/assets/noun_Battery_-1.svg";
import BatteryIconMedium from "../../components/assets/noun_Battery_-2.svg";
import BatteryIconLow from "../../components/assets/noun_Battery_-3.svg";
import BatteryIconLoss from "../../components/assets/noun_Battery_-4.svg";
import LengthOfStayIcon from "../../components/assets/LengthOfStay.svg";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Filter, User, UserFromAPI, UsersQuarantine,SmartInfirmaryUserList, SIuserListRaw,Health, Ward } from "../../types/user";
import { userDevice } from "../../types/device";
import { useListAllUser } from "../../services/api/user/useListAllUser";
import { Places } from "../../types/places";
import { Oximeter } from "../../types/oximeter";
import axios, { AxiosRequestConfig, Method } from 'axios';
import { AddMacAddressService } from "../../services/api/user/useListAllUser";

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    
    '&:hover': {
      backgroundColor: '#314993',
      color: '#fff'
    },
  },
}))(Button);


const ConfirmStatusModal = observer(
  ({
    isConfirm,
    onClick,
    setIsConfirm,
    status,
    buttonName = "CONFIRM",
  }: {
    isConfirm: boolean;
    setIsConfirm: any;
    onClick?: any;
    status: any;
    buttonName?: string;
  }) => {

    return (
      <Modal
        open={isConfirm}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 350, borderRadius: 5 }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box css={{ color: "#4B4C56" }} fontSize={14}>

            </Box>
            <Box
              onClick={() => {
                setIsConfirm(false);
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>
          <Box
            mt ={2}
          >
            <p style={{ color:'grey', textAlign: 'center', fontSize: '18px'}}>Status will be changed to </p>
            <p style={{ color:'#3f51b5', textAlign: 'center', fontSize: '18px'}}> {status} .</p>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <Button
              onClick={() => {
                setIsConfirm(false);
              }}
              size="medium"
              variant="outlined"
            >
              CANCEL
            </Button>

            <Button
              onClick={onClick}
              color="primary"
              size="medium"
              variant="outlined"
              style={{marginLeft: '3%'}}
            >
              {buttonName}
            </Button> 
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);

const DeviceValue = observer(
  ({
    icon,
    value,
    position,
  }: {
    icon: any;
    value: string;
    position?: string;
  }) => {
    return (
      <Box
        display={{ md: "flex" }}
        alignItems="center"
        css={{ color: "#595960", height: "100%" }}
        justifyContent={"flex-start"}
        textAlign="center"
      >
        <Box
          justifyContent="center"
          display="flex"
          mb={{ xs: 0.5, md: 0 }}
          height={{ xs: 14, md: "100%" }}
        >
          <img
            src={icon}
            css={{
              "@media (max-width: 992px)": { maxHeight: 14, maxWidth: 14 },
            }}
          />
        </Box>
        <Box ml={0.5} fontSize={13}>
          {value}
        </Box>
      </Box>
    );
  }
);

export const OnlineTab = observer(
  ({ user, device }: { user: User; device?: userDevice }) => {
    return (
      <Box display="flex" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          mr={3}
          css={{
            color: device?.device_status === 3  ? "#C4C4C4" 
                  :device?.device_status === 4 ? "#C4C4C4"
                  :device?.device_status === 9 ? "#C4C4C4"
                  :"#10940E"
          }}
        >
          <Box
            css={{
              width: 10,
              height: 10,
              borderRadius: "50%",
              backgroundColor:
              device?.device_status === 3  ? "#C4C4C4" 
              :device?.device_status === 4 ? "#C4C4C4"
              :device?.device_status === 9 ? "#C4C4C4"
              :"#10940E"
            }}
          ></Box>
          <Box ml={1} fontSize={12}>
            {/* {device?.device_status === 1 ? "Wearing" : "Not Wear"} */}
            {
               device?.device_status === 3  ? "Offline"
              :device?.device_status === 4 ? "Not Wear"
              :device?.device_status === 9 ? "Offline & Not Wear"
              :"Wearing"
            }
          </Box>
        </Box>

        {device?.device_status === 3 ? (
          <Box display="flex" alignItems="center" css={{ color: "#C4C4C4" }}>
            <WifiOffIcon css={{ width: 15 }} />
            <Box ml={1} fontSize={12}>
              Offline
            </Box>
          </Box>
        ) 
        :device?.device_status === 4 ? (
          <Box display="flex" alignItems="center" css={{ color: "#C4C4C4" }}>
            <WifiOffIcon css={{ width: 15 }} />
            <Box ml={1} fontSize={12}>
              Offline
            </Box>
          </Box>
        )
        :device?.device_status === 9 ? (
          <Box display="flex" alignItems="center" css={{ color: "#C4C4C4" }}>
            <WifiOffIcon css={{ width: 15 }} />
            <Box ml={1} fontSize={12}>
              Offline
            </Box>
          </Box>
        )
        : (
          <Box display="flex" alignItems="center" css={{ color: "#10940E" }}>
            <WifiIcon css={{ width: 15 }} />
            <Box ml={1} fontSize={12}>
              Online
            </Box>
          </Box>
        )}
      </Box>
    );
  }
);

export const DeviceValueTab = observer(
  ({ device, oximeter }: { device?: userDevice, oximeter? : Oximeter }) => {
    var iconBattery;
    if(typeof device !== 'undefined'){
      if(device.battery?.toString() < "0"){
        iconBattery = BatteryIconLoss;
      }else if(device.battery?.toString() === "0"){
        iconBattery = BatteryIconLoss;
      }else if(device.battery?.toString() === "1"){
        iconBattery = BatteryIconLow;
      }else if(device.battery?.toString() === "2"){
        iconBattery = BatteryIconMedium;
      }else if(device.battery?.toString() === "3"){
        iconBattery = BatteryIconHigh;
      }else if(device.battery?.toString() === "4"){
        iconBattery = BatteryIconFull;
      }else if(device.battery?.toString() > "4"){
        iconBattery = BatteryIconFull;
      }
    }

    return (
      <Grid container>
         <Grid item xs={2} style={{ maxWidth: "4.5rem" }}>
          {/* {oximeter?.blood_oxygen ? (
            <DeviceValue
              icon={OxygenIcon}
              value={oximeter?.blood_oxygen.toString()}
            />
          ) : (
            <DeviceValue icon={OxygenIcon} value={"?"} />
          )} */}
          {device?.oxygen ? (
            <DeviceValue
              icon={OxygenIcon}
              value={device?.oxygen.toString()}
            />
          ) : (
            <DeviceValue icon={OxygenIcon} value={"?"} />
          )}
        </Grid>
        <Grid item xs={2} style={{ maxWidth: "4.5rem" }}>
          {device?.body_temp ? (
            <DeviceValue
              icon={TemperatureIcon}
              value={device?.body_temp.toString()}
            />
          ) : (
            <DeviceValue icon={TemperatureIcon} value={"?"} />
          )}
        </Grid>
        <Grid item xs={2} style={{ maxWidth: "4.5rem" }}>
          {device?.heart_rate ? (
            <DeviceValue
              icon={FavoriteIcon}
              value={device?.heart_rate.toString()}
            />
          ) : (
            <DeviceValue icon={FavoriteIcon} value={"?"} />
          )}
        </Grid>
        <Grid item xs={3} style={{ maxWidth: "6rem" }}>
          <DeviceValue
            icon={BloodIcon}
            value={`${device?.blood_systolic || "?"} / ${
              device?.blood_diastolic || "?"
            }`}
          />
        </Grid>
        <Grid item xs={2} style={{ maxWidth: "3rem" }}>
          <DeviceValue
            icon={iconBattery}
            value={``}
            position="right"
          />
        </Grid>
      </Grid>
    );
  }
);

const VibrateCallAPI = (macAddress: any) => {
  let instance = axios.create({  baseURL: "https://api.space-trax.com" }); 
  const method = 'POST';

  const url = "https://api.space-trax.com/v2/vibrate/" + macAddress;
  const headers = {
    "Content-Type": "application/json",
  }
  let options: AxiosRequestConfig = { 
    url,
    method,
    responseType: 'json',
    headers
  };

  return instance.request<any>(options)
  .then(response => { 
      console.log(response);
  });
}

export const UserItemInsomniac = observer(
  ({
    user,
    device,
    place,
    oximeter,
    borderTop,
    index,
    mapRefLat,
    lengthOfStay,
    showVibrate

  }: {
    user: User;
    device?: userDevice;
    place?: Places;
    oximeter?: Oximeter;
    index: number;
    borderTop?: boolean;
    mapRefLat?: String;
    lengthOfStay?: number;
    showVibrate?: Boolean;
  }) => {
    const history = useHistory();
    const [background, setBackground] = useState<String>();
    const [ isAddIbeacon,setIsAddIbeacon ] = useState(false);
    let dropzoneStyle
    if(mapRefLat){
      if(mapRefLat.toString() === device?.latitude?.toString() && device?.latitude?.toString() !== "0"){
        dropzoneStyle = {
          backgroundColor: "rgba(3,169,244,0.08)"
        };
      }else{
        dropzoneStyle = {
          backgroundColor: "unset"
        };
      }
    }
    return (
      <Box
        className="relative"
        css={{
          backgroundColor: "#fff",
          borderRadius: 5,
          cursor: "pointer",
          "@media (max-width: 992px)": { marginBottom: 15 },
        }}
      >
        <Box
          display="flex"
          py={2}
          px={{ xs: 2, md: 0 }}
          borderTop={borderTop ? "1px solid #ddd" : 0}
          style={dropzoneStyle}
          css={{
            opacity: device?.latitude && device?.longitude ? 1 : 0.5,
            "@media (max-width: 992px)": { borderTop: 0 },
            "&:hover": {
              backgroundColor: "rgba(3,169,244,0.08) !important",
              borderTop: 0,
            },
          }}
        >
          <Box pr={2}
            onClick={() => {
              if(user.id){
                history.push("/user/" + user.id + "/preview");
              }else{
                history.push("/user/" + "userId_empty" + "/preview");
              }
              
            }}
          >
            <Avatar css={{ width: 32, height: 32 }} src={user?.avatar}>
              {user?.name.substring(0, 1)}
            </Avatar>
          </Box>
          <Grid container>
            <Grid item xs={12} md={6}
              onClick={() => {
                if(user.id){
                  history.push("/user/" + user.id + "/preview");
                }else{
                  history.push("/user/" + "userId_empty" + "/preview");
                }
                
              }}
            >
              <Box mb={2} position="relative">
                <Box
                  display="flex"
                  alignItems="center"
                  css={{ color: "#35353F" }}
                >
                  <Box fontWeight={600} fontSize={14} mr={1}>
                    {`${user?.name} ${user.surname}`}
                  </Box>
                  {user?.isSos && (
                    <ReportProblemIcon
                      css={{
                        color: "#C83728",
                        width: 16,
                        height: 16,
                        marginRight: "0.3em",
                      }}
                    />
                  )}
                  <Box position="relative">
                    <Box
                      className="pin"
                      css={{
                        "--color":
                          device?.device_status === 3 ? "red" 
                          : device?.device_status === 4 ? "red" 
                          : device?.device_status === 9 ? "red" 
                          : "green",
                      }}
                    >
                      {index.toString()}
                    </Box>
                  </Box>
                </Box>
                <Box fontSize={12} css={{ color: "#5B5B62" }}>
                  {`${
                    user.gender === "m"
                    ? "Male"
                    :user.gender === "M"
                    ? "Male"
                    :user.gender === "male"
                    ? "Male"
                    : user.gender === "f"
                    ? "Female"
                    : user.gender === "F"
                    ? "Female"
                    :user.gender === "female"
                    ? "Female"
                    : "-"
                  }
              | ${user.nationality} | ${place?.name_en} | ${device?.mac_address ? device?.mac_address : ""}`} 
                </Box>
              </Box> 
            </Grid>
            <Grid item xs={12} md={2}>
              {
                showVibrate ?
                <Button  variant="contained"  color="primary"
                  onClick={() => {
                    VibrateCallAPI(device?.mac_address);
                  }}
                > 
                  Vibrate 
                </Button>
                :
                <></>
              } 
            </Grid>
            <Grid item md={4}
              onClick={() => {
                if(user.id){
                  history.push("/user/" + user.id + "/preview");
                }else{
                  history.push("/user/" + "userId_empty" + "/preview");
                }
              }}
            >
              <Box
                fontSize={12}
                textAlign="right"
                css={{ color: "rgba(30, 30, 30, 0.5)" }}
                display={{ xs: "none", md: "block" }}
              >
                {device?.updated_at && dateStr(new Date(device?.updated_at))}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          display={{ xs: "flex", md: "none" }}
          px={2}
          py={1}
          justifyContent="space-between"
          alignItems="center"
          css={{
            opacity: device?.latitude && device?.longitude ? 1 : 0.5,
            "@media (max-width: 992px)": { borderTop: "1px solid #ddd" },
          }}
          onClick={() => {
            if(user.id){
              history.push("/user/" + user.id + "/preview");
            }else{
              history.push("/user/" + "userId_empty" + "/preview");
            }
            
          }}
        >
          <OnlineTab user={user} device={device} />
          <Box
            fontSize={12}
            textAlign="right"
            css={{ color: "rgba(30, 30, 30, 0.5)" }}
            onClick={() => {
              if(user.id){
                history.push("/user/" + user.id + "/preview");
              }else{
                history.push("/user/" + "userId_empty" + "/preview");
              }
            }}
          >
            {device?.updated_at && dateStr(new Date(device?.updated_at))}
          </Box>
        </Box>
      </Box>
    );
  }
);

export const UserItemWaiting = observer(
  ({
    user,
    date_end,
    date_start,
    quarantine_result_status,
    device,
    place,
    id,
    oximeter,
    borderTop,
    index,
    mapRefLat,
    lengthOfStay

  }: {
    user: User;
    date_end: Date;
    date_start: Date;
    quarantine_result_status: number;
    device?: userDevice;
    place?: Places;
    id: any;
    oximeter?: Oximeter;
    index: number;
    borderTop?: boolean;
    mapRefLat?: String;
    lengthOfStay?: number;
  }) => {
    const history = useHistory();
    const [background, setBackground] = useState<String>();
    const [isConfirm, setIsConfirm] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { fire_quarantineStatus_update, loading_quarantineStatus_update } = UpdateQuarantineStatus(id);

    let dropzoneStyle
    if(mapRefLat){
      if(mapRefLat.toString() === device?.latitude?.toString() && device?.latitude?.toString() !== "0"){
        dropzoneStyle = {
          backgroundColor: "rgba(3,169,244,0.08)"
        };
      }else{
        dropzoneStyle = {
          backgroundColor: "unset"
        };
      }
    }
    const getNextResultText = (nextResult : any) =>{
      let next_result;
       if(nextResult === 2){
         next_result = "In Quarantine"
       }else if (nextResult === 3){
         next_result = "Complete"
       }
       return next_result;
    }

    const getNextResult = (nextResult : any) =>{
      let next_result = "";
       if(nextResult === 2){
         next_result = "1"
       }else if (nextResult === 3){
         next_result = "4"
       }
       return next_result;
    }

    return (
      <Box
        className="relative"
        css={{
          backgroundColor: "#fff",
          borderRadius: 5,
          cursor: "pointer",
          "@media (max-width: 992px)": { marginBottom: 15 },
        }}
      >
        <Box
          display="flex"
          py={2}
          px={{ xs: 2, md: 0 }}
          borderTop={borderTop ? "1px solid #ddd" : 0}
          style={dropzoneStyle}
          css={{
            opacity: device?.latitude && device?.longitude ? 1 : 0.5,
            "@media (max-width: 992px)": { borderTop: 0 },
            "&:hover": {
              backgroundColor: "rgba(3,169,244,0.08) !important",
              borderTop: 0,
            },
          }}
        >
          <Box pr={2} pl={2}
            onClick={() => {
              if(user.id){
                history.push("/user/" + user.id + "/preview");
              }else{
                history.push("/user/" + "userId_empty" + "/preview");
              }
              
            }}
          >
            <Avatar css={{ width: 32, height: 32}} src={user?.avatar}>
              {user?.name.substring(0, 1)}
            </Avatar>
          </Box>
          <Grid container>
            <Grid item xs={12} md={5}
              onClick={() => {
                if(user.id){
                  history.push("/user/" + user.id + "/preview");
                }else{
                  history.push("/user/" + "userId_empty" + "/preview");
                }
                
              }}
            >
              <Box mb={2} position="relative">
                <Box
                  display="flex"
                  alignItems="center"
                  css={{ color: "#35353F" }}
                >
                  <Box fontWeight={600} fontSize={14} mr={1}>
                    {`${user?.name} ${user.surname}`}
                  </Box>
                  {/* {user?.isSos && (
                    <ReportProblemIcon
                      css={{
                        color: "#C83728",
                        width: 16,
                        height: 16,
                        marginRight: "0.3em",
                      }}
                    />
                  )} */}
                  <Box position="relative">
                    <Box
                      className="pin"
                      css={{
                        "--color":
                          device?.device_status === 3 ? "red" 
                          : device?.device_status === 4 ? "red" 
                          : device?.device_status === 9 ? "red" 
                          : "green",
                      }}
                    >
                      {index.toString()}
                    </Box>
                  </Box>
                </Box>
                <Box fontSize={12} css={{ color: "#5B5B62" }}>
                  {`${
                    user.gender === "m"
                    ? "Male"
                    :user.gender === "M"
                    ? "Male"
                    :user.gender === "male"
                    ? "Male"
                    : user.gender === "f"
                    ? "Female"
                    : user.gender === "F"
                    ? "Female"
                    :user.gender === "female"
                    ? "Female"
                    : "-"
                  }
              | ${user.nationality} | ${place?.name_en ? place?.name_en: "-"}`}
                </Box>
              </Box>
            </Grid>
            <Grid item md={4} style={{marginTop: "4%", float: "left"}}
             onClick={() => {
              if(user.id){
                history.push("/user/" + user.id + "/preview");
              }else{
                history.push("/user/" + "userId_empty" + "/preview");
              }
              
            }}
            >
              <Box
                fontSize={12}
                display={{ xs: "none", md: "block" }}
              >
                <b>
                Status: { quarantine_result_status === 1 ? "In Quarantine"
                        : quarantine_result_status === 2 ? "Waiting For Quarantine"
                        : quarantine_result_status === 3 ? "Waiting For Apporve"
                        : quarantine_result_status === 4 ? "Complete"
                        : quarantine_result_status === 5 ? "Re-Quarantine"
                        : "?"
                }
                </b>
              </Box>
            </Grid>
            <Grid item md={3} style={{marginTop: "2%"}}>
                  <ColorButton
                    variant="outlined"
                    color="primary"
                    style={{maxWidth: '130px', maxHeight: '50px', minWidth: '130px', minHeight: '50px', fontSize: '12px'}}
                    onClick={() => {
                       setIsConfirm(true);
                    }}
                    >   
                      {getNextResultText(quarantine_result_status)}

                    </ColorButton>
            </Grid>
            <Grid item md={7}
             onClick={() => {
              if(user.id){
                history.push("/user/" + user.id + "/preview");
              }else{
                history.push("/user/" + "userId_empty" + "/preview");
              }
              
            }}
            >
              <Box display={{ xs: "none", md: "block" }} fontSize={11}>
                  <b>
                     Start Date: {dateStrAPIFormat(date_start) } | End Date: {dateStrAPIFormat(date_end)}
                  </b>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          display={{ xs: "flex", md: "none" }}
          px={2}
          py={1}
          css={{
            opacity: device?.latitude && device?.longitude ? 1 : 0.5,
            "@media (max-width: 992px)": { borderTop: "1px solid #ddd" },
          }}
          onClick={() => {
            if(user.id){
              history.push("/user/" + user.id + "/preview");
            }else{
              history.push("/user/" + "userId_empty" + "/preview");
            }
            
          }}
        >
          <Grid container>
          <Grid item xs={12}>
            <Box
              fontSize={12}
            >
              <b>
                  Start Date: {dateStrAPIFormat(date_start) } | End Date: {dateStrAPIFormat(date_end)}
              </b>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              fontSize={12}
            >
              Status: {quarantine_result_status === 1 ? "In Quarantine"
                          : quarantine_result_status === 2 ? "Waiting For Quarantine"
                          : quarantine_result_status === 3 ? "Waiting For Apporve"
                          : quarantine_result_status === 4 ? "Complete"
                          : quarantine_result_status === 5 ? "Re-Quarantine"
                          : "?"
                  }
            </Box>
          </Grid>
          </Grid>
          
        </Box>

        <ConfirmStatusModal
            isConfirm={isConfirm}
            setIsConfirm={setIsConfirm}
            status = {getNextResultText(quarantine_result_status)}
            onClick={async () => {
              
              fire_quarantineStatus_update(
                getNextResult(quarantine_result_status)
              )
                .then(({ data }: { data: any }) => {
                  webStore.set_filter_reload(true);
                  enqueueSnackbar(`Update Status Success`, {
                    key: "editSuccess",
                    variant: "success",
                    autoHideDuration: 3000,
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  });
                })
                .catch((ex: any) => {
                  enqueueSnackbar("Update Status Failed", {
                    key: "editError",
                    variant: "error",
                    autoHideDuration: 3000,
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  });
                });
                setIsConfirm(false);
              
            }}
          />
      </Box>
    );
  }
);

export const UserItem = observer(
  ({
    user,
    device,
    place,
    oximeter,
    borderTop,
    index,
    mapRefLat,
    lengthOfStay

  }: {
    user: User;
    device?: userDevice;
    place?: Places;
    oximeter?: Oximeter;
    index: number;
    borderTop?: boolean;
    mapRefLat?: String;
    lengthOfStay?: number;
  }) => {
    const history = useHistory();
    const [background, setBackground] = useState<String>();
    let dropzoneStyle
    if(mapRefLat){
      if(mapRefLat.toString() === device?.latitude?.toString() && device?.latitude?.toString() !== "0"){
        dropzoneStyle = {
          backgroundColor: "rgba(3,169,244,0.08)"
        };
      }else{
        dropzoneStyle = {
          backgroundColor: "unset"
        };
      }
    }
    return (
      <Box
        className="relative"
        css={{
          backgroundColor: "#fff",
          borderRadius: 5,
          cursor: "pointer",
          "@media (max-width: 992px)": { marginBottom: 15 },
        }}
      >
        <Box
          display="flex"
          py={2}
          px={{ xs: 2, md: 0 }}
          borderTop={borderTop ? "1px solid #ddd" : 0}
          style={dropzoneStyle}
          css={{
            opacity: device?.latitude && device?.longitude ? 1 : 0.5,
            "@media (max-width: 992px)": { borderTop: 0 },
            "&:hover": {
              backgroundColor: "rgba(3,169,244,0.08) !important",
              borderTop: 0,
            },
          }}
        >
          <Box pr={2}
            onClick={() => {
              if(user.id){
                history.push("/user/" + user.id + "/preview");
              }else{
                history.push("/user/" + "userId_empty" + "/preview");
              }
              
            }}
          >
            <Avatar css={{ width: 32, height: 32 }} src={user?.avatar}>
              {user?.name.substring(0, 1)}
            </Avatar>
          </Box>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box mb={2} position="relative"
                onClick={() => {
                  if(user.id){
                    history.push("/user/" + user.id + "/preview");
                  }else{
                    history.push("/user/" + "userId_empty" + "/preview");
                  }
                  
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  css={{ color: "#35353F" }}
                >
                  <Box fontWeight={600} fontSize={14} mr={1}>
                    {`${user?.name} ${user.surname}`}
                  </Box>
                  {user?.isSos && (
                    <ReportProblemIcon
                      css={{
                        color: "#C83728",
                        width: 16,
                        height: 16,
                        marginRight: "0.3em",
                      }}
                    />
                  )}
                  <Box position="relative">
                    <Box
                      className="pin"
                      css={{
                        "--color":
                          device?.device_status === 3 ? "red" 
                          : device?.device_status === 4 ? "red" 
                          : device?.device_status === 9 ? "red" 
                          : "green",
                      }}
                    >
                      {index.toString()}
                    </Box>
                  </Box>
                </Box>
                <Box fontSize={12} css={{ color: "#5B5B62" }}>
                  {`${
                    user.gender === "m"
                    ? "Male"
                    :user.gender === "M"
                    ? "Male"
                    :user.gender === "male"
                    ? "Male"
                    : user.gender === "f"
                    ? "Female"
                    : user.gender === "F"
                    ? "Female"
                    :user.gender === "female"
                    ? "Female"
                    : "-"
                  }
              | ${user.nationality} | ${place?.name_en}`}
                </Box>
                {/* <Box
                  display={{ xs: "block", md: "none" }}
                  position="absolute"
                  right={0}
                  top="calc(50% - 15px)"
                >
                  <ArrowForwardIosIcon css={{ fontSize: 15 }} />
                </Box> */}
              </Box> 
            </Grid>
            <Grid item xs={12} md={2}>
              {/* <Button color="primary" > Vibrate </Button> */}
            </Grid>
            <Grid item md={4}>
              <Box
                fontSize={12}
                textAlign="right"
                css={{ color: "rgba(30, 30, 30, 0.5)" }}
                display={{ xs: "none", md: "block" }}
                onClick={() => {
                  if(user.id){
                    history.push("/user/" + user.id + "/preview");
                  }else{
                    history.push("/user/" + "userId_empty" + "/preview");
                  }
                  
                }}
              >
                {device?.updated_at && dateStr(new Date(device?.updated_at))}
              </Box>
              <Box
                display={{ md: "flex" }}
                alignItems="center"
                css={{ color: "#595960", height: "100%", marginLeft: "50%", marginTop: "-9%" }}
                justifyContent={"flex-start"}
              >
                <Box
                  justifyContent="center"
                  display="flex"
                  mb={{ xs: 0.5, md: 0 }}
                  height={{ xs: 14, md: "100%" }}
                >
                  <img
                    src={LengthOfStayIcon}
                    css={{
                      "@media (max-width: 992px)": { maxHeight: 20, maxWidth: 20 },
                    }}
                  />
                </Box>
                <Box ml={0.5} fontSize={13}>
                    {lengthOfStay ? Math.floor(lengthOfStay) : ""}
                </Box>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box display={{ xs: "none", md: "block" }} 
                onClick={() => {
                  if(user.id){
                    history.push("/user/" + user.id + "/preview");
                  }else{
                    history.push("/user/" + "userId_empty" + "/preview");
                  }
                  
                }}
              >
                <OnlineTab user={user} device={device} />
              </Box>
            </Grid>
            <Grid
              md={6}
              xs={10}
              item
              // spacing={2}
              style={{ justifyContent: "flex-end" }}
              onClick={() => {
                if(user.id){
                  history.push("/user/" + user.id + "/preview");
                }else{
                  history.push("/user/" + "userId_empty" + "/preview");
                }
                
              }}
            >
              <DeviceValueTab device={device} oximeter={oximeter}/>
            </Grid>
          </Grid>
        </Box>
        <Box
          display={{ xs: "flex", md: "none" }}
          px={2}
          py={1}
          justifyContent="space-between"
          alignItems="center"
          css={{
            opacity: device?.latitude && device?.longitude ? 1 : 0.5,
            "@media (max-width: 992px)": { borderTop: "1px solid #ddd" },
          }}
          onClick={() => {
            if(user.id){
              history.push("/user/" + user.id + "/preview");
            }else{
              history.push("/user/" + "userId_empty" + "/preview");
            }
            
          }}
        >
          <OnlineTab user={user} device={device} />
          <Box
            fontSize={12}
            textAlign="right"
            css={{ color: "rgba(30, 30, 30, 0.5)" }}
            onClick={() => {
              if(user.id){
                history.push("/user/" + user.id + "/preview");
              }else{
                history.push("/user/" + "userId_empty" + "/preview");
              }
              
            }}
          >
            {device?.updated_at && dateStr(new Date(device?.updated_at))}
          </Box>
        </Box>
      </Box>
    );
  }
);

export const UserItem_AlertTemp = observer(
  ({
    user,
    device,
    place,
    oximeter,
    borderTop,
    // index,
  }: {
    user: User;
    device?: userDevice;
    place?: Places;
    oximeter?: Oximeter;
    // index: number;
    borderTop?: boolean;
  }) => {
    const history = useHistory();
    // console.log("userssss",user);
    return (
      <Box
        className="relative"
        css={{
          backgroundColor: "#fff",
          borderRadius: 5,
          cursor: "pointer",
          "@media (max-width: 992px)": { marginBottom: 15 },
        }}
        onClick={() => {
          if(user.id){
            history.push("/user/" + user.id + "/preview");
          }else{
            history.push("/user/" + "userId_empty" + "/preview");
          }
        }}
      >
        <Box
          display="flex"
          py={2}
          px={{ xs: 2, md: 0 }}
          borderTop={borderTop ? "1px solid #ddd" : 0}
          css={{
            opacity: device?.latitude && device?.longitude ? 1 : 0.5,
            "@media (max-width: 992px)": { borderTop: 0 },
            "&:hover": {
              backgroundColor: "rgba(3,169,244,0.08)",
              borderTop: 0,
            },
          }}
        >
          <Box pr={2} pl={2}>
            <Avatar css={{ width: 32, height: 32 }} src={user?.avatar}>
              {user?.name.substring(0, 1)}
            </Avatar>
          </Box>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Box mb={2} position="relative">
                <Box
                  display="flex"
                  alignItems="center"
                  css={{ color: "#35353F" }}
                >
                  <Box fontWeight={600} fontSize={14} mr={1}>
                    {`${user?.name} ${user.surname}`}
                  </Box>
                  {user?.isSos && (
                    <ReportProblemIcon
                      css={{
                        color: "#C83728",
                        width: 16,
                        height: 16,
                        marginRight: "0.3em",
                      }}
                    />
                  )}
                  {/* <Box position="relative">
                    <Box
                      className="pin"
                      css={{
                        "--color":
                          device?.device_status === 1 ? "green" : "red",
                      }}
                    >
                      {index.toString()}
                    </Box>
                  </Box> */}
                </Box>
                <Box fontSize={12} css={{ color: "#5B5B62" }}>
                  {`${
                    user.gender === "m"
                    ? "Male"
                    :user.gender === "M"
                    ? "Male"
                    : user.gender === "f"
                    ? "Female"
                    : user.gender === "F"
                    ? "Female"
                    : ""
                  }
              | ${user.nationality} | ${place?.name_en}`}
                </Box>
                {/* <Box
                  display={{ xs: "block", md: "none" }}
                  position="absolute"
                  right={0}
                  top="calc(50% - 15px)"
                >
                  <ArrowForwardIosIcon css={{ fontSize: 15 }} />
                </Box> */}
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box
                fontSize={12}
                textAlign="right"
                css={{ color: "rgba(30, 30, 30, 0.5)" }}
                display={{ xs: "none", md: "block" }}
              >
                {device?.updated_at && dateStr(new Date(device?.updated_at))}
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box display={{ xs: "none", md: "block" }}>
                <OnlineTab user={user} device={device} />
              </Box>
            </Grid>
            <Grid
              md={6}
              xs={10}
              item
              // spacing={2}
              style={{ justifyContent: "flex-end" }}
            >
              {/* <DeviceValueTab device={device} oximeter={oximeter}/> */}
            </Grid>
          </Grid>
        </Box>
        <Box
          display={{ xs: "flex", md: "none" }}
          px={2}
          py={1}
          justifyContent="space-between"
          alignItems="center"
          css={{
            opacity: device?.latitude && device?.longitude ? 1 : 0.5,
            "@media (max-width: 992px)": { borderTop: "1px solid #ddd" },
          }}
        >
          <OnlineTab user={user} device={device} />
          <Box
            fontSize={12}
            textAlign="right"
            css={{ color: "rgba(30, 30, 30, 0.5)" }}
          >
            {device?.updated_at && dateStr(new Date(device?.updated_at))}
          </Box>
        </Box>
      </Box>
    );
  }
);

export const UserItem_AlertOximeter = observer(
  ({
    user,
    device,
    place,
    oximeter,
    borderTop,
    // index,
  }: {
    user: User;
    device?: userDevice;
    place?: Places;
    oximeter?: Oximeter;
    // index: number;
    borderTop?: boolean;
  }) => {
    const history = useHistory();
    return (
      <Box
        className="relative"
        css={{
          backgroundColor: "#fff",
          borderRadius: 5,
          cursor: "pointer",
          "@media (max-width: 992px)": { marginBottom: 15 },
        }}
        onClick={() => {
          if(user.id){
            history.push("/user/" + user.id + "/preview");
          }else{
            history.push("/user/" + "userId_empty" + "/preview");
          }
        }}
      >
        <Box
          display="flex"
          py={2}
          px={{ xs: 2, md: 0 }}
          borderTop={borderTop ? "1px solid #ddd" : 0}
          css={{
            opacity: device?.latitude && device?.longitude ? 1 : 0.5,
            "@media (max-width: 992px)": { borderTop: 0 },
            "&:hover": {
              backgroundColor: "rgba(3,169,244,0.08)",
              borderTop: 0,
            },
          }}
        >
          <Box pr={2} pl={2}>
            <Avatar css={{ width: 32, height: 32 }} src={user?.avatar}>
              {user?.name.substring(0, 1)}
            </Avatar>
          </Box>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Box mb={2} position="relative">
                <Box
                  display="flex"
                  alignItems="center"
                  css={{ color: "#35353F" }}
                >
                  <Box fontWeight={600} fontSize={14} mr={1}>
                    {`${user?.name} ${user.surname}`}
                  </Box>
                  {user?.isSos && (
                    <ReportProblemIcon
                      css={{
                        color: "#C83728",
                        width: 16,
                        height: 16,
                        marginRight: "0.3em",
                      }}
                    />
                  )}
                  {/* <Box position="relative">
                    <Box
                      className="pin"
                      css={{
                        "--color":
                          device?.device_status === 1 ? "green" : "red",
                      }}
                    >
                      {index.toString()}
                    </Box>
                  </Box> */}
                </Box>
                <Box fontSize={12} css={{ color: "#5B5B62" }}>
                  {`${
                    user.gender === "m"
                      ? "Male"
                      :user.gender === "M"
                      ? "Male"
                      : user.gender === "f"
                      ? "Female"
                      : user.gender === "F"
                      ? "Female"
                      : ""
                  }
              | ${user.nationality} | ${place?.name_en}`}
                </Box>
                {/* <Box
                  display={{ xs: "block", md: "none" }}
                  position="absolute"
                  right={0}
                  top="calc(50% - 15px)"
                >
                  <ArrowForwardIosIcon css={{ fontSize: 15 }} />
                </Box> */}
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box
                fontSize={12}
                textAlign="right"
                css={{ color: "rgba(30, 30, 30, 0.5)" }}
                display={{ xs: "none", md: "block" }}
              >
                {device?.updated_at && dateStr(new Date(device?.updated_at))}
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box display={{ xs: "none", md: "block" }}>
                <OnlineTab user={user} device={device} />
              </Box>
            </Grid>
            <Grid
              md={6}
              xs={10}
              item
              // spacing={2}
              style={{ justifyContent: "flex-end" }}
            >
              {/* <DeviceValueTab device={device} oximeter={oximeter}/> */}
            </Grid>
          </Grid>
        </Box>
        <Box
          display={{ xs: "flex", md: "none" }}
          px={2}
          py={1}
          justifyContent="space-between"
          alignItems="center"
          css={{
            opacity: device?.latitude && device?.longitude ? 1 : 0.5,
            "@media (max-width: 992px)": { borderTop: "1px solid #ddd" },
          }}
        >
          <OnlineTab user={user} device={device} />
          <Box
            fontSize={12}
            textAlign="right"
            css={{ color: "rgba(30, 30, 30, 0.5)" }}
          >
            {device?.updated_at && dateStr(new Date(device?.updated_at))}
          </Box>
        </Box>
      </Box>
    );
  }
);

export const CardUserList = observer(
  ({
    result_userList_raw,
    result_userList,
    loading_userList,
    total = 0,
    filter,
    mapRef,
    setPage,
    showVibrate
  }: {
    result_userList_raw?: SIuserListRaw;
    result_userList: UsersQuarantine[];
    loading_userList: boolean;
    total?: number;
    mapRef?: any;
    filter?: Filter;
    setPage: (pn: number) => void;
    showVibrate?: Boolean;
  }) => {
    const [userLiser, setUserLiser] = useState<UsersQuarantine[]>([]);
    const [mapRefLat, setMapRefLat] = useState<String>();
    const [filterUser, setFilterUser] = useState<string>("oldest_first");
    useEffect(() => {
      setUserLiser(
        result_userList
        // result_userList.filter((user) => {
        //   return user.device?.latitude && user.device?.latitude;
        //   return user.device?.latitude && user.device?.latitude;
        // })
      );
      if(filterUser === "oldest_first"){
        setUserLiser(result_userList);
      }
      else{
        setUserLiser([...result_userList].reverse());
      }
    }, [result_userList]);
    // const total = userLiser.length;
    const pageCount = Math.ceil(total / (filter?.perPage || 10));
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
      webStore.setFilterPage(value);
      webStore.setFilterUserListPage(value);
    };

    const onChange = (e: any) => {
      setFilterUser(e.target.value as string);
      // changeFilter(e.target.value);
      if (e.target.value === "oldest_first"){
        setPage(1);
        setUserLiser(result_userList);
        // console.log("setUservalue: oldest:", userLiser);
      }else {
        setPage(pageCount);
        setUserLiser([...result_userList].reverse());
        
        // console.log("setUservalue: newest:", [...result_userList].reverse());
      }
    };

    return (
      <Box
        className="relative"
        css={{
          backgroundColor: "#fff",
          borderRadius: 5,
          cursor: "pointer",
          "@media (max-width: 992px)": { backgroundColor: "transparent" },
          height: "100%",
        }}
      >
        {loading_userList && (
          <LinearProgress
            style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
          />
        )}
        <Box py={2} px={{ xs: 0, md: 2 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            {
              total ? 
              <>
              {
                localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ?
                  <Box fontSize={14} fontWeight={600}>
                    Total {total} Guests
                  </Box>
                  :
                  <Box fontSize={14} fontWeight={600}>
                    Total {total} User
                  </Box>
              }
                
              </> 
              : <></>
            }
            
            {
              total ? <>
                <Box display="flex" alignItems="center" fontSize={14}>
                  <Box mr={1}>Sort by</Box>
                    <Select
                      labelId="label"
                      id="select"
                      value={filterUser}
                      onChange = {onChange}
                      disableUnderline
                      css={{ fontSize: 14, fontFamily: "inherit", color: "#4684E2" }}
                    >
                      <MenuItem value="oldest_first">Oldest first</MenuItem>
                      <MenuItem value="newest_first">Newest first</MenuItem>
                    </Select>
                </Box>
              </> :
              <></>
            }
            
          </Box>
          <Box>
            {(userLiser || []).map((user, i) => {
              var index =
                ((filter?.page || 1) - 1) * (filter?.perPage || 10) + i + 1;
              
              return (
                <div
                  onMouseEnter={() => {
                    mapRef &&
                      mapRef.current &&
                      user.device?.longitude &&
                      user.device?.latitude &&
                      mapRef.current.focus &&
                      mapRef.current.focus(
                        user.device?.latitude,
                        user.device?.longitude,
                        index.toString()
                      );
                    setMapRefLat(user.device?.latitude?.toString());
                  }}
                  onMouseLeave={() => {
                    mapRef &&
                      mapRef.current &&
                      mapRef.current.reset &&
                      mapRef.current.reset();
                  }}
                >
                  {/* <UserItem mapRefLat={mapRefLat} {...user} index={index} borderTop={i !== 0} /> */}
                  {
                    localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ?
                    <UserItemInsomniac mapRefLat={mapRefLat} {...user} lengthOfStay={user.length_of_stay} index={index} borderTop={i !== 0} showVibrate={showVibrate} />
                    :
                    <UserItem mapRefLat={mapRefLat} {...user} lengthOfStay={user.length_of_stay} index={index} borderTop={i !== 0} />

                  }
                </div>
              );
            })}
          </Box>
          {pageCount > 1 && (
            <Box mt={2}>
              <Pagination
                count={pageCount}
                page={filter?.page || 1}
                onChange={handleChange}
                size="small"
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);

export const CardUserList_AlertTemp = observer(
  ({
    result_userList,
    loading_userList,
    total = 0,
    filter,
    mapRef,
    // setPage,
  }: {
    result_userList: UsersQuarantine[];
    loading_userList: boolean;
    total?: number;
    mapRef?: any;
    filter?: Filter;
    // setPage: (pn: number) => void;
  }) => {
    const [userLiser, setUserLiser] = useState<UsersQuarantine[]>([]);
    useEffect(() => {
      setUserLiser(
        result_userList
        // result_userList.filter((user) => {
        //   return user.device?.latitude && user.device?.latitude;
        //   return user.device?.latitude && user.device?.latitude;
        // })
      );
    }, [result_userList]);
    // const total = userLiser.length;
    // const pageCount = Math.ceil(total / (filter?.perPage || 10));
    // const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    //   setPage(value);
    // };

    return (
      <Box
        className="relative"
        css={{
          backgroundColor: "#fff",
          borderRadius: 5,
          cursor: "pointer",
          "@media (max-width: 992px)": { backgroundColor: "transparent" },
          height: "100%",
        }}
      >
        {loading_userList && (
          <LinearProgress
            style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
          />
        )}
        <Box py={2} px={{ xs: 0, md: 2 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Box fontSize={14} fontWeight={600}>
              {/* Total {total} User */}
            </Box>
            <Box display="flex" alignItems="center" fontSize={14}>
              <Box mr={1}>Sort by</Box>
              <Select
                labelId="label"
                id="select"
                value="oldest_first"
                disableUnderline
                css={{ fontSize: 14, fontFamily: "inherit", color: "#4684E2" }}
              >
                <MenuItem value="oldest_first">Oldest first</MenuItem>
              </Select>
            </Box>
          </Box>
          <Box>
            {(userLiser || []).map((user, i) => {
              if(typeof user.device != 'undefined'){
                if(user.device.body_temp > 37.5 && user.device.body_temp !== 0){

                  // if(user.device.body_temp.toString() > "37.5" && user.device.body_temp.toString() !== "0"){
                  // var index =
                  //   ((filter?.page || 1) - 1) * (filter?.perPage || 10) + i + 1;
                    return (
                      <div>
                        <UserItem_AlertTemp {...user} borderTop={i !== 0} />
                      </div>
                    );
                }
              }
            })}
          </Box>

        {/* }else if (user.device.body_temp.toString() > "37.5" && user.device.body_temp.toString() !== "0"){
                //     console.log("OF",user);
                  return false; */}
          {/* {pageCount > 1 && (
            <Box mt={2}>
              <Pagination
                count={pageCount}
                page={filter?.page || 1}
                onChange={handleChange}
                size="small"
              />
            </Box>
          )} */}
        </Box>
      </Box>
    );
  }
);

export const CardUserList_Oximeter = observer(
  ({
    result_userList,
    loading_userList,
    total = 0,
    filter,
    mapRef,
    // setPage,
  }: {
    result_userList: UsersQuarantine[];
    loading_userList: boolean;
    total?: number;
    mapRef?: any;
    filter?: Filter;
    // setPage: (pn: number) => void;
  }) => {
    const [userLiser, setUserLiser] = useState<UsersQuarantine[]>([]);
    useEffect(() => {
      setUserLiser(
        result_userList
        // result_userList.filter((user) => {
        //   return user.device?.latitude && user.device?.latitude;
        //   return user.device?.latitude && user.device?.latitude;
        // })
      );
    }, [result_userList]);
    // const total = userLiser.length;
    // const pageCount = Math.ceil(total / (filter?.perPage || 10));
    // const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    //   setPage(value);
    // };

    return (
      <Box
        className="relative"
        css={{
          backgroundColor: "#fff",
          borderRadius: 5,
          cursor: "pointer",
          "@media (max-width: 992px)": { backgroundColor: "transparent" },
          height: "100%",
        }}
      >
        {loading_userList && (
          <LinearProgress
            style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
          />
        )}
        <Box py={2} px={{ xs: 0, md: 2 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Box fontSize={14} fontWeight={600}>
              {/* Total {total} User */}
            </Box>
            <Box display="flex" alignItems="center" fontSize={14}>
              <Box mr={1}>Sort by</Box>
              <Select
                labelId="label"
                id="select"
                value="oldest_first"
                disableUnderline
                css={{ fontSize: 14, fontFamily: "inherit", color: "#4684E2" }}
              >
                <MenuItem value="oldest_first">Oldest first</MenuItem>
              </Select>
            </Box>
          </Box>
          <Box>
            {(userLiser || []).map((user, i) => {
              // if(typeof user.oximeter != 'undefined'){
              //   if(user.oximeter.blood_oxygen < 95 && user.oximeter.blood_oxygen !== 0){
              //     return (
              //       <div>
              //         <UserItem_AlertOximeter {...user} borderTop={i !== 0} />
              //       </div>
              //     );
              //   }
              // }
              if(typeof user.device != 'undefined'){
                if(user.device.oxygen < 95 && user.device.oxygen !== 0){
                  return (
                    <div>
                      <UserItem_AlertOximeter {...user} borderTop={i !== 0} />
                    </div>
                  );
                }
              }
            })}
          </Box>
          {/* {pageCount > 1 && (
            <Box mt={2}>
              <Pagination
                count={pageCount}
                page={filter?.page || 1}
                onChange={handleChange}
                size="small"
              />
            </Box>
          )} */}
        </Box>
      </Box>
    );
  }
);