import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Container,
  Grid
} from "@material-ui/core";
import { Filter } from "../../types/user";
import { webStore } from "../../stores/webStore";
import { useHistory } from "react-router-dom";
import AssignmentIcon from '@material-ui/icons/Assignment';
import {
  withStyles,
  Theme,
  makeStyles
} from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import MentalIcon from "../../components/assets/Mental_0.png";
import TransparentMentalIcon from "../../components/assets/Mental2.png";
import DailyIcon from "../../components/assets/Daily_0.png";
import TransparentDailyIcon from "../../components/assets/Daily2.png";
import './Form.css';

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    // color: theme.palette.getContrastText('#3f51b5'),
    backgroundColor: '#3f51b5',
    color: '#fff',
    
    '&:hover': {
      backgroundColor: '#314993',
      color: '#fff',
      // img : {
      //   opacity: '0'
      // },
    },
  },
}))(Button);



export const ChooseReport = observer((props: any) => {

  useEffect(() => {
    return () => {
      webStore.resetFilter();
    };
  }, []);
  const mapRef = useRef<any>();
  const [page, setPage] = useState<number>(1);
  const filter: Filter = {
    startDate: webStore.startDate || new Date(),
    endDate: webStore.endDate || new Date(),
    filter_temp: webStore.filter_temp,
    filter_wear: webStore.filter_wear,
    filter_battery: webStore.filter_battery,
    filter_yacht_name: webStore.filter_yacht_name,
    filter_Keyword: webStore.filter_Keyword,
    page: page,
    perPage: 10,
  };
  const history = useHistory();
  return (
    <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16 pt-16">
        <Grid container>
            <Grid item xs={12} style={{marginTop: '6%'}} >
                <Box display="flex" alignItems="center" justifyContent="center" id="chooseForm">
                    <ColorButton
                    variant="outlined"
                    color="primary"
                    style={{maxWidth: '300px', maxHeight: '100px', minWidth: '300px', minHeight: '100px'}}
                    onClick={() => {
                        history.push("/report/daily");
                    }} 
                    css={{
                        "@media (max-width: 992px)": {
                        backgroundColor: "transparent",
                        boxShadow: "unset",
                        color: "#4684E2",
                        },
                    }}
                    >
                       <img
                        className ="top"
                        src={TransparentDailyIcon}
                        css={{
                          "@media (max-width: 2560px)": { maxHeight: 30, maxWidth: 30 , marginRight : 15},
                        }}
                      />
                        Daily Report
                    </ColorButton>
                </Box>
            </Grid>
            <Grid item xs={12} style={{marginTop: '8%'}} id = "chooseForm">
                <Box display="flex" alignItems="center" justifyContent="center">
                    <ColorButton
                    variant="outlined"
                    color="primary"
                    style={{maxWidth: '300px', maxHeight: '100px', minWidth: '300px', minHeight: '100px'}}
                    onClick={() => {
                        history.push("/report/mental");
                    }}
                    css={{
                        "@media (max-width: 992px)": {
                        backgroundColor: "transparent",
                        boxShadow: "unset",
                        color: "#4684E2",
                        },
                    }}
                    >   
                        <img
                          src={TransparentMentalIcon}
                          css={{
                            "@media (max-width: 2560px)": { maxHeight: 30, maxWidth: 30, marginRight : 15},
                          }}  
                        />
                         Mental Health 
                         <br/>
                         Report
                    </ColorButton>
                </Box>
            </Grid>
        </Grid>
    </Container>
  );
});