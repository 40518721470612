import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMe,GetProject } from "../services/api/auth/useMe";
import { webStore } from "../stores/webStore";
import logo from "../components/assets/PMH.svg";
export const AfterLogin = () => {
  const { result_me, loading_me } = useMe();
  const { result_project, loading_project } = GetProject();
  const { pathname } = useLocation();

  useEffect(() => {
    console.log("set profile", result_me, loading_me, pathname);
    if (!loading_me && result_me  && !loading_project && result_project ) {
      result_me.logo = logo;
      webStore.setProfile(result_me, result_project);

    }

    // if (!loading_me && result_me ) {
    //   result_me.logo = logo;
    //   webStore.setProfile(result_me, );

    // }

    // if (!loading_project && result_project) {
    //   // webStore.setProfile(result_project);
    //   console.log("hi");
    // }

  }, [localStorage.getItem("token"), loading_project, pathname || "/"]);
  return <></>;
};
