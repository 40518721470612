import {
  ListItem,
  ListItemText,
  List,
  Divider,
  Button,
  Avatar,
  IconButton,
  TextField,
  CircularProgress,
  Grid,
  Box,
  Modal,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { User } from "../../types/user";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import { observer } from "mobx-react-lite";

import { dateStr, dateStrAPI ,DateString } from "../../utils";
import { useSnackbar } from "notistack";
import { useUpdateUser } from "../../services/api/user/useUpdateUser";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import CloseIcon from "@material-ui/icons/Close";
import { InputDate } from "./UserCreate";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { keyframes } from "@emotion/react";
import { useClipboard } from "use-clipboard-copy";

const Field = observer(
  ({
    label,
    value,
    copy,
    edit,
    ...rest
  }: {
    label?: string;
    value: any;
    copy?: boolean;
    edit?: any;
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    const clipboard = useClipboard();
    return (
      <Box my={1}>
        <Box fontSize={14} css={{ color: "rgba(255, 255, 255, 0.5)" }}>
          {label}
        </Box>

        <Box fontSize={18} display="flex" alignItems="center">
          {copy ? (
            <Box
              css={{ cursor: "pointer" }}
              onClick={() => {
                clipboard.copy(value);
                enqueueSnackbar(`copy ${value}`, {
                  variant: "success",
                  autoHideDuration: 3000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                });
              }}
            >
              {value}
              <FileCopyIcon
                css={{ fontSize: 14, marginLeft: 5, cursor: "pointer" }}
              />
            </Box>
          ) : (
            value
          )}
          {edit && (
            <BorderColorIcon
              css={{ fontSize: 14, marginLeft: 5, cursor: "pointer" }}
              onClick={edit}
            />
          )}
        </Box>
      </Box>
    );
  }
);

export const EditField = observer(
  ({
    isEdit,
    label,
    value,
    field,
    onClick,
    setIsEdit,
    type = "text",
    setValue,
    buttonName = "SAVE",
  }: {
    isEdit: boolean;
    setIsEdit: any;
    label: string;
    field: string;
    value: any;
    onClick?: any;
    type?: string;
    setValue: any;
    buttonName?: string;
  }) => {
    return (
      <Modal
        open={isEdit}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 300, borderRadius: 5 }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box css={{ color: "#4B4C56" }} fontSize={14}>
              {label}
            </Box>
            <Box
              onClick={() => {
                setIsEdit(false);
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>
          <Box>
            {type === "text" && (
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                value={value}
                defaultValue={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setValue({
                    label: label,
                    type: type,
                    field: field,
                    value: event.target.value,
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                      onClick={onClick}
                      color="primary"
                      size="small"
                      variant="text"
                    >
                      {buttonName}
                    </Button>
                  ),
                }}
              />
            )}
            {type === "date" && (
              <Box display="flex">
                <InputDate
                  input={{ label: "", name: "start_date" }}
                  value={value}
                  onChange={(date: MaterialUiPickersDate) => {
                    setValue({
                      label: label,
                      type: type,
                      field: field,
                      value: date ? new Date(date.toString()) : null,
                    });
                  }}
                  error={false}
                />
                <Button
                  onClick={onClick}
                  color="primary"
                  size="small"
                  variant="text"
                >
                  {buttonName}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    );
  }
);

export const UserInfoCard = ({
  user,
  userRaw,
  reload,
}: {
  user: User;
  userRaw?: any;
  reload: any;
}) => {
  
  const [isEdit, setIsEdit] = useState(false);
  const [editValue, setEditValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { fire_userUpdate } = useUpdateUser();
  const [dateStart, setDateStart] = useState(user.date_start || "?");
  const [dateEnd, setDateEnd] = useState(user.date_end || "?");
  const [imei, setImei] = useState(user.imei || "?");
  const { enqueueSnackbar } = useSnackbar();

  const checkDate = (dateData : any) => {
    let dateFormat;
    // if(dateStrAPI(dateData) === '1-01-01'){
    //   dateFormat = "-"
    // }else {
    //   dateFormat = dateStrAPI(dateData)
    // }
    if(DateString(dateData) === '1-01-01'){
      dateFormat = "-"
    }else {
      dateFormat = DateString(dateData)
    }
    return dateFormat;
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={3} xs={12}>
        {/* <Field label="Place Name" value={user.yatch_name?.toString()} /> */}
        {
          userRaw?.place?.name_en ? 
          <Field label={localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ? "Venue Name" : "Place Name"} value={userRaw?.place?.name_en.toString()} />
          :
          <Field label={localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ? "Venue Name" : "Place Name"} value="-" />
        }
        
      </Grid>
      <Grid item md={3} xs={12}>
        {
          user.date_of_birth ? 
          <Field label="Date of birth" value={dateStrAPI(user.date_of_birth)} />
          :
          <Field label="Date of birth" value="-" />
        }
        
      </Grid>
      {
        user.passport?.passport_number ? <Grid item md={3} xs={12}>
                                          <Field
                                            label={localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ? "Ticket Number" : "Passport Number"}
                                            value={user.passport?.passport_number.toString()}
                                            copy={true}
                                          />
                                        </Grid> : 

        user.identity_card?.personal_no ? <Grid item md={3} xs={12}>
                                          <Field
                                            label={localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ? "Ticket Number" : "ID Card"}
                                            value={user.identity_card?.personal_no.toString()}
                                            copy={true}
                                          />
                                        </Grid>:
                                        <Grid item md={6} xs={12}>
                                        <Field
                                          label="ID Card"
                                          value="-"
                                          copy={true}
                                        />
                                      </Grid>


      }

      <Grid item md={3} xs={12}>
        {
          localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ?
          <></>
          :
          <>
              {
                userRaw?.home_isolation?.length_of_stay ? 
                <Field label={localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ? "Number of Days" :"Length of stay"} value={userRaw?.home_isolation?.length_of_stay?.toString()} />
                : <Field label={localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ? "Number of Days" :"Length of stay"} value="-" />
              }
          </>
        }
        
        
      </Grid>
      <Grid item md={3} xs={12}>
        {
          localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ?
          <></>
          :
          <Field
            label="Start Date"
            value={checkDate(dateStart)}
            edit={() => {
              setEditValue({
                label: "Edit start date",
                value: userRaw?.date_start ? DateString(userRaw?.date_start) : "",
                type: "date",
                field: "date_start",
              });
              setIsEdit(true);
            }}
          />
        }
      </Grid>
      <Grid item md={3} xs={12}>
        {
          localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ?
          <></>
          :
          <Field
            label="End Date"
            value={checkDate(dateEnd)}
            edit={() => {
              setEditValue({
                label: "Edit end date",
                value: userRaw?.date_end ? DateString(userRaw?.date_end) : "",
                type: "date",
                field: "date_end",
              });
              setIsEdit(true);
            }}
          />
        }
      </Grid>
      {/* <Grid item md={3} xs={12}>
        <Field
          label="CONEECT NEW DEVICES"
          value={user.imei}
          edit={() => {
            setEditValue({
              label: "CONEECT NEW DEVICES",
              value: user.imei ? user.imei : "",
              type: "text",
              field: "imei",
            });
            setIsEdit(true);
          }}
        />
      </Grid> */}
      {/* <Grid item md={3} xs={12}>
        <Field label="Type" value={user.passport?.type || "?"} />
      </Grid>
      <Grid item md={3} xs={12}>
        <Field
          label="Contracted hospital"
          value={userRaw?.hospital?.name_en || "?"}
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <Field
          label="Contracted agency"
          value={userRaw?.agency?.name_en || "?"}
        />
      </Grid> */}

      {
        localStorage.getItem("projectType") !== "YACHTQ" ? 
        <Grid item md={3} xs={12}>
          {
            userRaw?.bed_number ? 
            <Field
            label="Bed Number"
            value={userRaw?.bed_number}
            />
            :
            <Field label="Bed Number" value="-" />
          }
          
        </Grid> : <></>
      }
      
      {/* value={userRaw?.home_isolation?.room_number} */}
      {
        localStorage.getItem("projectType") !== "YACHTQ" ?
          <Grid item md={3} xs={12}>
          {
            userRaw?.room_number ? 
            <Field
            label="Room Number"
            value={userRaw?.room_number}
            />
            :
            <Field label="Room Number" value="-" />
          }
          
          </Grid>
        : <></>
      }

      {
        userRaw?.departure_port ? <Grid item md={3} xs={12}>
                                    <Field label="Departure port" value={userRaw?.departure_port || "?"} />
                              </Grid> : <></>
      }

      {
        userRaw?.destination_port ? <Grid item md={3} xs={12}>
                                <Field
                                    label="Destination port"
                                    value={userRaw?.destination_port || "?"}
                                  />
                              </Grid> : <></>
      }

      {
        localStorage.getItem("projectType") !== "YACHTQ" ? 
        <Grid item md={3} xs={12}>
          {
            userRaw?.user?.health_info?.weight ? 
            <Field
            label="Weight"
            value={userRaw?.user?.health_info?.weight}
            />
            :
            <Field label="Weight" value="-" />
          }
          
        </Grid> : <></>
      }
      

        {
          localStorage.getItem("projectType") !== "YACHTQ" ? 
          <Grid item md={3} xs={12}>
            {
              userRaw?.user?.health_info?.height ? 
              <Field
              label="Height"
              value={userRaw?.user?.health_info?.height}
              />
              :
              <Field label="Height" value="-" />
            }
            
          </Grid> : <></>
        }
      
      

      {
        localStorage.getItem("projectType") !== "YACHTQ" ? 
        <Grid item md={3} xs={12}>
          {
            userRaw?.user?.health_info?.bmi ? 
            <Field
            label="BMI"
            value={userRaw?.user?.health_info?.bmi.toFixed(4)}
            />
            :
            <Field label="BMI" value="-" />
          }
          
        </Grid> : <></>
      }

      
      {
        localStorage.getItem("projectType") !== "YACHTQ" ? 
        <Grid item md={3} xs={12}>
          {
            userRaw?.user?.phone_number ? 
            <Field
            label="Tel."
            value={userRaw?.user?.phone_number}
            />
            :
            <Field label="Tel." value="-" />
          }
          
        </Grid> :<></>
      }
      

      <EditField
        {...editValue}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        setValue={setEditValue}
        onClick={async () => {
          setLoading(true);
          if (editValue.field === "date_start") {
            await setDateStart(editValue.value);
            await fire_userUpdate({ ...user, date_start: editValue.value });
          }
          if (editValue.field === "date_end") {
            await setDateEnd(editValue.value);
            await fire_userUpdate({ ...user, date_end: editValue.value });
          }
          if (editValue.field === "imei") {
            alert(editValue.value);
            await setImei(editValue.value);
            await fire_userUpdate({ ...user, imei: editValue.value });
          }
          setDateStart(editValue.value);
          await reload();
          setIsEdit(false);
          setLoading(false);
          enqueueSnackbar(`${editValue.label} success`, {
            key: "updateSuccess",
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }}
      />
    </Grid>
  );
};

export const UserProfile = ({ user, userRaw, reload }: { user: User; userRaw?:any, reload: any }) => {
  
  return (
    <Box display="flex" alignItems="center">
      <Box mr={3}>
        <Avatar
          src={user.avatar}
          alt={user.name}
          css={{ width: "120px !important", height: "120px !important" }}
        />
      </Box>
      <Box>
        <Box fontSize="h6.fontSize" fontWeight={600}>
          {`${
              user.name ? user.name.toString() : "-"
            } ${
              user.surname ? user.surname.toString() : "-"
            }`}
        </Box>
        <Box fontSize={14}>{`${
          user.gender == "m"
            ? "Male"
            : user.gender == "M"
            ? "Male"
            : user.gender == "Male"
            ? "Male"
            : user.gender == "male"
            ? "Male"
            : user.gender == "f"
            ? "Female"
            : user.gender == "F"
            ? "Female"
            : user.gender == "female"
            ? "Female"
            : user.gender == "Female"
            ? "Female"
            : user.gender ? user.gender.toString() : "-"
        }, ${user.nationality ? user.nationality.toString() : "-"}`}</Box>
        <br/>
        {
          localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ?
          <Box fontSize={16} fontWeight={600}>
            Allergies
          </Box>
          :
          <Box fontSize={16} fontWeight={600}>
            Disease
          </Box>
        }
        
        <Box fontSize={14}>{`${userRaw?.user?.health_info?.disease || "-"}`}</Box>
      </Box>
    </Box>
  );
};
