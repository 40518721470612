import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  InputBase
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { SubBar } from "../Dashboard/Dashboard";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
//   DatePicker
// } from "@material-ui/pickers";

import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from "material-ui-thai-datepickers";
import { MentalReportFilter } from "../../types/user";
import { webStore } from "../../stores/webStore";
import LuxonUtils from "@date-io/luxon";
import 'moment/locale/th';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import SearchIcon from "@material-ui/icons/Search";
import {  makeStyles, useTheme, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { dateStr, dateStrAPI, timeStr } from "../../utils";
import { MentalReportList } from "../../services/api/report/MentalReportList";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import axios, { AxiosRequestConfig, Method } from 'axios';
import {saveAs}  from 'file-saver';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%'
  },
  container: {
    maxHeight: 500,
  },
});

const ExportButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#0CA789',
    borderColor: '#0CA789',
    '&:hover': {
      backgroundColor: '#0CA789',
      borderColor: '#0CA789',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0CA789',
      borderColor: '#0CA789',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button);

const UserFilter = observer(({ display = true }: { display?: boolean }) => {
  const history = useHistory();
  const [startDate, setStartDate] = useState<Date | null>(webStore.startDate);
  const [endDate, setEndDate] = useState<Date | null>(webStore.endDate);
  const [filterAssessmentCount, setFilterAssessmentCount] = useState <string |null>(
    webStore.filter_assessmentCount || ""
  );
  const [fliterSymptomCase, setFilterSymptomCase] = useState <string |null>(
    webStore.filter_symptomCase || ""
  );
  const [filterResult, setFilterResult] = useState <string |null>(
    webStore.filter_result || ""
  );
  

  const assessmentCountOptions = [
    {value: "0", label: "All"},
    {value: "1" , label: "ครั้งที่ 1"},
    {value: "2", label: "ครั้งที่ 2"},
    {value: "3", label: "ครั้งที่ 3"}
  ];

  const resultOptions = [
    {value: "0", label: "All"},
    {value: "1" , label: "ไม่มีอาการของโรคซึมเศร้า หรือมีอาการของโรคซึมเศร้าน้อยมาก"},
    {value: "2", label: "มีอาการของโรคซึมเศร้า ระดับน้อย"},
    {value: "3", label: "มีอาการของโรคซึมเศร้า ระดับปานกลาง"},
    {value: "4", label: "มีอาการของโรคซึมเศร้า ระดับรุนแรง"}
  ];

  

  useEffect(() => {
    // console.log("changee");
    webStore.setStartDate(startDate);
    webStore.setEndDate(endDate);
    webStore.set_filter_assessmentCount(filterAssessmentCount);
    webStore.set_filter_result(filterResult);
  }, [
    startDate,
    endDate,
    filterAssessmentCount,
    filterResult
  ]);

  const ChangeDateFormat = (dateData : Date | null) =>{
    let splitData;
    let dateFormat : any;
    let splitDate;
    let month;
    let day;
    let year;

    if(dateData){
      let data = dateData.toString();
      console.log("data date:", data);
      splitData = data.split(" ");
      console.log("split Data", splitData);
      splitDate = data.split(" ");
      year = splitDate[3];
      day = splitDate[2];
      if (splitDate[1] ===  'Jan'){
        month = '01';
      } else if (splitDate[1] ===  'Feb'){
        month = '02';
      } else if (splitDate[1] ===  'Mar'){
        month = '03';
      } else if (splitDate[1] ===  'Apr'){
        month = '04';
      }  else if (splitDate[1] ===  'May'){
        month = '05';
      }else if (splitDate[1] ===  'Jun'){
        month = '06';
      }else if (splitDate[1] ===  'Jul'){
        month = '07';
      }else if (splitDate[1] ===  'Aug'){
        month = '08';
      }else if (splitDate[1] ===  'Sep'){
        month = '09';
      }else if (splitDate[1] ===  'Oct'){
        month = '10';
      }else if (splitDate[1] ===  'Nov'){
        month = '11';
      }else {
        month = '12';
      }
    let checkYear = parseInt(year);
    if (checkYear< 2564){
      let buddishtYear = checkYear + 543;
      year = buddishtYear.toString();
    }

    dateFormat = day + "/" + month + "/" + year;
    console.log( "date format: ",dateFormat);
    }
    if(!dateFormat){
      dateFormat = new Date();
    }
    return dateFormat;
  }

  const excelExport = () => {
    let instance = axios.create({  baseURL: "https://api.space-trax.com" }); 
    const method = 'GET';
    let end_date;
    let start_date;
    if(!endDate){
      end_date = ChangeDateFormat(new Date());
    }else {
      end_date = ChangeDateFormat(endDate);
    }

    if(!startDate) {
      start_date = ChangeDateFormat(new Date());
    }else{
      start_date = ChangeDateFormat(startDate);
    }

    const url = "https://api.space-trax.com/v2/dashboard/user/mental-list/export?search_start="+ start_date
    +"&search_end=" + end_date
    + "&page=1&limit=10000";
    const headers = {
      "Project-Key": localStorage.getItem("projectKey"),
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    let options: AxiosRequestConfig = { 
      url,
      method,
      responseType: 'blob' ,
      headers
    };

    return instance.request<any>(options)
    .then(response => { 
      // let filename = response.headers['content-disposition']
      //   .split(';')
      //   .find((n: any) => n.includes('filename='))
      //   .replace('filename=', '')
      //   .trim();      
      let url = window.URL
        .createObjectURL(new Blob([response.data]));     
      saveAs(url, "mentalHealth.xlsx");    
  });
  }

  return (
    <Box mb={3}>
      {display && (
        <>
          <Box
            display="flex"
            py={2}
            css={{
              fontSize: 13,
              borderRadius: 5,
              backgroundColor: "#fff",
              "@media (max-width: 992px)": {
                flexFlow: "wrap",
                backgroundColor: "transparent",
              },
            }}
          >
            {/* <Box
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              display="flex"
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                },
              }}
            >
              
            </Box> */}
            <Box
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              display="flex"
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                  borderWidth: 0,
                },
              }}
            >
              <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                <KeyboardDatePicker
                  style={{
                    margin: 0,
                    width: "100%",
                    borderWidth: 0,
                    fontSize: 13,
                  }}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="from"
                  label="Date Start"
                  autoOk={true}
                  value={startDate || new Date()}
                  onChange={(date: MaterialUiPickersDate) => {
                    setStartDate(date ? new Date(date.toString()) : null);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  yearOffset={543}
                />
              </MuiPickersUtilsProvider>
            </Box>
            
            <Box
              display="flex"
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                  borderWidth: 0,
                },
              }}
            >
              <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                <KeyboardDatePicker
                  style={{ margin: 0, width: "100%", fontSize: 13 }}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="to"
                  label="Date End"
                  autoOk={true}
                  value={endDate || new Date()}
                  onChange={(date: MaterialUiPickersDate) => {
                    setEndDate(date ? new Date(date.toString()) : null);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  yearOffset={543}
                />
              </MuiPickersUtilsProvider>
            </Box>

            <Box
              display="flex"
              px={2}
              mb={{ xs: 3, md: 0 }}
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                },
              }}
            >
              
            </Box>

          </Box>
          <Box
            display="flex"
            py={2}
            css={{
              fontSize: 13,
              borderRadius: 5,
              backgroundColor: "#fff",
              "@media (max-width: 992px)": {
                flexFlow: "wrap",
                backgroundColor: "transparent",
              },
            }}
          >
            {/* <Box
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              display="flex"
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                },
              }}
            >
              
            </Box> */}
            <Box
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              display="flex"
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                  borderWidth: 0,
                },
              }}
            >
              <FormControl css={{ width: "100%" }}>
                <InputLabel id="filterTem-label">ครั้งที่ทำแบบประเมิน</InputLabel>
                <Select
                  value={filterAssessmentCount}
                  disableUnderline
                  css={{ fontFamily: "inherit", fontSize: "inherit" }}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setFilterAssessmentCount(event.target.value as string);
                  }}
                  labelId="filterTem-label"
                >
                  {assessmentCountOptions.map((v) => {
                    return <MenuItem value={v.value}>{v.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            
            <Box
              display="flex"
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                  borderWidth: 0,
                },
              }}
            >
              <FormControl css={{ width: "100%" }}>
                <InputLabel id="filterSpo2-label">ผลลัพธ์</InputLabel>
                <Select
                  value={filterResult}
                  disableUnderline
                  css={{ fontFamily: "inherit", fontSize: "inherit" }}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setFilterResult(event.target.value as string);
                  }}
                  labelId="filterSpo2-label"
                >
                  {resultOptions.map((v) => {
                    return <MenuItem value={v.value}>{v.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            
            <Box
              display="flex"
              px={2}
              mb={{ xs: 3, md: 0 }}
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                },
              }}
            >
              <Button
                color="primary"
                onClick={() => {
                  console.log("search");
                }}
                css={{
                  padding: 8,
                  minWidth: "unset",
                  boxShadow: "unset",
                  marginRight: '2%'
                }}
              >
                <SearchIcon />
              </Button>
              <Button
                  variant="contained"
                  color="primary"
                  css={{ padding:8, fontSize: 12, marginRight: '2%' }}
                  onClick={() => {
                    webStore.setStartDate(null);
                    webStore.setEndDate(null);
                    webStore.set_filter_assessmentCount(null);
                    webStore.set_filter_result(null)
                    
                    setStartDate(webStore.startDate);
                    setEndDate(webStore.endDate);
                    setFilterResult(webStore.filter_result);
                    setFilterAssessmentCount(webStore.filter_assessmentCount);
                  }}
                >
                  Clear
              </Button>
              <ExportButton
                  style= {{marginLeft: "2%", fontSize : '12px'}}
                  variant="contained"
                  color="primary" disableRipple 
                  css={{ padding:8, color:'#fff' }}
                  onClick = {excelExport}
                >
                  EXPORT
              </ExportButton>

              <ExportButton
                  style= {{marginLeft: "3%", fontSize : '12px'}}
                  variant="contained"
                  color="primary" disableRipple 
                  css={{ padding:8, color:'#fff' }}
                  onClick={() => {
                      history.push("/report/imporMentalHealthForm");
                  }}
                >
                  UPLOAD
              </ExportButton>
            </Box>

          </Box>
          <Box textAlign="right">
            
          </Box>
        </>
      )}
    </Box>
  );
});

export const MentalReport = observer((props: any) => {
  const classes = useStyles();

  useEffect(() => {
    return () => {
      webStore.resetMentalReportFilter();
    };
  }, []);

  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const filter: MentalReportFilter = {
    startDate: webStore.startDate || new Date(),
    endDate: webStore.endDate || new Date(),
    filter_assessmentCount: webStore.filter_assessmentCount,
    filter_result: webStore.filter_result,
    page: page,
    perPage: rowsPerPage,
  };

  let { result_mentalSymptoms, loading_mentalSymptoms, total } = MentalReportList(filter);
  // let { result_mentalSymptoms, loading_mentalSymptoms } = MentalReportList(filter);

  console.log("mental result:  ", result_mentalSymptoms);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: any }>) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const pageCount = Math.ceil(total / (filter?.perPage || 10));
  // const pageCount = Math.ceil(10 / (filter?.perPage || 10));
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16">
      <Box mb={2}>
        <Grid item xs={12}>
          <SubBar title={"Mental Health Report"} reload={false} back={true}  />
        </Grid>
      </Box>
      <Grid item xs={12} sm={12}>
        <Box display={{ xs: "block", md: "block" }}>
          <UserFilter/>
        </Box>
      </Grid>
      <Box
        className="relative"
        css={{
          backgroundColor: "#fff",
          borderRadius: 5,
          cursor: "pointer",
          "@media (max-width: 992px)": { backgroundColor: "transparent" },
          height: "100%",
        }}
      >
           <Paper className={classes.root}>
              {/* {loading_mentalSymptoms && (
                <LinearProgress
                  style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
                />
              )} */}
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ minWidth: '200px' }}>วันเวลาที่ทําแบบประเมิน</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>ชื่อ-นามสกุล</TableCell>
                        {/* <TableCell align="center" style={{ minWidth: '200px' }}>นามสกุล</TableCell>  */}
                        <TableCell align="center" style={{ minWidth: '200px' }}>หมายเลขห้อง</TableCell> 
                        {
                          localStorage.getItem("projectType")==='isolation' ?
                          <TableCell align="center" style={{ minWidth: '200px' }}>โรงแรม</TableCell>
                          :
                          <TableCell align="center" style={{ minWidth: '200px' }}>หมายเลขเตียง</TableCell>
                        }
                        {/* <TableCell align="center" style={{ minWidth: '200px' }}>IMEI</TableCell> */}
                        <TableCell align="center" style={{ minWidth: '200px' }}>ทําแบบประเมินเป็นครั้งที่*</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>ท่านมีอาการเหล่านี้หรือไม่ </TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>เบื่อ ทําอะไรก็ไม่เพลิดเพลิน <br/> ไม่สนใจอยากทําอะไร </TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>ไม่สบายใจ ซึมเศร้า ท้อแท้ </TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>หลับยากหรือหลับๆ ตื่นๆ <br/> หรือหลับมากเกินไป</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>เหนื่อยง่ายหรือไม่ค่อยมีแรง </TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>เบื่ออาหารหรือกินมากเกินไป </TableCell>
                        <TableCell align="center" style={{ minWidth: '350px' }}>รู้สึกไม่ดีกับตัวเอง คิดว่าตัวเองล้มเหลว <br/>หรือเป็นคนทําให้ตัวเองหรือครอบครัวผิดหวัง</TableCell>
                        <TableCell align="center" style={{ minWidth: '350px' }}> สมาธิไม่ดีเวลาทําอะไร เช่น ดูโทรทัศน์ <br/> ฟังวิทยุ หรือทํางานที่ต้องใช้ความตั้งใจ </TableCell>
                        <TableCell align="center" style={{ minWidth: '350px' }}> พูดหรือทําอะไรช้าจนคนอื่นสังเกตเห็น <br/> หรือกระสับกระส่ายไม่สามารถอยู่นิ่งได้เหมือนเคย </TableCell>
                        <TableCell align="center" style={{ minWidth: '350px' }}> คิดทําร้ายตนเอง <br/> หรือคิดว่าถ้าตายไปเสียคงจะดี </TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}> Total PHQ9  </TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}> ผลลัพธ์  </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                    { result_mentalSymptoms.length === 0 ? <TableRow>
                        <TableCell colSpan={18}>There is no data.</TableCell>
                       </TableRow> : <></> 
                    }
                    {result_mentalSymptoms?.map((mental) => (
                      
                      <TableRow key={mental.id}>
                        <TableCell align="center">{dateStrAPI(mental.updated_at)} : {timeStr(mental.updated_at)}</TableCell>
            
                          {
                            mental.patient?.name || mental.patient?.surname  ? 
                            <TableCell align="center">{mental.patient.name} {mental.patient.surname}</TableCell>
                            :
                            <TableCell align="center">N/A</TableCell>
                          }

                          {/* {
                            mental.patient?.surname ? 
                            <TableCell align="center">{mental.patient.surname}</TableCell>
                            :
                            <TableCell align="center">-</TableCell>
                          } */}
                        
                          {
                            mental.onboard?.room_number ? 
                            <TableCell align="center">{mental.onboard.room_number}</TableCell>
                            :
                            <TableCell align="center">N/A</TableCell>
                          }

                          {
                            localStorage.getItem("projectType") ==='isolation' ?
                            <>
                              {
                                mental.onboard?.hotel ?
                                <TableCell align="center">{mental.onboard.hotel}</TableCell>
                                :
                                <>N/A</>
                              }
                            </> :
                            <>
                              {
                                mental.onboard?.bed_number ? 
                                <TableCell align="center">{mental.onboard.bed_number}</TableCell>
                                :
                                <TableCell align="center">N/A</TableCell>
                              }
                            </>
                          }
                          
                          {/* {
                            mental.patient?.imei ? 
                            <TableCell align="center">{mental.patient.imei}</TableCell>
                            :
                            <TableCell align="center">-</TableCell>
                          } */}

                          {
                            mental.assesment_number ? 
                            <TableCell align="center">{mental.assesment_number }</TableCell>
                            :
                            <TableCell align="center">N/A</TableCell>
                          }

                          {
                            mental.symptoms_mental ? 
                            <TableCell align="center">{mental.symptoms_mental }</TableCell>
                            :
                            <TableCell align="center">N/A</TableCell>
                          }

                          {
                            mental.mental_one? 
                            <TableCell align="center">{ mental.mental_one} </TableCell>
                            :
                            <TableCell align="center">0</TableCell>
                          }

                          {
                            mental.mental_two? 
                            <TableCell align="center">{ mental.mental_two} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span>0</span>
                            </TableCell>
                          }
                          
                          {
                            mental.mental_three? 
                            <TableCell align="center">{ mental.mental_three} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          }

                          {
                            mental.mental_four? 
                            <TableCell align="center">{ mental.mental_four} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          }

                          {
                            mental.mental_five? 
                            <TableCell align="center">{ mental.mental_five} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          }

                          {
                            mental.mental_six? 
                            <TableCell align="center">{ mental.mental_six} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          }

                          {
                            mental.mental_seven? 
                            <TableCell align="center">{ mental.mental_seven} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          }

                          {
                            mental.mental_eight? 
                            <TableCell align="center">{ mental.mental_eight} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          }

                          {
                            mental.mental_nine? 
                            <TableCell align="center">{ mental.mental_nine} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          }

                          {/* {
                            mental.mental_ten? 
                            <TableCell align="center">{ mental.mental_ten} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          } */}

                          {
                            mental.total? 
                            <TableCell align="center">{ mental.total} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> 0 </span>
                            </TableCell>
                          }

                          {
                            mental.result? 
                            <TableCell align="center">{ mental.result} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> N/A </span>
                            </TableCell>
                          }
                          
                          
                          
                      </TableRow>
                    ))}
                    
                    </TableBody>
                  </Table>
                </TableContainer>
                
                <Box mt={2}>
                  
                  <Pagination
                    count={pageCount}
                    page={filter?.page || 1}
                    onChange={handleChange}
                    size="small"
                  />
                  <FormControl style={{float:'right', marginTop: '-36px', marginRight: '2%'}} >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rowsPerPage}
                      onChange={handleChangeRowsPerPage}
                    >
                      <MenuItem value={10}>10/ per page</MenuItem>
                      <MenuItem value={25}>25/ per page</MenuItem>
                      <MenuItem value={50}>50/ per page</MenuItem>
                      <MenuItem value={100}>100/ per page</MenuItem>
                      
                    </Select>
                  </FormControl>
                </Box>
                <br/>
              </Paper>


      </Box>
    </Container>
  );
});