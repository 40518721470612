import { css, jsx } from "@emotion/react";
import React, { Fragment, useEffect, useState } from "react";
import _ from "lodash";
import {
  Box,
  Container,
  Grid,
  FormControl,
  Button,
  CircularProgress,
  Paper,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Typography,
  MuiThemeProvider,
  createMuiTheme,
  Avatar,
  LinearProgress,
  Badge,
} from "@material-ui/core";
import { SubBar } from "../Dashboard/Dashboard";
import { useHistory, useParams } from "react-router-dom";
import { useReadUser } from "../../services/api/user/useReadUser";
import LuxonUtils from "@date-io/luxon";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { webStore } from "../../stores/webStore";
import { observer } from "mobx-react-lite";
import { UserInfoCard } from "./UserInfoCard";
import { DeviceHistoryDatagrid } from "./DeviceHistoryDatagrid";
import { DateFilter } from "../../components/DateFilter";

import { BodyTempGraph } from "./BodyTempGraph";
import { HeartRateGraph } from "./HeartRateGraph";
import { BloodPressureGraph } from "./BloodPressureGrph";
import { UserCreateInput,UserCreateQuarantine, UserCreateHospitalInput } from "../../types/user";
import { useCreateUserHospital,CheckImeiPair } from "../../services/api/user/useCreateUser";
import { useSnackbar } from "notistack";
import { useListHospital } from "../../services/api/user/useListHospital";
import { useListAgencies } from "../../services/api/user/useListAgencies";
import { useListBranches, useListProject } from "../../services/api/user/useListBranches";
import { ToolbarComponentProps } from "@material-ui/pickers/Picker/Picker";
import { useUploadImage } from "../../services/api/user/useUploadImage";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { arrayIncludes } from "@material-ui/pickers/_helpers/utils";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from "@material-ui/icons/Close";

export const NON_REQUIRE_USER_FIELD = [
  "avatar",
  "avatar_url",
  "mac_address",
  "room_number",
  "bed_number",
  "place"
];

const LabelForm = (props: any) => {
  return (
    <Box fontSize={13} css={{ color: "rgba(75, 76, 86, 0.4)" }} mt={4} mb={1}>
      {props.children}
    </Box>
  );
};

const InputText = ({ input, form, onChange, ...rest }: any) => {
  return (
    <TextField
      label={input.label}
      value={form[input.name]}
      onChange={onChange}
      name={input.name}
      placeholder={"Please enter " + input.label}
      // helperText={`Please enter ${input.label}`}
      fullWidth
      margin="dense"
      // InputLabelProps={{
      //   shrink: true,
      // }}
      variant="outlined"
      size="small"
      {...rest}
    />
  );
};

export const ConfirmModal = observer(
  ({
    isConfirm,
    onClick,
    setIsConfirm,
    buttonName = "CONFIRM",
  }: {
    isConfirm: boolean;
    setIsConfirm: any;
    onClick?: any;
    buttonName?: string;
  }) => {

    return (
      <Modal
        open={isConfirm}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 350, borderRadius: 5 }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box css={{ color: "#4B4C56" }} fontSize={14}>

            </Box>
            <Box
              onClick={() => {
                setIsConfirm(false);
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <p style={{ color:'grey', textAlign: 'center', fontSize: '18px'}}>Do you confirm to add user?</p>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <Button
              onClick={() => {
                setIsConfirm(false);
              }}
              size="medium"
              variant="outlined"
            >
              CANCEL
            </Button>

            <Button
              onClick={onClick}
              color="primary"
              size="medium"
              variant="outlined"
              style={{marginLeft: '3%'}}
            >
              {buttonName}
            </Button> 
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);

export const ImeiPairModal = observer(
  ({
    isImeiPair,
    Imei1,
    Imei2,
    onClick,
    setIsImeiPair,
    result_imeiPair,
    result_devicePair,
    buttonName = "OK",
  }: {
    isImeiPair: boolean;
    setIsImeiPair: any;
    Imei1?: any;
    Imei2?: any;
    result_imeiPair?: any;
    result_devicePair?: any;
    onClick?: any;
    buttonName?: string;
  }) => {

    return (
      <Modal
        open={isImeiPair}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 350, borderRadius: 5 }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box css={{ color: "#4B4C56" }} fontSize={14}>

            </Box>
            <Box
              onClick={() => {
                setIsImeiPair(false);
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            {
              Imei1 && result_imeiPair === undefined ? 
              <p style={{ color:'grey', textAlign: 'center', fontSize: '18px'}}>
                Imei No-1. <b>{Imei1} </b> is already paired.
              </p> 
              : <></>
            }

          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            {
              Imei2 && result_devicePair=== undefined? 
              <p style={{ color:'grey', textAlign: 'center', fontSize: '18px'}}>
                Imei No-2. <b>{Imei2} </b> is already paired.
              </p> 
              : <></>
            }
            
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            <Button
              onClick={onClick}
              color="primary"
              size="medium"
              variant="outlined"
            >
              {buttonName}
            </Button> 
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);

const InputDropdown = observer(
  ({
    option,
    input,
    value,
    onChange,
    error,
  }: {
    option: any;
    input: any;
    value: any;
    onChange: any;
    error: boolean;
  }) => {
    return (
      <FormControl
        variant="outlined"
        style={{ width: "100%" }}
        disabled={(option || []).length === 0}
        required={NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0}
        size="small"
        margin="dense"
      >
        <InputLabel id="demo-simple-select-outlined-label">
          {input.label}
        </InputLabel>
        <Select
          disabled={(option || []).length === 0}
          labelId="demo-simple-select-outlined-label"
          name={input.name}
          label={input.label}
          value={value}
          onChange={onChange}
          fullWidth
          required={NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0}
          error={error}
        >
          {option?.map((v: any) => {
            return <MenuItem value={v.value}>{v.name}</MenuItem>;
          })}
        </Select>
      </FormControl>
    );
  }
);

export const InputDate = observer(
  ({
    input,
    form,
    onChange,
    error,
    value,
  }: {
    input: any;
    form?: any;
    onChange: any;
    error: boolean;
    value?: any;
  }) => {
    const f: any = form;
    const v: any = form ? f[input.name] : value;
    return (
      <FormControl
        required={NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0}
        style={{ width: "100%" }}
        size="small"
        margin="dense"
      >
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <KeyboardDatePicker
            required={NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0}
            style={{ margin: 0, width: "100%" }}
            autoOk
            format="dd/MM/yyyy"
            margin="normal"
            id="from"
            size="small"
            ToolbarComponent={(props: ToolbarComponentProps) => {
              return (
                <div
                  style={{
                    background: "#494592",
                  }}
                >
                  <Box p={2}>
                    <Button
                      size="small"
                      css={css`
                        font-size: 0.8rem;
                        color: white;
                        line-height: 1rem;
                        font-weight: bold;
                        text-transform: none;
                      `}
                      onClick={() => {
                        props.setOpenView("date");
                      }}
                    >
                      {props.date?.weekdayLong + " " + props.date?.day}
                    </Button>
                    <Grid>
                      <Button
                        size="small"
                        css={css`
                          font-size: 1.5rem;
                          line-height: 1.65rem;
                          font-weight: bold;
                          color: white;
                          text-transform: none;
                        `}
                        onClick={() => {
                          props.setOpenView("month");
                        }}
                      >
                        {props.date?.monthLong}
                      </Button>
                      <Button
                        size="small"
                        css={css`
                          font-size: 1.5rem;
                          line-height: 1.5rem;
                          font-weight: bold;
                          color: white;
                          text-transform: none;
                        `}
                        onClick={() => {
                          props.setOpenView("year");
                        }}
                      >
                        {props.date?.year}
                      </Button>
                    </Grid>
                  </Box>
                </div>
              );
            }}
            inputVariant="outlined"
            label={input.label}
            value={v}
            onChange={onChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            error={error}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    );
  }
);

//
export const UserDetailSeeMore = observer(() => {
  const [isConfirm, setIsConfirm] = useState(false);
  const [form, setForm] = useState<UserCreateHospitalInput>({
    name: "",
    surname: "",
    gender: "",
    date_of_birth: null,
    nationality: "",
    identity_number: "",
    weight: "",
    height: "",
    phone_number: "",
    disease : "",
    branch_id: "",
    place_id: "",
    mac_address: "",
    device_id_1: null,
    start_date: null,
    end_date: null,
    room_number: "",
    bed_number: "",
    place: []
  }); //

  const [ERROR_REQUIRE, setErrorRequired] = useState(Array());
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const { fire_user_create_hospital, loading_user_create_hospital } = useCreateUserHospital();
  const {result_imeiPair, loading_imeiPair } = CheckImeiPair(form.device_id_1);
  const [isImeiPair, setIsImeiPair] = useState(false);

  const { fire_upload_image, loading_upload_image } = useUploadImage();
  
  const { result_agency, result_branch, loading_project, error_project} = useListProject();
  const history = useHistory();
  const setVal = (k: any, v: any) => {
    let newV: any = { ...form };
    newV[k] = v;
    setForm(newV);
  };
  const onChange = (e: any) => {
    if (e.target.name) {
      setVal(e.target.name, e.target.value);
    }
    if (e.target.value !== null || e.target.value !== "") {
      setErrorRequired(ERROR_REQUIRE.filter((x) => x != e.target.name));
    }
  };

  

  const getPlace = (branch: any) => {
    let placeList = [];
    if(branch){
      placeList = branch[0].place;
      form.place = placeList;
    }
    return placeList;
  }

  let option: any = [];
  option["gender"] = [
    { value: "m", name: "Male" },
    { value: "f", name: "Female" },
  ];

  option["place_id"] = loading_project
  ? []
  : getPlace(result_branch)?.map((h: any) => {
      return { value: h.id, name: h.name_th };
    });
    
  option["branch_id"] = loading_project
    ? [] 
    :result_branch?.map((h: any) => {
        return { value: h.id, name: h.name_th };
      });
  useEffect(() => {
  }, [form && form.branch_id]);

  return (
    <>
      <Container maxWidth="md" className="py-4">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SubBar
              reload={false}
              back={true}
              title={"More Info"}
              // importExcel={"import"}
            />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md" className="py-2">
        <Paper variant="outlined" css={{ borderWidth: 0 }}>
          <Box py={4} px={{ xs: 2, md: 6 }}>
            <Box fontSize="h5.fontSize" fontWeight={600} mb={1}>
              User information
            </Box>
            <Grid container>
              <Box order={{ xs: 2, md: 0 }} width={{ xs: "100%", md: "100%" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <LabelForm>Information</LabelForm>
                  </Grid>
                  <Grid container spacing={1}>
                    {[
                      { name: "name", label: "Name", width: 6 },
                      { name: "surname", label: "Surname", width: 6 },
                    ].map((input) => {
                      return (
                        <Grid item md={(input.width as 6 | 2 | 5) || 6} xs={12}>
                          <InputText
                            {...{
                              onChange,
                              form,
                              input,
                              required:
                                NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0,
                              error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                            }}
                          />
                        </Grid>
                      );
                    })}
                    <Grid item md={2} xs={12}>
                      <InputDropdown
                        option={option["gender"]}
                        input={{ name: "gender", label: "Gender" }}
                        value={form["gender"]}
                        onChange={onChange}
                        error={ERROR_REQUIRE.indexOf("gender") >= 0}
                      />
                    </Grid>
                    {[{ name: "nationality", label: "Nationality" }].map(
                      (input) => {
                        return (
                          <Grid item md={5} xs={12}>
                            <InputText
                              {...{
                                onChange,
                                form,
                                input,
                                required:
                                  NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                  0,
                                error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                              }}
                            />
                          </Grid>
                        );
                      }
                    )}
                    <Grid item md={5} xs={12}>
                      <InputDate
                        form={form}
                        input={{
                          name: "date_of_birth",
                          label: "Date of Birth",
                        }}
                        onChange={(date: MaterialUiPickersDate) => {
                          setVal(
                            "date_of_birth",
                            date ? new Date(date.toString()) : null
                          );
                          if (date) {
                            setErrorRequired(
                              ERROR_REQUIRE.filter((x) => x != "date_of_birth")
                            );
                          }
                        }}
                        error={ERROR_REQUIRE.indexOf("date_of_birth") >= 0}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                  {[{ name: "weight", label: "Weight" }].map(
                      (input) => {
                        return (
                          <Grid item md={4} xs={12}>
                            <InputText
                              {...{
                                onChange,
                                form,
                                input,
                                required:
                                  NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                  0,
                                error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                              }}
                            />
                          </Grid>
                        );
                      }
                    )}

                    {[{ name: "height", label: "Height" }].map(
                      (input) => {
                        return (
                          <Grid item md={4} xs={12}>
                            <InputText
                              {...{
                                onChange,
                                form,
                                input,
                                required:
                                  NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                  0,
                                error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                              }}
                            />
                          </Grid>
                        );
                      }
                    )}

                    {[{ name: "phone_number", label: "Phone Number" }].map(
                      (input) => {
                        return (
                          <Grid item md={4} xs={12}>
                            <InputText
                              {...{
                                onChange,
                                form,
                                input,
                                required:
                                  NON_REQUIRE_USER_FIELD.indexOf(input.name) <
                                  0,
                                error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                              }}
                            />
                          </Grid>
                        );
                      }
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <LabelForm>ID Card/ Passport Number *</LabelForm>
                  </Grid>
                  {[{ name: "identity_number", label: "ID Card/ Passport Number" }].map(
                    (input) => {
                      return (
                        <Grid item xs={12}>
                          <InputText
                            {...{
                              onChange,
                              form,
                              input,
                              required:
                                NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0,
                              error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                            }}
                          />
                        </Grid>
                      );
                    }
                  )}

                <Grid item xs={12}>
                    <LabelForm>Disease *</LabelForm>
                  </Grid>
                  {[{ name: "disease", label: "Disease" }].map(
                    (input) => {
                      return (
                        <Grid item xs={12}>
                          <InputText
                            {...{
                              onChange,
                              form,
                              input,
                              required:
                                NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0,
                              error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                            }}
                          />
                        </Grid>
                      );
                    }
                  )}
                  
                  {/* <Box mb={3} width="100%">
                    &nbsp;
                  </Box> */}
                  <Grid item xs={6}>
                    <LabelForm>Wristband</LabelForm>
                  </Grid>
                  <Grid item xs={6}>
                    <LabelForm>Oximeter</LabelForm>
                  </Grid>
                  {/* spacing={1} */}
                  <Grid container spacing={1}>
                    {[
                      { name: "device_id_1", label: "IMEI 01" },
                      { name: "mac_address", label: "Mac Address" },
                    ].map((input) => {
                      return (
                        <Grid item md={6} xs={12}>
                          <InputText
                            {...{
                              onChange,
                              form,
                              input,
                              required:
                                NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0,
                              error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  
                  <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <LabelForm>Branch*</LabelForm>
                  </Grid>
                  <Grid item md={6} xs={12}>
                   {form.branch_id ?  <LabelForm>Place/Hospital*</LabelForm> : <></>}
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <InputDropdown
                      option={option["branch_id"]}
                      // input={{ name: "branch_id", label: "Branch" }}
                      input={{ name: "branch_id" }}
                      value={form["branch_id"]}
                      onChange={onChange}
                      error={ERROR_REQUIRE.indexOf("branch_id") >= 0}
                    />
                  </Grid>
                  {form.branch_id ?  
                      <>
                        {[{ name: "place_id", label:"Place/Hospital"}].map(
                          (input) => {
                            return (
                              <Grid item md={6} xs={12}>
                                <Autocomplete
                                  id="place_id"
                                  freeSolo
                                  onChange={(event, value) =>{
                                      setVal(
                                        input.name,
                                        value ? value : ""
                                      );
                                      if (value) {
                                        setErrorRequired(
                                          ERROR_REQUIRE.filter((x) => x != input.name)
                                        );
                                      }
                                  }} 
                                  options={getPlace(result_branch)?.map((option : any) => option.name_th)}
                                  renderInput={(params) => (
                                    <InputText {...params}
                                      {...{
                                        onChange,
                                        form,
                                        input,
                                        required:
                                          NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0,
                                        error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            );
                          }
                        )}
                      </>
                      : <></>

                    }

                  </Grid>

                  <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <LabelForm>Room Number</LabelForm>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <LabelForm>Bed Number</LabelForm>
                  </Grid>
                  
                  {[{ name: "room_number", label: "Room Number" }].map(
                    (input) => {
                      return (
                        <Grid item md={6} xs={12}>
                          <InputText
                            {...{
                              onChange,
                              form,
                              input,
                              required:
                                NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0,
                              error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                            }}
                          />
                        </Grid>
                      );
                    }
                  )}

                {[{ name: "bed_number", label: "Bed Number" }].map(
                    (input) => {
                      return (
                        <Grid item md={6} xs={12}>
                          <InputText
                            {...{
                              onChange,
                              form,
                              input,
                              required:
                                NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0,
                              error: ERROR_REQUIRE.indexOf(input.name) >= 0,
                            }}
                          />
                        </Grid>
                      );
                    }
                  )}

                  </Grid>
                  
                  <Grid item xs={12}>
                    <LabelForm>Date</LabelForm>
                  </Grid>
                  {/* spacing={1} */}
                  <Grid item xs={12}>
                    {[
                      { name: "start_date", label: "Start Date" },
                      { name: "end_date", label: "End Date" },
                    ].map((input) => {
                      return (
                        <Grid item md={6} xs={12}>
                          <InputDate
                            form={form}
                            input={input}
                            onChange={(date: MaterialUiPickersDate) => {
                              setVal(
                                input.name,
                                date ? new Date(date.toString()) : null
                              );
                              if (date) {
                                setErrorRequired(
                                  ERROR_REQUIRE.filter((x) => x != input.name)
                                );
                              }
                            }}
                            error={ERROR_REQUIRE.indexOf(input.name) >= 0}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  {/* <Box mb={3} width="100%">
                    &nbsp;
                  </Box> */}
                  {/* spacing={1} */}
                  <Grid item xs={12}>
                    <Grid item md={6} xs={12}>
                      <Box mt={6}>
                        
                      </Box>
                    </Grid>
                  </Grid>
                  <ConfirmModal
                      isConfirm={isConfirm}
                      setIsConfirm={setIsConfirm}
                      onClick={async () => {
                        setLoading(true);
                        try {
                          fire_user_create_hospital(form)
                            .then(() => {
                              history.goBack();
                              enqueueSnackbar("สมัครสมาชิกสำเร็จ", {
                                variant: "success",
                                autoHideDuration: 3000,
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "center",
                                },
                              });
                            })
                            .catch((ex) => {
                              enqueueSnackbar(
                                "สมัครสมาชิกไม่สำเร็จ" +
                                  (ex ? " : " + ex.toString() : ""),
                                {
                                  variant: "error",
                                  autoHideDuration: 3000,
                                  anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                  },
                                }
                              );
                            });
                        } catch (ex) {}
                        setIsConfirm(false);
                        setLoading(false);
                        
                      }}
                    />

                    <ImeiPairModal
                      isImeiPair={isImeiPair}
                      setIsImeiPair={setIsImeiPair}
                      Imei1={form.device_id_1}
                      result_imeiPair ={result_imeiPair}
                      onClick={async () => {
                        setIsImeiPair(false);
                      }}
                    />
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </>
  );
});
export const UserDetailSeeMoreMobile = UserDetailSeeMore;
