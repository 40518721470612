import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  InputBase
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { SubBar } from "../Dashboard/Dashboard";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
//   DatePicker
// } from "@material-ui/pickers";

import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from "material-ui-thai-datepickers";
import { Filter,DailyReportFilter } from "../../types/user";
import { webStore } from "../../stores/webStore";
import LuxonUtils from "@date-io/luxon";
import 'moment/locale/th';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import SearchIcon from "@material-ui/icons/Search";
import {  makeStyles, useTheme, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { dateStr, dateStrAPI, timeStr } from "../../utils";
import { DailyReportList, DailyReport_List } from "../../services/api/report/DailyReportList";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import axios, { AxiosRequestConfig, Method } from 'axios';
import {saveAs}  from 'file-saver';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%'
  },
  container: {
    maxHeight: 500,
  },
});

const ExportButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#0CA789',
    borderColor: '#0CA789',
    '&:hover': {
      backgroundColor: '#0CA789',
      borderColor: '#0CA789',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0CA789',
      borderColor: '#0CA789',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button);

// const theme = createTheme({
//   palette: {
//     primary: '#0CA789',
//   },
// });


const UserFilter = observer(({ display = true }: { display?: boolean }) => {
  const [startDate, setStartDate] = useState<Date | null>(webStore.startDate);
  const [endDate, setEndDate] = useState<Date | null>(webStore.endDate);
  const [fliterSymptoms, setFilterSymptoms] = useState <string |null>(
    webStore.filter_symptom || ""
  );
  const [fliterSymptomCase, setFilterSymptomCase] = useState <string |null>(
    webStore.filter_symptomCase || ""
  );
  const [fliterPeriod, setFilterPeriod] = useState <string |null>(
    webStore.filter_period || ""
  );
  const [fliterSpo2, setFilterSpo2] = useState <string |null>(
    webStore.filter_spo2 || ""
  );
  const [filterTemperature, setFilterTemperature] = useState<string | null>(
    webStore.filter_temperature || ""
  );

  const TempOptions = [
    { value: "0", label: "All"},
    { value: "3", label: "High >= 38.5" },
    { value: "2", label: "Normal 35.1- 38.4" },
    { value: "1", label: "Low <=35" },
  ];

  const SymptomOptions = [
    { value: "0", label: "All"},
    {value: "true", label: "มีอาการผิดปกติ"},
    {value: "false", label: "ไม่มีอาการผิดปกติ"}
  ];

  const SymptomCaseOptions = [
    { value: "0", label: "All"},
    {value: "1" , label: "Green"},
    {value: "2", label: "Yellow"}
  ];

  let PeriodOptions = [
    { value: "0", label: "All"},
    {value: "1", label: 'ช่วงเช้า 06.00 น.'},
    {value: "2" , label: 'ช่วงเย็น 18.00 น.'}
  ];

  if(localStorage.getItem("projectKey") === '7tr84n2xhsvepht7t99wqc5k4db5yk5kpitekq3d'){
    PeriodOptions = [
      { value: "0", label: "All"},
      {value: "1", label: 'ช่วงเช้า 08.00 น.'},
      {value: "2" , label: 'ช่วงเย็น 14.00 น.'},
      {value: "3" , label: 'ช่วงเย็น 20.00 น.'}
    ];
  }else {
    PeriodOptions = [
      { value: "0", label: "All"},
      {value: "1", label: 'ช่วงเช้า 06.00 น.'},
      {value: "2" , label: 'ช่วงเย็น 18.00 น.'}
    ];
  }

  const Spo2Options = [
    { value: "0", label: "All"},
    {value: "2", label: 'Normal 96-100'},
    {value: "1", label: 'Low <=95'}
  ];
  const history = useHistory();

  useEffect(() => {
    // console.log("changee");
    webStore.setStartDate(startDate);
    webStore.setEndDate(endDate);
    webStore.set_filter_temperature(filterTemperature);
    webStore.set_filter_symptom(fliterSymptoms);
    webStore.set_filter_symptomCase(fliterSymptomCase);
    webStore.set_filter_period(fliterPeriod);
    webStore.set_filter_spo2(fliterSpo2);
  }, [
    startDate,
    endDate,
    filterTemperature,
    fliterSymptoms,
    fliterSymptomCase,
    fliterPeriod, 
    fliterSpo2
  ]);

  const ChangeDateFormat = (dateData : Date | null) =>{
    let splitData;
    let dateFormat : any;
    let splitDate;
    let month;
    let day;
    let year;

    if(dateData){
      let data = dateData.toString();
      console.log("data date:", data);
      splitData = data.split(" ");
      console.log("split Data", splitData);
      splitDate = data.split(" ");
      year = splitDate[3];
      day = splitDate[2];
      if (splitDate[1] ===  'Jan'){
        month = '01';
      } else if (splitDate[1] ===  'Feb'){
        month = '02';
      } else if (splitDate[1] ===  'Mar'){
        month = '03';
      } else if (splitDate[1] ===  'Apr'){
        month = '04';
      }  else if (splitDate[1] ===  'May'){
        month = '05';
      }else if (splitDate[1] ===  'Jun'){
        month = '06';
      }else if (splitDate[1] ===  'Jul'){
        month = '07';
      }else if (splitDate[1] ===  'Aug'){
        month = '08';
      }else if (splitDate[1] ===  'Sep'){
        month = '09';
      }else if (splitDate[1] ===  'Oct'){
        month = '10';
      }else if (splitDate[1] ===  'Nov'){
        month = '11';
      }else {
        month = '12';
      }
    let checkYear = parseInt(year);
    if (checkYear< 2564){
      let buddishtYear = checkYear + 543;
      year = buddishtYear.toString();
    }

    dateFormat = day + "/" + month + "/" + year;
    console.log( "date format: ",dateFormat);
    }
    if(!dateFormat){
      dateFormat = new Date();
    }
    return dateFormat;
  }

  const excelExport = () => {
    let instance = axios.create({  baseURL: "https://api.space-trax.com" }); 
    const method = 'GET';
    let end_date;
    let start_date;
    let period;
    let symptomCase;
    let symptom;
    let temperature;
    let spo2;

    if(!endDate){
      end_date = ChangeDateFormat(new Date());
    }else {
      end_date = ChangeDateFormat(endDate);
    }

    if(!startDate) {
      start_date = ChangeDateFormat(new Date());
    }else{
      start_date = ChangeDateFormat(startDate);
    }

    if (!fliterPeriod){
      period = '';
    }else {
      period = fliterPeriod;
    }

    if(!fliterSymptomCase){
      symptomCase = '';
    }else{
      symptomCase = fliterSymptomCase;
    }

    if(!fliterSymptoms){
      symptom = '';
    }else {
      symptom = fliterSymptoms;
    }

    if(!filterTemperature){
      temperature = ''
    }else{
      temperature = filterTemperature
    }
    
    if(!fliterSpo2){
      spo2 = ''
    }else{
      spo2 = fliterSpo2;
    }

    const url = "https://api.space-trax.com/v2/dashboard/user/daily-list/export?search_start="+ start_date
    +"&search_end=" + end_date + "&assesment_period=" + period + "&symptoms_case=" + symptomCase
    +"&temperature=" + temperature + "&is_abnormal=" + symptom + "&SPO2=" + spo2
    + "&page=1&limit=10000";
    const headers = {
      "Project-Key": localStorage.getItem("projectKey"),
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    let options: AxiosRequestConfig = { 
      url,
      method,
      responseType: 'blob' ,
      headers
    };

    return instance.request<any>(options)
    .then(response => { 
      // let filename = response.headers['content-disposition']
      //   .split(';')
      //   .find((n: any) => n.includes('filename='))
      //   .replace('filename=', '')
      //   .trim();      
      let url = window.URL
        .createObjectURL(new Blob([response.data]));     
      saveAs(url, "dailySymptoms.xlsx");    
  });
  }
  


  return (
    <Box mb={3}>
      {display && (
        <>
          <Box
            display="flex"
            py={2}
            css={{
              fontSize: 13,
              borderRadius: 5,
              backgroundColor: "#fff",
              "@media (max-width: 992px)": {
                flexFlow: "wrap",
                backgroundColor: "transparent",
              },
            }}
          >
            <Box
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              display="flex"
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                },
              }}
            >
              <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                <KeyboardDatePicker
                  style={{
                    margin: 0,
                    width: "100%",
                    borderWidth: 0,
                    fontSize: 13,
                  }}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="from"
                  label="Date Start"
                  autoOk={true}
                  value={startDate || new Date()}
                  onChange={(date: MaterialUiPickersDate) => {
                    setStartDate(date ? new Date(date.toString()) : null);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  yearOffset={543}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              display="flex"
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                  borderWidth: 0,
                },
              }}
            >
             
              <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                <KeyboardDatePicker
                  style={{ margin: 0, width: "100%", fontSize: 13 }}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="to"
                  label="Date End"
                  autoOk={true}
                  value={endDate || new Date()}
                  onChange={(date: MaterialUiPickersDate) => {
                    setEndDate(date ? new Date(date.toString()) : null);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  yearOffset={543}
                />
              </MuiPickersUtilsProvider>
            </Box>
            
            <Box
              display="flex"
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                  borderWidth: 0,
                },
              }}
            >
              <FormControl css={{ width: "100%" }}>
                <InputLabel id="period-label">ช่วงเวลาที่ประเมินอาการ</InputLabel>
                <Select
                  value={fliterPeriod}
                  disableUnderline
                  css={{ fontFamily: "inherit", fontSize: "inherit" }}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setFilterPeriod(event.target.value as string);
                  }}
                  labelId="period-label"
                >
                  {PeriodOptions.map((v) => {
                    return <MenuItem value={v.value}>{v.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>

            <Box
              display="flex"
              px={2}
              mb={{ xs: 3, md: 0 }}
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                },
              }}
            >
              <FormControl css={{ width: "100%" }}>
                <InputLabel id="filterSymptomCase-label">Symptoms Case</InputLabel>
                <Select
                  value={fliterSymptomCase}
                  disableUnderline
                  css={{ fontFamily: "inherit", fontSize: "inherit" }}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setFilterSymptomCase(event.target.value as string);
                  }}
                  labelId="filterSymptomCase-label"
                >
                  {SymptomCaseOptions.map((v) => {
                    return <MenuItem value={v.value}>{v.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>

          </Box>
          <Box
            display="flex"
            py={2}
            css={{
              fontSize: 13,
              borderRadius: 5,
              backgroundColor: "#fff",
              "@media (max-width: 992px)": {
                flexFlow: "wrap",
                backgroundColor: "transparent",
              },
            }}
          >
            <Box
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              display="flex"
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                },
              }}
            >
              <FormControl css={{ width: "100%" }}>
                <InputLabel id="filterSymptoms-label">อาการปัจจุบัน</InputLabel>
                <Select
                  value={ fliterSymptoms }
                  disableUnderline
                  css={{ fontFamily: "inherit", fontSize: "inherit" }}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setFilterSymptoms(event.target.value as string);
                  }}
                  labelId="filterSymptoms-label"
                >
                  {SymptomOptions.map((v) => {
                    return <MenuItem value={v.value}>{v.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              display="flex"
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                  borderWidth: 0,
                },
              }}
            >
              <FormControl css={{ width: "100%" }}>
                <InputLabel id="filterTem-label">Temperature</InputLabel>
                <Select
                  value={filterTemperature}
                  disableUnderline
                  css={{ fontFamily: "inherit", fontSize: "inherit" }}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setFilterTemperature(event.target.value as string);
                  }}
                  labelId="filterTem-label"
                >
                  {TempOptions.map((v) => {
                    return <MenuItem value={v.value}>{v.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            
            <Box
              display="flex"
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                  borderWidth: 0,
                },
              }}
            >
              <FormControl css={{ width: "100%" }}>
                <InputLabel id="filterSpo2-label">ค่าความเข้มข้นออกซิเจน (%)</InputLabel>
                <Select
                  value={fliterSpo2}
                  disableUnderline
                  css={{ fontFamily: "inherit", fontSize: "inherit" }}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setFilterSpo2(event.target.value as string);
                  }}
                  labelId="filterSpo2-label"
                >
                  {Spo2Options.map((v) => {
                    return <MenuItem value={v.value}>{v.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            
            <Box
              display="flex"
              px={2}
              mb={{ xs: 3, md: 0 }}
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                },
              }}
            >
              <Button
                color="primary"
                onClick={() => {
                  console.log("search");
                }}
                css={{
                  padding: 8,
                  minWidth: "unset",
                  boxShadow: "unset",
                  marginRight: '2%'
                }}
              >
                <SearchIcon />
              </Button>
              <Button
                  variant="contained"
                  color="primary"
                  css={{ padding:8, fontSize: 12, marginRight: '2%' }}
                  onClick={() => {
                    webStore.setStartDate(null);
                    webStore.setEndDate(null);
                    webStore.set_filter_temperature(null);
                    webStore.set_filter_symptomCase(null);
                    webStore.set_filter_symptom(null);
                    webStore.set_filter_spo2(null);
                    webStore.set_filter_period(null);
                    
                    setStartDate(webStore.startDate);
                    setEndDate(webStore.endDate);
                    setFilterTemperature(webStore.filter_temperature);
                    setFilterSymptomCase(webStore.filter_symptomCase);
                    setFilterSymptoms(webStore.filter_symptom);
                    setFilterPeriod(webStore.filter_period);
                    setFilterSpo2(webStore.filter_period);
                  }}
                >
                  Clear
              </Button>
              <ExportButton
                  style= {{marginLeft: "2%", fontSize : '12px'}}
                  variant="contained"
                  color="primary" disableRipple 
                  css={{ padding:8, color:'#fff' }}
                  onClick = {excelExport}
                >
                  EXPORT
              </ExportButton>
              <ExportButton
                  style= {{marginLeft: "3%", fontSize : '12px'}}
                  variant="contained"
                  color="primary" disableRipple 
                  css={{ padding:8, color:'#fff' }}
                  onClick={() => {
                      history.push("/report/importDailyForm");
                  }}
                >
                  UPLOAD
              </ExportButton>
            </Box>

          </Box>
          <Box textAlign="right">
            
          </Box>
        </>
      )}
    </Box>
  );
});

export const DailyReport = observer((props: any) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    return () => {
      webStore.resetDailyReportFilter();
    };
  }, []);

  const mapRef = useRef<any>();
  // const [page, setPage] = useState<number>(0);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const filter: DailyReportFilter = {
    startDate: webStore.startDate || new Date(),
    endDate: webStore.endDate || new Date(),
    filter_temperature: webStore.filter_temperature,
    filter_period: webStore.filter_period,
    filter_symptom: webStore.filter_symptom,
    filter_symptomCase: webStore.filter_symptomCase,
    filter_spo2: webStore.filter_spo2, 
    page: page,
    perPage: rowsPerPage,
  };

  let { result_dailySymptoms, loading_dailySymptoms, total } = DailyReportList(filter);
  console.log("result_dailySymptoms in veiw: ", result_dailySymptoms);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: any }>) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const pageCount = Math.ceil(total / (filter?.perPage || 10));
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const removeLastComa = (symptoms : string) => {
    let symptom = "";
    let splitSypmtoms;
    splitSypmtoms = symptoms.split(",");
    console.log("splitSymptoms:", splitSypmtoms);
    let count = splitSypmtoms.length - 1;
    let lastValue = splitSypmtoms[count];
    console.log("last Value: ", lastValue);
    if(splitSypmtoms.length>1){
      if (lastValue === " "){
        console.log("hi");
          for ( let i = 0; i < splitSypmtoms.length; i++){
            if (i !== splitSypmtoms.length - 2){
              if(splitSypmtoms[i] !== " "){
                symptom = symptom + splitSypmtoms[i] + ",";
              }
              console.log("with coma");
            }else {
              symptom = symptom + splitSypmtoms[i];
              console.log("without coma");
            }
        }
      }else{
        for ( let i = 0; i < splitSypmtoms.length; i++){
            if (i !== splitSypmtoms.length - 1){
              if(splitSypmtoms[i] !== " "){
                symptom = symptom + splitSypmtoms[i] + ",";
              }
            }else {
              symptom = symptom + splitSypmtoms[i];
            }
        }
      }
      
    }else {
      symptom  = splitSypmtoms[0];
    }
    
    console.log("remove coma:", symptom);
    return symptom;
  }

  return (
    <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16">
      <Box mb={2}>
        <Grid item xs={12}>
          <SubBar title={"Daily Report"} reload={false} back={true}  />
        </Grid>
      </Box>
      <Grid item xs={12} sm={12}>
        <Box display={{ xs: "block", md: "block" }}>
          <UserFilter/>
        </Box>
      </Grid>
      <Box
        className="relative"
        css={{
          backgroundColor: "#fff",
          borderRadius: 5,
          cursor: "pointer",
          "@media (max-width: 992px)": { backgroundColor: "transparent" },
          height: "100%",
        }}
      >
           <Paper className={classes.root}>
           {loading_dailySymptoms && (
                <LinearProgress
                  style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
                />
              )}
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ minWidth: '200px' }}>วันเวลาที่ทําแบบประเมิน</TableCell>
                        <TableCell align="center" style={{ minWidth: '150px' }}>Symptoms Case</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>ชื่อ-นามสกุล</TableCell>
                        {/* <TableCell align="center" style={{ minWidth: '200px' }}></TableCell>  */}
                        <TableCell align="center" style={{ minWidth: '200px' }}>หมายเลขห้อง</TableCell> 
                        {
                          localStorage.getItem("projectType")==='isolation' ?
                          <TableCell align="center" style={{ minWidth: '200px' }}>โรงแรม</TableCell>
                          :
                          <TableCell align="center" style={{ minWidth: '200px' }}>หมายเลขเตียง</TableCell>
                        }
                        
                        {/* <TableCell align="center" style={{ minWidth: '200px' }}>IMEI</TableCell> */}
                        <TableCell align="center" style={{ minWidth: '200px' }}>ช่วงเวลาที่ประเมินอาการ <br/> (Assessment Peroid)</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>อาการปัจจุบัน <br/>(Current Symptoms)</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>อาการผิดปกติ ณ ปัจจุบัน <br/> (1)</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>อาการผิดปกติ ณ ปัจจุบัน <br/>(2)</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>Temperature <br/>( C )</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>Heart Rate <br/> ( bpm )</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>Blood Pressure<br/>( mmHg )</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>Respiration<br/>( per min )</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>ค่าความเข้มข้นออกซิเจน <br/> ก่อนออกกําลังกาย(%)</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>ค่าความเข้มข้นออกซิเจน <br/> หลังออกกําลังกาย( % )</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}>จํานวนครั้งที่ปัสสาวะ <br/>ใน 12 ชั่วโมงที่ผ่านมา  <br/>( ครั้ง )</TableCell>
                        <TableCell align="center" style={{ minWidth: '200px' }}> จํานวนครั้งที่อุจจาระ <br/> ใน 12 ชั่วโมงที่ผ่านมา  <br/>( ครั้ง )</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    { result_dailySymptoms.length === 0 ? <TableRow>
                        <TableCell colSpan={18}>There is no data.</TableCell>
                       </TableRow> : <></> 
                    }
                    {result_dailySymptoms?.map((daily) => (
                      
                      <TableRow key={daily.id}>
                        <TableCell align="center">{dateStrAPI(daily.updated_at)} : {timeStr(daily.updated_at)}</TableCell>
                        
                          {
                            daily.symptoms_case === 2 ? 
                            <TableCell align="center">
                              <FiberManualRecordIcon style={{color: 'yellow', fontSize:'20px', paddingRight:'4px'}}/>
                              Yellow 
                            </TableCell>
                            :
                            <TableCell align="center"> 
                              <FiberManualRecordIcon style={{color: 'green', fontSize:'20px', paddingRight:'4px'}}/>Green 
                            </TableCell>
                          }

                          {
                            daily.patient?.name || daily.patient?.surname? 
                            <TableCell align="center">{daily.patient.name} {daily.patient.surname}</TableCell>
                            :
                            <TableCell align="center">N/A</TableCell>
                          }

                          {/* {
                            daily.patient?.surname ? 
                            <TableCell align="center">{daily.patient.surname}</TableCell>
                            :
                            <TableCell align="center">N/A</TableCell>
                          } */}
                        
                          {
                            daily.onboard?.room_number ? 
                            <TableCell align="center">{daily.onboard.room_number}</TableCell>
                            :
                            <TableCell align="center">N/A</TableCell>
                          }

                          
                          {
                            localStorage.getItem("projectType") === "isolation" ?
                            <>
                              {
                                daily.onboard?.hotel ? 
                                <TableCell align="center">{daily.onboard.hotel}</TableCell>
                                :
                                <TableCell align="center">N/A</TableCell>
                              }
                            </>
                            :
                            <>
                              {
                                daily.onboard?.bed_number ? 
                                <TableCell align="center">{daily.onboard.bed_number}</TableCell>
                                :
                                <TableCell align="center">N/A</TableCell>
                              }
                            </>
                            
                          }

                          {/* {
                            daily.patient?.imei ? 
                            <TableCell align="center">{daily.patient.imei}</TableCell>
                            :
                            <TableCell align="center">N/A</TableCell>
                          } */}

                          {
                            localStorage.getItem("projectKey") === '7tr84n2xhsvepht7t99wqc5k4db5yk5kpitekq3d' ?
                            <>
                              {
                                daily.assesment_period === 1 ? 
                                <TableCell align="center">ช่วงเช้า 08.00 น. </TableCell>
                                :
                                daily.assesment_period === 2 ?
                                <TableCell align="center">ช่วงเย็น 14.00 น.</TableCell>
                                :
                                <TableCell align="center">ช่วงเย็น 20.00 น.</TableCell>
                              }
                            </>
                            :
                            <>
                                {
                                  daily.assesment_period === 1 ? 
                                  <TableCell align="center">ช่วงเช้า 06.00 น. </TableCell>
                                  :
                                  <TableCell align="center">ช่วงเย็น 18.00 น.</TableCell>
                                }
                            </>
                          }

                          {
                            daily.is_abnormal ===  true ? 
                            <TableCell align="center">มีอาการผิดปกติ </TableCell>
                            :
                            <TableCell align="center">ไม่มีอาการผิดปกติ</TableCell>
                          }

                          {
                            daily.symptoms_one ? 
                            <TableCell align="center">{ removeLastComa(daily.symptoms_one)} </TableCell>
                            :
                            <TableCell align="center"> 
                              <span> N/A </span>
                            </TableCell>
                          }
                          
                          <>
                          {(function() {
                              switch (daily.symptoms_two) {
                                case '':
                                  return <TableCell align="center"> N/A </TableCell>;
                                case 'ไม่มีอาการใดใดข้างต้น':
                                  return <TableCell align="center"> ไม่มีอาการใดใดข้างต้น </TableCell>;
                                case 'None of the above' :
                                  return <TableCell align="center">None of the above</TableCell>;
                                default:
                                  return <TableCell align="center"> 
                                  <span style={{color: 'red'}}> {removeLastComa(daily.symptoms_two)} </span>
                                </TableCell>;
                              }
                            })()}
                          </>
                          {/* { daily.symptoms_two ? 
                              {daily.symptoms_two ===  'ไม่มีอาการใดใดข้างต้น' ? 
                              <TableCell align="center"> ไม่มีอาการใดใดข้างต้น </TableCell>
                              :
                              <TableCell align="center"> 
                                <span style={{color: 'red'}}> {daily.symptoms_two} </span>
                              </TableCell>} :
                            <TableCell align="center"> 
                              <span> N/A </span>
                            </TableCell>
                          } */}

                          {
                            daily.tempurature >= 38.5 || daily.tempurature <= 35 ? 
                            <TableCell align="center"> 
                              <span style={{color: 'red'}}> {daily.tempurature }</span>
                            </TableCell>
                            :
                            <TableCell align="center"> {daily.tempurature} </TableCell>
                          }

                          {
                            daily.heart_rate <= 60 || daily.heart_rate >= 125 ? 
                            <TableCell align="center"> 
                              <span style={{color: 'red'}}> {daily.heart_rate} </span>
                            </TableCell>
                            :
                            <TableCell align="center"> {daily.heart_rate} </TableCell>
                          }
                          
                          {/* {
                            daily.blood_pressure <= 85/40 || daily.blood_pressure >= 165/130 ? 
                            <TableCell align="center"> 
                              <span style={{color: 'red'}}> daily.heart_rate </span>
                            </TableCell>
                            :
                            <TableCell align="center"> daily.heart_rate </TableCell>
                          } */}
                          {
                            daily.blood_pressure === "0/0" ?
                            <TableCell align="center"> N/A </TableCell>
                            :
                            <TableCell align="center"> {daily.blood_pressure} </TableCell>
                          }

                          {
                            daily?.breath ? 
                            <TableCell align="center"> {daily.breath} </TableCell>
                            :
                            <TableCell align="center"> N/A </TableCell>
                          }
                          

                        {
                            daily.oxygen_after <= 94 ? 
                            <TableCell align="center"> 
                              <span style={{color: 'red'}}> {daily.oxygen_after} </span>
                            </TableCell>
                            :
                            <TableCell align="center"> {daily.oxygen_after} </TableCell>
                          }

                          {
                            daily.oxygen_before <= 94 ? 
                            <TableCell align="center"> 
                              <span style={{color: 'red'}}> {daily.oxygen_before} </span>
                            </TableCell>
                            :
                            <TableCell align="center"> {daily.oxygen_before} </TableCell>
                          }
                        <TableCell align="center">
                          {daily.urination_among}
                        </TableCell>
                        <TableCell align="center">
                          {daily.defecation_among}
                        </TableCell>
                      </TableRow>
                    ))}
                        
                    </TableBody>
                  </Table>
                </TableContainer>
                
                <Box mt={2}>
                  
                  <Pagination
                    count={pageCount}
                    page={filter?.page || 1}
                    onChange={handleChange}
                    size="small"
                  />
                  <FormControl style={{float:'right', marginTop: '-36px', marginRight: '2%'}} >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rowsPerPage}
                      onChange={handleChangeRowsPerPage}
                    >
                      <MenuItem value={10}>10/ per page</MenuItem>
                      <MenuItem value={25}>25/ per page</MenuItem>
                      <MenuItem value={50}>50/ per page</MenuItem>
                      <MenuItem value={100}>100/ per page</MenuItem>
                      
                    </Select>
                  </FormControl>
                </Box>
                <br/>
                  
                {/* <TablePagination 
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="paper"
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </Paper>


      </Box>
      

    </Container>
  );
});