import { Theme } from "@emotion/react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  createStyles,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useHistory } from "react-router-dom";
import xlsx from "xlsx";
import fa_cloud_upload from "../../components/assets/fa-cloud-upload.svg";
import fa_upload_white from "../../components/assets/fa-upload-white.svg";
import fa_upload from "../../components/assets/fa-upload.svg";
// import template from "../../components/assets/import_users_template.xlsx";
import { useImportUser } from "../../services/api/user/useImportUser";
import { useListBranches } from "../../services/api/user/useListBranches";
import { SubBar } from "../Dashboard/Dashboard";
import { NON_REQUIRE_USER_FIELD } from "../UserManage/UserCreate";
// import DailyFormFile from '../../components/assets/DailyForm.csv';
import { DailyFormUpload } from '../../services/api/report/UploadForms';
//

export const ImportDailyForm = observer(() => {
  const [dragOverState, setDragOverState] = useState(false);
  const [fails, setFails] = useState<any[]>([]);
  const history = useHistory();
  const [uploadState, setUploadState] = useState(false);
  const [file, setFile] = useState<any>();
  const [failed, setFailed] = useState(false);
  const [progress, setProgress] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const {loading_form_upload, fire_form_upload }  = DailyFormUpload()

  const handleDragOver = (e: any) => {
    setDragOverState(true);
  };

  const handleDragLeave = (e: any) => {
    setDragOverState(false);
  };

  const handleDrop = (e: any) => {
    setDragOverState(false);
  };

  const handleDropZone = (acceptedFiles: any) => {
    if (acceptedFiles.length > 1) {
      alert("Please select only one file");
    } else if (
      acceptedFiles[0].type != "text/csv" &&
      acceptedFiles[0].type != "application/vnd.ms-excel" &&
      acceptedFiles[0].type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      alert("Please select .csv file type only");
    } else {
      //file is okay
      setUploadState(true);
      let progressInterval = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress + 1 >= 100) {
            clearInterval(progressInterval);
            return 100;
          }
          return oldProgress + 1;
        });
      }, 30);
      const onError = (msgs: any[]) => {
        setFails(msgs);
        setFailed(true);
        setProgress(100);
      };

      setFile(acceptedFiles);
    }
  };

  const handleSubmit = () => {
    fire_form_upload({
        file: file[0],
    })
    .then(() => {
        enqueueSnackbar(
        "Daily from file have been uploaded successfully!",
        { variant: "success" }
        );
        history.push("/report/daily");
    })
    .catch((ex) => {
        enqueueSnackbar("Upload Fail!:" + (ex ? " Please review all of information is correct or not" : "."), {
        variant: "error",
        });
    });
  };

  const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
      root: {
        height: 10,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: "#FFF",
      },
      bar: {
        borderRadius: 5,
        backgroundColor: "var(--primary)",
      },
    })
  )(LinearProgress);

  const uploadNewFile = () => {
    setUploadState(false);
    setFails([]);
    setProgress(0);
  };

  return (
    <>
      <Container maxWidth="lg" className="py-4">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SubBar reload={false} back={true} title={"Import Daily Form"} />
            <Paper className="mt-4">
              <Container className="py-12">
                <Grid className="mx-6 relative">
                  <Box className="text-xl" fontWeight={600}>
                    Upload Daily Form to import data
                  </Box>
                  {/* <Box className="text-xs mt-2 mb-8">
                    Please use templates example from this{" "}
                    <a
                      href=""
                      className="font-bold cursor-pointer"
                      style={{ color: "var(--primary)" }}
                      download
                    >
                      link
                    </a>
                  </Box> */}
                  {progress === 100 ? (
                    <Box mb={2}>
                      <Grid container justify="space-between">
                        {failed ? (
                          <Box
                            className="text-xs"
                            css={{ color: "var(--fresh-error)" }}
                            fontWeight={600}
                          >
                            Validation fail
                          </Box>
                        ) : (
                          <Box
                            className="text-base"
                            css={{ color: "var(--primary)" }}
                            fontWeight={700}
                          >
                            Submit 
                          </Box>
                        )}
                        <Box
                          className="cursor-pointer text-xs"
                          display="flex"
                          alignItems="center"
                          fontWeight={600}
                          onClick={uploadNewFile}
                        >
                          <Box mr={1}>Upload new file</Box>{" "}
                          <img
                            src={fa_cloud_upload}
                            alt="cloud-upload"
                            width={15}
                          />{" "}
                        </Box>
                      </Grid>
                    </Box>
                  ) : (
                    ""
                  )}
                  {progress === 100 && !failed ? (
                    <>
                      <Box>
                        <Divider />
                        <Box mt={4}>
                          <Grid container alignItems="center" justify="center">
                            <Button
                              css={{
                                backgroundColor: "#279825",
                                color: "#fff",
                                textTransform: "none",
                              }}
                              disabled={
                                loading_form_upload
                              }
                              size="large"
                              variant="contained"
                              onClick={handleSubmit}
                            >
                              <Box
                                className="w-56"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                {loading_form_upload && (
                                  <Box mr={2}>
                                    <CircularProgress
                                      size="1rem"
                                      color="inherit"
                                    />
                                  </Box>
                                )}{" "}
                                 {"Import " + file[0]?.name }
                              </Box>
                            </Button>
                          </Grid>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <Dropzone disabled={uploadState} onDrop={handleDropZone}>
                      {({ getRootProps, getInputProps }) => {
                        return (
                          <section>
                            <div {...getRootProps()}>
                              <input
                                {...getInputProps()}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                multiple={false}
                              />
                              <div
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}
                                className="select-none cursor-pointer"
                              >
                                <Grid
                                  container
                                  direction="column"
                                  className={`${
                                    progress === 100 && failed
                                      ? "px-8 py-6"
                                      : "h-56"
                                  } rounded-md`}
                                  justify={
                                    progress === 100 && failed
                                      ? "flex-start"
                                      : "center"
                                  }
                                  alignItems={
                                    progress === 100 && failed
                                      ? "flex-start"
                                      : "center"
                                  }
                                  style={{
                                    color: `${
                                      progress === 100 && failed
                                        ? "black"
                                        : dragOverState
                                        ? "var(--sub-primary)"
                                        : "var(--primary)"
                                    }`,
                                    backgroundColor: `${
                                      progress === 100 && failed
                                        ? "#FAEEED"
                                        : dragOverState
                                        ? "var(--primary)"
                                        : "var(--sub-primary)"
                                    }`,
                                  }}
                                >
                                  {uploadState ? (
                                    failed ? (
                                      <Box className="text-xs">
                                        {fails.map((ex) => {
                                          return <Box mb={1}>{ex}</Box>;
                                        })}
                                      </Box>
                                    ) : (
                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        className="w-full"
                                        px={20}
                                      >
                                        <Box
                                          className="text-xs font-bold"
                                          mb={2}
                                        >
                                          {progress >= 75
                                            ? "Validating"
                                            : `Uploading ${progress}%`}
                                        </Box>
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={progress}
                                          className="w-full"
                                        />
                                      </Box>
                                    )
                                  ) : (
                                    <>
                                      <Box mb={2}>
                                        <img
                                          draggable={false}
                                          src={
                                            dragOverState
                                              ? fa_upload_white
                                              : fa_upload
                                          }
                                          alt="upload"
                                        />
                                      </Box>
                                      <Box className="text-xs font-bold">
                                        {dragOverState
                                          ? "Release to upload"
                                          : "Upload your .csv file"}
                                      </Box>
                                    </>
                                  )}
                                </Grid>
                              </div>
                            </div>
                          </section>
                        );
                      }}
                    </Dropzone>
                  )}
                </Grid>
              </Container>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
});
export const ImportDailyFormMobile = ImportDailyForm;