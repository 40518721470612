import React, { Fragment, useEffect, useRef, useState } from "react";

import {
  Box,
  Container,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  makeStyles,
  withStyles,
  InputBase,
  Modal
} from "@material-ui/core";
import { CardMap, CardMapMemo } from "../Dashboard/CardMap";
import { CardUserList } from "../Dashboard/CardUserList";
import { CardUserListByStatus } from "../Dashboard/CardUserListByStatus";
import { SubBar } from "../Dashboard/Dashboard";
import LuxonUtils from "@date-io/luxon";
import { DateFilter } from "../../components/DateFilter";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { StickyContainer, Sticky } from "react-sticky";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { webStore } from "../../stores/webStore";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import MapIcon from "@material-ui/icons/Map";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Filter, Value, ReloadValue } from "../../types/user";
import { useListAllUser,useListAllUserWaiting,AddMacAddressService } from "../../services/api/user/useListAllUser";
import { usePlace } from "../../services/api/Options/usePlace";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { SwiperUserLists } from "../UserManage/SwiperUserLists";
import { resolveValue } from "react-hot-toast";
import { useListUser,useListUserQuarantine,UserListSI } from "../../services/api/user/useListUser";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";

const UserFilter = observer(({ display = true }: { display?: boolean }) => {
  const [startDate, setStartDate] = useState<Date | null>(webStore.startDate);
  const [endDate, setEndDate] = useState<Date | null>(webStore.endDate);
  const [filterHour, setFilterHour] = useState<number | null>(
    webStore.filter_hour
  );
  const [filterTemperature, setFilterTemperature] = useState<string | null>(
    webStore.filter_temp || ""
  );
  const [filterWearing, setFilterWearing] = useState<string | null>(
    webStore.filter_wear || ""
  );
  const [filterBattery, setFilterBattery] = useState<string | null>(
    webStore.filter_battery || ""
  );
  const [filterYachtName, setFilterYachtName] = useState<string | null>(
    webStore.filter_yacht_name || ""
  );
  // เพิ่ม
  const [filterKeyword, setFilterKeyword] = useState<string | null>(
    webStore.filter_Keyword || ""
  );
  // เพิ่ม
  const [filterGeoFence, setFilterGeoFence] = useState<string | null>(
    webStore.filter_geo_fence || ""
  );

  // const [filterHour, setFilterHour] = useState<number | null>(
  //   webStore.filter_hour
  // );

  const TempOptions = [
    { value: "high", label: "High >37.8" },
    { value: "warning", label: "Warning 37.5 - 37.8" },
    { value: "normal", label: "Normal <=37.5" },
  ];

  const WearingOptions = [
    { value: "24", label: ">= 20 hour" },
    { value: "19", label: "15 - 19 hour" },
    { value: "15", label: "10 - 14 hour" },
    { value: "9", label: "5 - 9 hour" },
    { value: "5", label: "< 5 hour" },
  ];

  const BatteryOptions = [
    { value: "4", label: "76 - 100%" },
    { value: "3", label: "51 - 75%" },
    { value: "2", label: "26 - 50%" },
    { value: "1", label: "16 - 25%" },
    { value: "-0", label: "1 - 15%" },
    { value: "-1", label: "Turn off (including out of battery and charging)" },
  ];

  const { result_places, loading_places, error_places } = usePlace();

  useEffect(() => {
    // console.log("changee");
    webStore.setStartDate(startDate);
    webStore.setEndDate(endDate);
    webStore.set_filter_temp(filterTemperature);
    webStore.set_filter_wear(filterWearing);
    webStore.set_filter_battery(filterBattery);
    webStore.set_filter_yacht_name(filterYachtName);
    webStore.set_filter_Keyword(filterKeyword);
    webStore.set_filter_geo_fence(filterGeoFence);
    // webStore.set_filter_hour(filterHour);
  }, [
    startDate,
    endDate,
    filterTemperature,
    filterWearing,
    filterBattery,
    filterYachtName,
    filterKeyword,
    filterGeoFence,
  ]);

  return (
    <Box mb={3}>
      {display && (
        <>
          <Box
            display="flex"
            py={2}
            css={{
              fontSize: 13,
              borderRadius: 5,
              backgroundColor: "#fff",
              "@media (max-width: 992px)": {
                flexFlow: "wrap",
                backgroundColor: "transparent",
              },
            }}
          >
            <Box
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              display="flex"
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                },
              }}
            >
              <MuiPickersUtilsProvider utils={LuxonUtils}>
                <KeyboardDatePicker
                  style={{
                    margin: 0,
                    width: "100%",
                    borderWidth: 0,
                    fontSize: 13,
                  }}
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="from"
                  label="Date Start"
                  autoOk={true}
                  value={startDate || new Date()}
                  onChange={(date: MaterialUiPickersDate) => {
                    setStartDate(date ? new Date(date.toString()) : null);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              display="flex"
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                  borderWidth: 0,
                },
              }}
            >
              <MuiPickersUtilsProvider utils={LuxonUtils}>
                <KeyboardDatePicker
                  style={{ margin: 0, width: "100%", fontSize: 13 }}
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="to"
                  label="Date End"
                  autoOk={true}
                  value={endDate || new Date()}
                  onChange={(date: MaterialUiPickersDate) => {
                    setEndDate(date ? new Date(date.toString()) : null);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box
              display="flex"
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                },
              }}
            >
              <FormControl css={{ width: "100%" }}>
                <InputLabel id="filterTem-label">Temperature</InputLabel>
                <Select
                  value={filterTemperature}
                  disableUnderline
                  css={{ fontFamily: "inherit", fontSize: "inherit" }}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setFilterTemperature(event.target.value as string);
                  }}
                  labelId="filterTem-label"
                >
                  {TempOptions.map((v) => {
                    return <MenuItem value={v.value}>{v.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box
              display="flex"
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                  borderWidth: 0,
                },
              }}
            >
              <FormControl css={{ width: "100%" }}>
                <InputLabel id="filterWearing-label">Device wearing</InputLabel>
                <Select
                  value={filterWearing}
                  disableUnderline
                  css={{ fontFamily: "inherit", fontSize: "inherit" }}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setFilterWearing(event.target.value as string);
                  }}
                  labelId="filterWearing-label"
                >
                  {WearingOptions.map((v) => {
                    return <MenuItem value={v.value}>{v.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box
              display="flex"
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                },
              }}
            >
              <FormControl css={{ width: "100%" }}>
                <InputLabel id="filterBattery-label">Battery</InputLabel>
                <Select
                  value={filterBattery}
                  disableUnderline
                  css={{ fontFamily: "inherit", fontSize: "inherit" }}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setFilterBattery(event.target.value as string);
                  }}
                  labelId="filterBattery-label"
                >
                  {BatteryOptions.map((v) => {
                    return <MenuItem value={v.value}>{v.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box
              display="flex"
              // borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                  borderWidth: 0,
                },
              }}
            >
              <FormControl css={{ width: "100%" }}>
                <InputLabel id="filterYachtName-label">Place Name</InputLabel>
                <Select
                  value={filterYachtName}
                  disableUnderline
                  css={{ fontFamily: "inherit", fontSize: "inherit" }}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setFilterYachtName(event.target.value as string);
                  }}
                  labelId="filterYachtName-label"
                >
                  {result_places.map((v) => {
                    return <MenuItem value={`${v.id}`}>{v.name_en}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            {/* <Box display="flex" px={2} justifyContent="center" width="100%">
                <FormControl css={{ width: "100%" }}>
                  <InputLabel id="filterGeoFence-label">GEO Fence</InputLabel>
                  <Select
                    value={filterGeoFence}
                    disableUnderline
                    css={{ fontFamily: "inherit", fontSize: "inherit" }}
                    onChange={(
                      event: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      setFilterGeoFence(event.target.value as string);
                    }}
                    labelId="filterGeoFence-label"
                  >
                    <MenuItem value="GEO_fence">GEO Fence</MenuItem>
                  </Select>
                </FormControl>
              </Box> */}
          </Box>
          <Box textAlign="right">
            <Button
              color="primary"
              css={{ fontSize: 13 }}
              onClick={() => {
                webStore.setStartDate(null);
                webStore.setEndDate(null);
                webStore.set_filter_hour(null);
                webStore.set_filter_temp(null);
                webStore.set_filter_wear(null);
                webStore.set_filter_battery(null);
                webStore.set_filter_yacht_name(null);
                webStore.set_filter_Keyword(null);
                webStore.set_filter_geo_fence(null);

                setStartDate(webStore.startDate);
                setEndDate(webStore.endDate);
                setFilterHour(webStore.filter_hour);
                setFilterTemperature(webStore.filter_temp);
                setFilterWearing(webStore.filter_wear);
                setFilterBattery(webStore.filter_battery);
                setFilterYachtName(webStore.filter_yacht_name);
                setFilterKeyword(webStore.filter_Keyword);
                setFilterGeoFence(webStore.filter_geo_fence);
              }}
            >
              Clear all filter
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
});

const UserSearch = observer(
  ({
    keywordsearch,
    setKeywordsearch,
    displayFilter,
    setDisplayFilter,
    inputWidthMobile,
  }: {
    inputWidthMobile?: number;
    keywordsearch: String;
    setKeywordsearch: (event: any) => void;
    displayFilter: boolean;
    setDisplayFilter: (e: boolean) => void;
  }) => {
    
    return (
      <Box css={{ backgroundColor: "#fff" }} p={1} borderRadius={2}>
        <InputBase
          placeholder="Customer name or IMEI or Passport"
          value={keywordsearch}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setKeywordsearch(event.target.value as string);
          }}
          css={{
            width: 300,
            padding: "8px 15px",
            "@media (max-width: 992px)": {
              fontSize: 12,
              width: inputWidthMobile ? inputWidthMobile : 235,
            },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            // console.log("search");
          }}
          css={{
            padding: 8,
            minWidth: "unset",
            boxShadow: "unset",
          }}
        >
          <SearchIcon />
        </Button>
        <Button
          variant="text"
          onClick={() => {
            setDisplayFilter(displayFilter ? false : true);
          }}
          css={{
            padding: 8,
            minWidth: "unset",
            boxShadow: "unset",
            borderLeft: "1px solid #ddd",
            borderRadius: 0,
            marginLeft: 10,
            "@media (min-width: 991px)": {
              display: "none",
            },
          }}
        >
          <FilterListIcon />
        </Button>
      </Box>
    );
  }
);

export const UserList = observer(() => {
  const history = useHistory();
  const [displayFilter, setDisplayFilter] = useState<boolean>(false);
  const [displayUserLocation, setDisplayUserLocation] = useState<boolean>(
    false
  );
  const [keywordsearch, setKeywordsearch] = useState(String);
  const mapRef = useRef<any>();
  const [page, setPage] = useState<number>(1);
  const [pageWaiting, setPageWaiting] = useState<number>(1);
  const [value, setValue] = React.useState(0);
  const [filterWaiting, setFilterWaiting] = useState<string>("waiting");
  // const [ reload_value, setReload ] = useState<boolean>(false);

  const reloadValue: ReloadValue = {
    reload: webStore.filter_reload
  }

  const filter: Filter = {
    startDate: webStore.startDate || new Date(),
    endDate: webStore.endDate || new Date(),
    filter_temp: webStore.filter_temp,
    filter_wear: webStore.filter_wear,
    filter_battery: webStore.filter_battery,
    filter_yacht_name: webStore.filter_yacht_name,
    filter_Keyword: keywordsearch,
    page: webStore.filter_userListPage,
    perPage: localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ? 50 : 10,
    waitingPage: webStore.filter_waitingListPage,
    waitingPerPage: 10
  };

  const valueTab: Value = {
    // value: value,
    value: webStore.list_valueTab,
    filter_waiting: filterWaiting
  };

  const AddIbeaconModal = observer(
    ({
      isAddIbeacon,
      onClick,
      setIsAddIbeacon,
      setChangeState,
      buttonName = "Add",
    }: {
      isAddIbeacon: boolean;
      setIsAddIbeacon: any;
      setChangeState?: any;
      onClick?: any;
      buttonName?: string;
    }) => {
      const { enqueueSnackbar } = useSnackbar();
      useEffect(() =>{  
      }, []);
  
      const [ macAddress, setMacAddress ] = useState('');
      const [ long , setLong ] = useState('');
      const [ lat, setLat ] = useState('');
      const [ accuracy, setAccuracy ] = useState('');
      const { fire_addMacAddress, result_addMacAddress, loading_addMacAddress } = AddMacAddressService();
      return (
        <Modal
          open={isAddIbeacon}
          onClose={() => {}}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            css={{ backgroundColor: "#fff", width: 600, borderRadius: 20, border: 'solid 2px #3f51b5' }}
            p={2}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
              mt={1}
            >
              <Box css={{ color: "#4B4C56" }}>
                <h3>Add Data</h3> 
              </Box>
              <Box
                onClick={() => {
                  setIsAddIbeacon(false);
                }}
              >
                <CloseIcon css={{ cursor: "pointer" }} />
              </Box>
            </Box>
            <Box>
              <br/>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                  <label style={{fontFamily: 'Poppins', fontWeight: 400}}>Mac Address</label>
                  <br/>
                    <TextField
                        id="margin-none"
                        style={{fontFamily: 'Poppins', fontSize: '16px'}}
                        type="text"
                        fullWidth
                        value={macAddress} 
                        name="macAddress"
                        onChange={(event) =>{
                           setMacAddress(event.target.value);
                        }
                      } 
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                  <label style={{fontFamily: 'Poppins', fontWeight: 400}}>Accuracy</label>
                  <br/>
                    <TextField
                        id="margin-none"
                        style={{fontFamily: 'Poppins', fontSize: '16px'}}
                        type="number"
                        fullWidth
                        value={accuracy} 
                        name="accuracy"
                        onChange={(event) =>{
                          setAccuracy(event.target.value);
                        }
                      } 
                    />
                </Grid>
              </Grid>
              <br/>
  
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                  <label style={{fontFamily: 'Poppins', fontWeight: 400}}>Latitude </label>
                  <br/>
                    <TextField
                        id="margin-none"
                        style={{fontFamily: 'Poppins', fontSize: '16px'}}
                        type="number"
                        fullWidth
                        value={lat} 
                        name="lat"
                        onChange={(event) =>{
                          setLat(event.target.value);
                        }
                      } 
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{paddingTop:'8px', paddingLeft:'5px', paddingRight : '10px'}}>
                  <label style={{fontFamily: 'Poppins', fontWeight: 400}}>Longitude</label>
                  <br/>
                    <TextField
                        id="margin-none"
                        style={{fontFamily: 'Poppins', fontSize: '16px'}}
                        type="text"
                        fullWidth
                        value={long} 
                        name="long"
                        onChange={(event) =>{
                          setLong(event.target.value);
                        }
                      } 
                    />
                </Grid>
              </Grid>
              <br/>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt ={2}
            >
              <Button
                onClick={async () => {
                  try {
                    fire_addMacAddress(macAddress, lat, long, accuracy)
                      .then(() => {
                        enqueueSnackbar("Success added data", {
                          variant: "success",
                          autoHideDuration: 3000,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                          },
                        });
                        setIsAddIbeacon(false);
                        setChangeState(false);
                      })
                      .catch((ex) => {
                        enqueueSnackbar(
                          "Error" +
                            (ex ? " : " + ex.toString() : ""),
                          {
                            variant: "error",
                            autoHideDuration: 3000,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                          }
                        );
                        setIsAddIbeacon(false);
                        setChangeState(false);

                      });
                  } catch (ex) {}}
                }
                color="primary"
                size="medium"
                variant="contained"
              >
                {buttonName}
              </Button>
            </Box>
            <br/>
          </Box>
        </Modal>
      );
    }
  );

  const [ isAddIbeacon,setIsAddIbeacon ] = useState(false);
  const [ changeState, setChangeState ] = useState(false);
  // console.log("filterS",filter.filter_battery);
  const { result_userList, loading_userList, total } = useListAllUser(filter, reloadValue, changeState);
  // const { result_userlist_raw ,result_userList, loading_userList, total } = UserListSI(filter);
  const { result_userList_waiting, loading_userList_waiting, total_waiting } = useListAllUserWaiting(filter, valueTab, reloadValue);
  
  return (
    <Container maxWidth="xl" className="py-4">
      <Grid container>
        <Grid item md={6} xs={12}>
          <Box
            className="flex justify-between items-center flex-wrap"
            pt={4}
            mb={3}
          >
            <Box
              display="flex"
              alignItems="center"
              css={{
                "@media (max-width: 992px)": {
                  width: "100%",
                  justifyContent: "space-between",
                },
              }}
              mb={{ xs: 3, md: 0 }}
            >
              {
                localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ?
                  <Box fontSize="h5.fontSize" fontWeight={500} mr={3}>
                    Guest List
                  </Box>
                  :
                  <Box fontSize="h5.fontSize" fontWeight={500} mr={3}>
                    User List
                  </Box>
              }
              
              <Box display="flex" alignItems="center">
                {
                  localStorage.getItem("projectType") ? 
                  <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    if(localStorage.getItem("projectType") === "PHUIS"){
                      history.push("/user/createUser");
                    }else if(localStorage.getItem("projectType") === "SI"){
                      history.push("/user/createUserSI")
                    }
                    else{
                      history.push("/user/create");
                    }
                  }}
                  css={{
                    "@media (max-width: 992px)": {
                      backgroundColor: "transparent",
                      boxShadow: "unset",
                      color: "#4684E2",
                    },
                  }}
                >
                  
                  {
                    localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ?
                     "Add Guest" 
                     :
                     "Add User"
                  }
                </Button>
                : <></>
                }
                
                <Box
                  display={{ xs: "block", md: "none" }}
                  borderLeft="1px solid rgba(0, 0, 0, 0.1)"
                >
                  <Button
                    variant="text"
                    css={{
                      padding: 8,
                      minWidth: "unset",
                    }}
                    onClick={() => {
                      setDisplayUserLocation(true);
                    }}
                  >
                    <MapIcon css={{ color: "#4684E2" }} />
                  </Button>
                </Box>
              </Box>
            </Box>
            {/* <UserSearch
              keywordsearch={keywordsearch}
              setKeywordsearch={setKeywordsearch}
              displayFilter={displayFilter}
              setDisplayFilter={setDisplayFilter}
            /> */}
            {
              localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ?
              <Button
                color="primary"
                css={{ fontWeight: "bold", width: 150 }}
                onClick={()=> {
                  setIsAddIbeacon(true);
                  setChangeState(true);
                }}
              >
                Add iBeacon
              </Button>
              :
              <></>
            }
             
          </Box>
        </Grid>
        <AddIbeaconModal
          isAddIbeacon ={isAddIbeacon}
          setIsAddIbeacon = {setIsAddIbeacon}
          setChangeState = {setChangeState}
        />
        
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>

          {/* localStorage.getItem("projectType") === "YACHTQ" ? */}
            {
              localStorage.getItem("projectKey") === "n55lzq3sbc27jy0tlgy4h9fu4tj8xb2tb83xzas3" ?
                <CardUserList
                  result_userList={result_userList}
                  loading_userList={loading_userList}
                  total={total}
                  filter={filter}
                  setPage={setPage}
                  mapRef={mapRef}
                  showVibrate={true}
                />
              :

              <CardUserListByStatus
                result_userList={result_userList}
                loading_userList={loading_userList}
                result_userList_waiting={result_userList_waiting}
                loading_userList_waiting={loading_userList_waiting}
                total={total}
                total_waiting={total_waiting}
                filter={filter}
                setPage={setPage}
                setValue={setValue}
                setPageWaiting={setPageWaiting}
                setFilterWaiting= {setFilterWaiting}
                mapRef={mapRef}
                valueTab = {valueTab}
              />
            }
            
            
          </Grid>
          <Grid
            item
            md={6}
            css={{
              width: "100%",
              flex: 1,
              minHeight: "100%",
              display: "flex",
              flexDirection: "column",
              "@media (max-width: 992px)": {
                display: "none",
              },
            }}
          >
            <StickyContainer
              style={{
                width: "100%",
                flex: 1,
                minHeight: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Sticky topOffset={-80}>
                {({ style, isSticky }: any) => (
                  <div style={style}>
                    <div
                      style={{
                        height: "auto",
                        position: "relative",
                        minHeight: "calc(100vh - 4rem)",
                        display: "flex",
                        flexDirection: "column",
                        top: !isSticky ? 0 : "4rem",
                      }}
                    >
                      {
                        value === 0 ? <CardMapMemo
                            mapRef={mapRef}
                            result_userList={result_userList}
                            loading_userList={loading_userList}
                            page={filter.page}
                            perPage={filter.perPage}
                          />
                        : <CardMapMemo
                            mapRef={mapRef}
                            result_userList={result_userList_waiting}
                            loading_userList={loading_userList_waiting}
                            page={filter.waitingPage}
                            perPage={filter.waitingPerPage}
                          />
                      }
                      
                    </div>
                  </div>
                )}
              </Sticky>
            </StickyContainer>
          </Grid>
        </Grid>
      </Grid>
      {displayUserLocation && (
        <Grid
          container
          className="fixed w-screen h-screen left-0 top-0"
          style={{ zIndex: 9991 }}
        >
          <Box className="absolute w-full z-10 ">
            <Box className="flex justify-center items-center bg-white">
              <Button
                variant="text"
                onClick={() => {
                  setDisplayUserLocation(false);
                }}
                css={{
                  padding: "8px 8px 8px 15px",
                  minWidth: "unset",
                  boxShadow: "unset",
                  borderRight: "1px solid #ddd",
                  borderRadius: 0,
                }}
              >
                <ArrowBackIosIcon style={{ width: 20 }} />
              </Button>
              <UserSearch
                inputWidthMobile={220}
                keywordsearch={keywordsearch}
                setKeywordsearch={setKeywordsearch}
                displayFilter={displayFilter}
                setDisplayFilter={setDisplayFilter}
              />
            </Box>
            <Box className="bg-white">
              <UserFilter display={displayFilter} />
            </Box>
          </Box>
          {
            value === 0 ? <CardMapMemo
                mapRef={mapRef}
                result_userList={result_userList}
                loading_userList={loading_userList}
                page={filter.page}
                perPage={filter.perPage}
                loadmore={true}
              />
            : <CardMapMemo
                mapRef={mapRef}
                result_userList={result_userList_waiting}
                loading_userList={loading_userList_waiting}
                page={filter.page}
                perPage={filter.perPage}
                loadmore={true}
              />
          }
          <Box className="absolute w-full z-10 bottom-0">
          {
            value === 0 ? <SwiperUserLists
                  result_userList={result_userList}
                  loading_userList={loading_userList}
                  total={total}
                  filter={filter}
                  setPage={setPage}
                  mapRef={mapRef}
                />
            : 
            <SwiperUserLists
              result_userList={result_userList_waiting}
              loading_userList={loading_userList_waiting}
              total={total_waiting}
              filter={filter}
              setPage={setPageWaiting}
              mapRef={mapRef}
            />
          }
            
          </Box>
        </Grid>
      )}
    </Container>
  );
});
export const UserListMobile = UserList;