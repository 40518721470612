import { TempHistoryReport } from "../../../types/personalReport";
import { User, UserCreateInput, UserCreateQuarantine, UserCreateHospitalInput, UserCreateSmartInfirmaryInput} from "../../../types/user";
import { dateStrAPI, dateStrAPIFormat,dateStr } from "../../../utils";
import { useCallAPI,useCallAPISpacetrax } from "../../useCallAPI";

export const useCreateUser = () => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    code: 0 | 1;
    message: string;
    // data: User;
    data : any;
  }>(
    {
      // url: `/api/v1/users/register/tourist/quarantines`,
      url:  `/v2/dashboard/user/create`,
      method: "POST",
    },
    {
      manual: true,
    }
  );
  return {
    result_user_create: res?.code === 1 ? res?.data : null,
    loading_user_create: loading,
    error_user_create: res?.code === 0 ? res?.message : null,
    fire_user_create: (userInput: UserCreateQuarantine) => {
      let placeId = "";
      let agencyId = "";
      if(userInput.place.length>0){
        for(let i = 0 ; i< userInput.place.length; i++){
          if(userInput.place[i].name_th === userInput.place_id){
            placeId = userInput.place[i].id;
          }
        }
      }
      if(placeId === ""){
        placeId = userInput.place_id;
      }
      if(userInput.agency.length>0){
        for(let i = 0 ; i< userInput.agency.length; i++){
          if(userInput.agency[i].name_th === userInput.agency_id){
            agencyId = userInput.agency[i].id;
          }
        }
      }
      if(agencyId === ""){
        agencyId = userInput.agency_id;
      }
      let inputData = {
        name : userInput.name,
        surname: userInput.surname,
        gender: userInput.gender,
        nationality: userInput.nationality,
        date_of_birth: dateStrAPIFormat(userInput.date_of_birth),
        identity_number : userInput.identity_number,
        imei: [userInput.device_id_1],
        branch_id: userInput.branch_id,
        place_id: placeId,
        agency_id: agencyId,
        start_date: dateStrAPIFormat(userInput.start_date),
        end_date: dateStrAPIFormat(userInput.end_date),
        hospital_id: userInput.hospital_id,
        departure_port: userInput.departure_port,
        destination_port: userInput.destination_port
      };
      if(userInput.device_id_2){
        inputData.imei.push(userInput.device_id_2);
      };
      
      return new Promise((resolve, reject) => {
        fire({
          data: inputData,
        })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
    },
  };
};

export const useCreateUserHospital = () => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    code: 0 | 1;
    message: string;
    data : any;
  }>(
    {
      url:  `/v2/dashboard/user/create`,
      method: "POST",
    },
    {
      manual: true,
    }
  );
  return {
    result_user_create_hospital: res?.code === 1 ? res?.data : null,
    loading_user_create_hospital: loading,
    error_user_create_hospital: res?.code === 0 ? res?.message : null,
    fire_user_create_hospital: (userInput: UserCreateHospitalInput) => {
      let placeId = "";
      if(userInput.place.length>0){
        for(let i = 0 ; i< userInput.place.length; i++){
          if(userInput.place[i].name_th === userInput.place_id){
            placeId = userInput.place[i].id;
          }
        }
      }
      if(placeId === ""){
        placeId = userInput.place_id;
      }
      let inputData = {};
      if(userInput.mac_address){
        inputData = {
          name : userInput.name,
          surname: userInput.surname,
          gender: userInput.gender,
          nationality: userInput.nationality,
          date_of_birth: dateStrAPIFormat(userInput.date_of_birth),
          identity_number : userInput.identity_number,
          weight: parseInt(userInput.weight),
          height: parseInt(userInput.height),
          phone_number: userInput.phone_number,
          disease: userInput.disease,
          imei: [userInput.device_id_1],
          mac_address: [userInput.mac_address],
          branch_id: userInput.branch_id,
          place_id: placeId,
          start_date: dateStrAPIFormat(userInput.start_date),
          end_date: dateStrAPIFormat(userInput.end_date),
          room_number: userInput.room_number,
          bed_number: userInput.bed_number
        };
      }else{
        inputData = {
          name : userInput.name,
          surname: userInput.surname,
          gender: userInput.gender,
          nationality: userInput.nationality,
          date_of_birth: dateStrAPIFormat(userInput.date_of_birth),
          identity_number : userInput.identity_number,
          weight: parseInt(userInput.weight),
          height: parseInt(userInput.height),
          phone_number: userInput.phone_number,
          disease: userInput.disease,
          imei: [userInput.device_id_1],
          branch_id: userInput.branch_id,
          place_id: placeId,
          start_date: dateStrAPIFormat(userInput.start_date),
          end_date: dateStrAPIFormat(userInput.end_date),
          room_number: userInput.room_number,
          bed_number: userInput.bed_number
        };
      }
      
      return new Promise((resolve, reject) => {
        fire({
          data: inputData,
        })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
    },
  };
};

export const UserCreateSIFunction = () => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    code: 0 | 1;
    message: string;
    data : any;
  }>(
    {
      url:  `/v2/dashboard/user/create`,
      method: "POST",
    },
    {
      manual: true,
    }
  );
  return {
    result_user_create_SI: res?.code === 1 ? res?.data : null,
    loading_user_create_SI: loading,
    error_user_create_SI: res?.code === 0 ? res?.message : null,
    fire_user_create_SI: (userInput: UserCreateSmartInfirmaryInput) => {
      let placeId = "";
      console.log("userInput:", userInput);
      let date;
      let startTime;
      let startDate;
      let medicalCondition :any = [];
      let currentMedicine: any = [];
      let medicineAllergies: any = [];

      if(userInput.otherDisease){
        medicalCondition = userInput.specifyOtherDisease;
      }
      if(userInput.HT){
        medicalCondition.push("HT");
      }
      if(userInput.DM){
        medicalCondition.push("DM");
      }

      if(userInput.HD){
        medicalCondition.push("HD");
      }

      if(userInput.Anemina){
        medicalCondition.push("Anemina");
      }

      if(userInput.HPL){
        medicalCondition.push("HPL");
      }

      if(userInput.CVA){
        medicalCondition.push("CVA");
      }

      if(userInput.CRF){
        medicalCondition.push("CRF");
      }

      if(userInput.Dementia){
        medicalCondition.push("Dementia");
      }

      if(userInput.CA){
        medicalCondition.push("CA");
      }

      if(userInput.serviceDate){
        date = dateStrAPIFormat(userInput.serviceDate);
      }

      if(userInput.allergic === '2'){
        medicineAllergies = userInput.specifyAllergic;
      }else {
        medicineAllergies = [];
      }

      if(userInput.currentDrug === '2'){
        currentMedicine = userInput.specifyCurrentDrug;
      }else{
        currentMedicine = [];
      }

      if(userInput.time){
        let splitTime = userInput.time.toString().split(" ");
        startTime=splitTime[4];
        startDate = date + "T" + startTime;
      }else{
        startDate = date + "T00:00";
      }
      
      
      let inputData = {};

      inputData=
        {
          "user": {
              "title_name_id": userInput.genderStatus,
              "name": userInput.name,
              "surname": userInput.surname,
              "gender": userInput.gender,
              "phone_number": userInput.phoneNumber,
              "date_of_birth": userInput.date_of_birth ? dateStrAPIFormat(userInput.date_of_birth) : "",
              "identity_number": userInput.identity_number
          },
          "onboard": {
              "start_time": startDate,
              "ward_id": userInput.ward,
              "branch_id": userInput.branch_id,
              "hospital_id": ""
          },
          "health": {
              "blood_oxygen": userInput.o2 ? parseFloat(userInput.o2) : 0,
              "blood_sugar": userInput.bloodSugar ? parseFloat(userInput.bloodSugar) : 0,
              "blood_diastolic": userInput.blood_diastolic ? parseFloat(userInput.blood_diastolic) : 0,
              "blood_systolic": userInput.blood_systolic ? parseFloat(userInput.blood_systolic) : 0,
              "heart_rate": userInput.heart_rate ? parseFloat(userInput.heart_rate) : 0,
              "body_temp": userInput.temp ? parseFloat(userInput.temp) : 0,
              "weight": userInput.weight ? parseFloat(userInput.weight) : 0,
              "height": userInput.height ? parseFloat(userInput.height) : 0
          },
          "madicine_allergies": medicineAllergies,
          "madicine_regulary": currentMedicine,
          "madical_condition": medicalCondition
      };

      console.log("input data:", inputData)
      
      return new Promise((resolve, reject) => {
        fire({
          data: inputData,
        })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
              medicalCondition=[];
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            medicalCondition=[];
            reject(ex.toString());
          });
      });
    },
  };
};

export const CheckImeiPair = (imei: any) => {
  
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: {
        bed_number?: string;
        created_at?: Date;
        date_end?: Date;
        date_start? : Date;
        departure_port?: string;
        destination_port?: string;
        id?: string;
        is_pair?: boolean;
        is_imeipair?: boolean;
        is_quarantine: number;
        quarantine_result_status?: number;
        room_number?: number;
        updated_at?: Date;
        user?: {
            avatar?: string,
            email?: string,
            gender?: string,
            id?: string,
            name?: string,
            nationality?: string,
            surname?: string,
            title_name_id?: string
        }
      // is_imeipair?: boolean;
      // is_pair?: boolean;
        
    };
  }>({
    url: `/v2/dashboard/user/valid-imei/${imei}`,
    method: "GET"
  });

  return {
    result_imeiPair: res?.data?.is_imeipair,
    result_imeiPair_user: res?.data?.user,
    loading_imeiPair: loading,
    error_imeiPair: error,
  };
};

export const CheckDevicePair = (imei: any) => {
  
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: {
      is_imeipair?: boolean;
      is_pair?: boolean;
    };
  }>({
    url: `/v2/dashboard/user/valid-imei/${imei}`,
    method: "GET"
  });

  return {
    result_devicePair: res?.data?.is_imeipair,
    loading_devicePair: loading,
    error_devicePair: error,
  };
};
