import React, { Fragment, useEffect } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  Box,
  Button,
  CardActionArea,
  Container,
  Grid,
  Paper,
  Typography,
  LinearProgress,
  Divider,
  Tab,
} from "@material-ui/core";
import { webStore } from "../../stores/webStore";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
// import { Table } from 'react-bootstrap';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableRow from '@material-ui/core/TableRow';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
// import Chart from "react-apexcharts"; 
import { ApexOptions } from "apexcharts";
import { Line } from "react-chartjs-2";
// import Chart from 'react-chartjs-2';
// import ChartDataLabels from "chartjs-plugin-datalabels";
import { useParams } from "react-router-dom";
import {
  GetVitalSignData
} from "../../services/api/user/GetVitalSignsData";

import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { calculateAge } from './UserInfoCardSI';

// Chart.register(ChartDataLabels);

import {  makeStyles, useTheme, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { RemoveShoppingCartOutlined } from "@material-ui/icons";

 const onCapture = () =>{
      htmlToImage.toPng(document.getElementById("savePNG") as HTMLElement)
          .then(function (dataUrl) {
          saveAs(dataUrl,  'vitalSign.png');
          });

      const input = document.getElementById('savePNG') as HTMLElement;
      html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p');
          pdf.addImage(imgData, 'PNG', 1, 3, 207, 295, undefined,'FAST');
          pdf.save("vitalSign.pdf");
        })
      ;
    }
  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%'
    },
    container: {
      maxHeight: 1000,
    },
    tableRightBorder: {
      borderWidth: 0,
      borderRightWidth: 1,
      borderTopWidth: 1,
      borderColor: 'grey',
      borderStyle: 'solid',
  },
  });

export const VitalSign = observer(() => {
  const { userId }: any = useParams();
  const { result_vitalSign,tempData,pulseData, dateArray ,weightHeightData,xAxisArray,reportData, loading_vitalSign,error_vitalSign } = GetVitalSignData(userId);
  const classes = useStyles();

    const chartJsLabel = xAxisArray;
    const TempData = tempData;
    const data = {
      labels:  chartJsLabel,
      datasets: [{
        label: 'Temperature',
        xAxisID:'xAxis1',
        yAxisID: 'temp',
        borderColor: '#008FFB',
        backgroundColor: 'white',
        data: TempData,
        // data: [36.4, 37, 36.5, 35.6, 37.4, 35.6, 35, 38,36.6, 36.4,36.4, 37, 36.5, 35.6, 37.4, 35.6, 35, 38,36.6, 36.4,36.4, 37, 36.5, 35.6, 37.4, 35.6, 35, 38,36.6, 35],
        tension: 0.2,
        fill: false
      },
      {
        label: '',
        xAxisID:'xAxis1',
        yAxisID: 'temperature',
        borderColor: '#fff',
        backgroundColor: 'white',
        data: [],
        tension: 0.2,
        fill: false
      },
      {
        label: 'Pulse',
        xAxisID:'xAxis1',
        yAxisID: 'pulse',
        borderColor: '#D22B2B',
        backgroundColor: 'white',
        tension: 0.2,
        data: pulseData,
        // data: [87, 87, 93, 67, 102, 78, 69, 90, 76,80, 102, 87, 93, 67, 90, 78, 69, 90, 87,80, 67 , 87, 93, 67, 78, 78, 69, 90, 90,80],
        fill: false
      }
     ]
    };
    let historyNoData = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40];
    let countMonth = 0;
    let countyear = 0;
    let countTemp = 0;
    let countTempF = 0;

    const getTemp = () =>{
      let temp = TempData[countTemp];
      countTemp++;
      return temp;
    }

    const getMonth = () =>{
      let month = chartJsLabel[countMonth]?.split(" ")[1];
      let year = chartJsLabel[countMonth]?.split(" ")[0];
      countMonth++;
      return month;
    }

    const getYear = () =>{
      let month = chartJsLabel[countyear-1]?.split(" ")[1];
      let year = chartJsLabel[countyear-1]?.split(" ")[0];
      countyear++;
      if(month === '8:00'){
        return year;
      }else {
        return "";
      }
    }

    const getTempF = () =>{

      let value = ['107.6', '105.8', '104', '102.2', '100.4', '98.6', '96.8', '95','93.2','91.4','89.6','87.8','86'];
      let returnvalue = value[countTempF];
      countTempF++;
      return returnvalue;
    }

    const legend = {
      display: true,
      position: "bottom",
      labels: {
        fontColor: "#323130",
        fontSize: 14
      }
    };

    const optionsChartJs = {
      title: {
        display: true,
        text: "Temperature and Pulse Chart"
      },
      scales: {
        xAxes:[
          {
            id:'xAxis1',
            type:"category",
            position: 'top',
            ticks:{
              maxRotation: 0,
              callback: function (){
               let value =  getMonth();
               return value;
                
              }
            }
          },
          {
            id:'xAxis2',
            type:"category",
            position: 'top',
            gridLines: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            ticks:{
              autoSkip:false,
              maxRotation: 0,
              callback:function(){
                  let value = getYear();
                  return value;
                
              }
            }
          }],
        yAxes: [
          {
            id: 'temp',
            type: 'linear',
            position: 'left',
            ticks: {
              min: 30,
              max: 42,
              stepSize: 1,
              fontColor: '#008FFB'
            }
          },
          {
            id: 'temperature',
            type: 'linear',
            position: 'left',
            gridLines: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            
            ticks: {
              min: 30,
              max: 42,
              stepSize: 1,
              fontColor: '#008FFB',
              callback: function() {
                return getTempF()
              }
            }
          },

          {
            id: 'pulse',
            type: 'linear',
            position: 'left',
            gridLines: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            ticks: {
              min: 40,
              max: 180,
              stepSize: 10,
              fontColor: '#D22B2B',
              // callback: function(value, index, values) {
              //   return value + ' bpm';
              // }
            }
          },
        ]
      },
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          align: "end",
          padding: {
            right: 2
          },
          labels: {
            padding: { top: 10 },
            title: {
              font: {
                weight: "bold"
              }
            },
            value: {
              color: "green"
            }
          },
          callback: function ({value} : {value: any}) {
            // let tempValue = getTemp();
            return "\n" + getTemp();
          }
        }
      }
      // options:{
      //   color: "#000"
      // }
    };

  return (
    <>
      <Box
      style={{
        width: "100%",
        display: "flex",
        flex: 1,
        minHeight: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
        backgroundColor: "#fff",
      }} id="savePNG"
    >
      <Box style={{ width: "100%" }} p={2}>
        <Box display={{ sm: "none", xs: "none", md: "block" }}>
          <Typography gutterBottom>
            <Box>
               <Grid container>
                  <Grid item xs={6} style={{fontWeight: 'bold', textAlign: 'center', fontSize: '20px'}}>
                    <br/>
                      VIBHARAM AMATANAKORN HOSPITAL <br/> GRAPHIC SHEET
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <p>Name: {result_vitalSign?.user?.name + " " + result_vitalSign?.user?.surname} </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p>Age: {calculateAge(result_vitalSign?.user?.date_of_birth)}</p>
                      </Grid>
                      <Grid item xs={6}>
                        <p>Room No: {result_vitalSign?.user?.onboard?.room_number ? result_vitalSign?.user?.onboard?.room_number : "N/A"}</p>
                      </Grid>
                      <Grid item xs={6}>
                        <p> {result_vitalSign?.user?.ward?.name_en ? "Ward : " + result_vitalSign?.user?.ward?.name_en 
                                  :result_vitalSign?.user?.onboard?.hotel ? "Hotel : " + result_vitalSign?.user?.onboard?.hotel : "N/A"   }</p>
                      </Grid>
                      <Grid item xs={12}>
                        <p>Doctor : </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p>HN : </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p>AN : </p>
                      </Grid>
                    </Grid>
                      
                      
                  </Grid>
               </Grid>
            </Box>
            <Box
              fontWeight="bold"
              textAlign="center"
              lineHeight={"56px"}
              fontSize={48}
            >

            </Box>
            <Box
              fontWeight="bold"
              textAlign="center"
              mb={3}
              css={{ color: "#14AF5C" }}
            >
            </Box>
          </Typography>
        </Box>
        <Box display={{ xs: "block", md: "none" }}>
          <Typography style={{ fontWeight: "bold" }}>Graphic Sheet</Typography>
        </Box>


        <Grid item md={12} xs={12} style={{marginRight: '-2%'}}>
          {/* <Chart
              options={options}
              series={series}
              type="line"
              height={400}
            /> */}
          

        </Grid>
          
      </Box>

      <Box style={{ width: "100%" }}>
        <Line data={data} legend={legend} options={optionsChartJs} height={110}/>
      </Box>

      <Box style={{ width: "100%" }} p={2}>
      <Table  style={{border: '1px solid grey', boderBottom: '1px solid grey'}}>
          <Tbody>
            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }} >Date</Td>
    
              {dateArray?.map((date)=>{
                return (
                  <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>{date}</Td>
              );})}
            </Tr>
            <Tr>
              <Td rowSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}> Day After </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}> Admission </Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}> Operation </Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td  colSpan={4} className={classes.tableRightBorder}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px', fontWeight: 'bold'  }}>Time</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
            </Tr>
            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>Respiration</Td>
              {
                reportData?
                <>
                    {
                      reportData?.map((data)=>{
                        return (
                          <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                            {data.breath ? data.breath : "-"}
                          </Td>
                        );
                        
                      })
                    }
                </>
                :
                <>
                  {
                    historyNoData?.map((data)=>{
                      return (
                        <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
                      );
                      
                    })
                  }
                </>
              }
              
            </Tr>
            <Tr>
              <Td rowSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>B.P.</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>Systolic</Td>
              {
                reportData?.map((data)=>{
                  return (
                    <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                      {
                        data.blood_systolic ?
                        <>
                          {data.blood_systolic < 86  ? 
                          <p style={{color:'red'}}>
                            {data.blood_systolic}
                          </p>:
                          data.blood_systolic > 164  ? 
                          <p style={{color:'red'}}>
                            {data.blood_systolic}
                          </p>
                          : data.blood_systolic}
                        </>
                        :
                        "-"
                      }
                    </Td>
                  );
                  
                })
              }
            </Tr>
            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>Diastolic</Td>
              {
                reportData?.map((data)=>{
                  return (
                    <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                      {
                        data.blood_diastolic ?
                        <>
                          {data.blood_diastolic < 41  ? 
                          <p style={{color:'red'}}>
                            {data.blood_diastolic}
                          </p>:
                          data.blood_diastolic > 129  ? 
                          <p style={{color:'red'}}>
                            {data.blood_diastolic}
                          </p>
                          : data.blood_diastolic}
                        </>
                        :
                        "-"
                      }
                    </Td>
                  );
                  
                })
              }
            </Tr>
            <Tr>
              <Td rowSpan={3} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>O<sub>2</sub> sat</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                Room Air
                <br/>
                <p style={{fontSize: '10px'}}>(before exercise)</p>
              </Td>
              {
                reportData?.map((data)=>{
                  return (
                    <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                      {
                        data.oxygen_before ?
                        <>
                          {data.oxygen_before <=94 ? 
                          <p style={{color:'red'}}>
                            {data.oxygen_before}
                          </p>
                          : data.oxygen_before}
                        </>
                        :
                        "-"
                      }
                      
                    </Td>
                  );
                  
                })
              }
            </Tr>
            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                Room Air
                <br/>
                <p style={{fontSize: '10px'}}>(after exercise)</p>
              </Td>
              {
                reportData?.map((data)=>{
                  return (
                    <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                      {
                        data.oxygen_after ?
                        <>
                          {data.oxygen_after <=94 ? 
                          <p style={{color:'red'}}>
                            {data.oxygen_after}
                          </p>
                          : data.oxygen_after}
                        </>
                        :
                        "-"
                      }
                      
                    </Td>
                  );
                  
                })
              }
            </Tr>
            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>On O<sub>2</sub></Td>
              {
                reportData?.map((data)=>{
                  return (
                    <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                      -
                    </Td>
                  );
                  
                })
              }
            </Tr>
            <Tr>
              <Td rowSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                {/* <SentimentVerySatisfiedIcon style={{fontSize: '50px'}}/> */}
                <SentimentVeryDissatisfiedIcon style={{fontSize: '50px'}}/>
                {/* <SentimentSatisfiedIcon style={{fontSize: '50px'}}/> */}
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>10</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>9</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>8</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>7</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td rowSpan={3} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                <SentimentSatisfiedIcon style={{fontSize: '50px'}}/>
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>6</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>5</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>4</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td rowSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                <SentimentVerySatisfiedIcon style={{fontSize: '50px'}}/>
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>3</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>2</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>1</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>0</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}> Weight </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}> Height </Td>
              {
                weightHeightData?.map((data)=>{
                  return (
                    <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                      {data ? data : "-"}
                    </Td>
                  );
                  
                })
              }
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}> Diet </Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px', fontWeight: 'bold'  }}>INTAKE</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>Oral/NG</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>Parental</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>Blood Products</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>Imgation</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px', fontWeight: 'bold'  }}>OUTPUT</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>06-14</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>14-22</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>22-06</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '10px',paddingBottom: '8px', fontWeight: 'bold' }}>Total</Td>
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px'}}>
                Urine
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px'}}>
                Emesis
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px'}}>
                Drainage
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px'}}>
                
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px'}}>
                Blood Lose
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px'}}>
                Total
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px'}}>
                Urine
              </Td>
              {
                reportData?.map((data)=>{
                  return (
                    <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                      {data.urination_among ? data.urination_among : "-"}
                    </Td>
                  );
                  
                })
              }
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px'}}>
                Stool
              </Td>
              {
                reportData?.map((data)=>{
                  return (
                    <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                      {data.defecation_among ? data.defecation_among : "-"}
                    </Td>
                  );
                  
                })
              }
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px'}}>
                Foley's Day
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td rowSpan={5} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px'}}>
                Braden Scale
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>6-9 Very High Risk</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                10-12 High Risk
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                13-14 Moderate Risk
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                15-18 At Risk
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                19-23 Very High Risk
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td rowSpan={3} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px'}}>
                Fall Risk Scale Adult
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>0-24 Very High Risk</Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px'}}>
                25-50
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px'}}>
                &gt;=51
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td rowSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px'}}>
                Fall Risk Scale PED
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                7-10 Low Risk
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}>
                12-23 Low Risk
              </Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>

            <Tr>
              <Td colSpan={2} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }} >Note</Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
              <Td colSpan={4} className={classes.tableRightBorder} style={{ textAlign:'center', fontSize: '12px', paddingBottom: '8px' }}></Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      {/* <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="dense table" size="small" style={{borderLeft: '1px solid grey'}}>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} style={{ maxWidth: '1%', textAlign:'center' }} className={classes.tableRightBorder}>Date</TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ maxWidth: '100px', textAlign:'center' }} className={classes.tableRightBorder} rowSpan={2}> Day After</TableCell>
                <TableCell style={{ maxWidth: '100px', textAlign:'center' }} className={classes.tableRightBorder}> Admission</TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ maxWidth: '100px', textAlign:'center' }} className={classes.tableRightBorder}>Operation</TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
                <TableCell colSpan={4} className={classes.tableRightBorder}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} style={{ maxWidth: '100px', textAlign:'center' }} className={classes.tableRightBorder}>Respiration</TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
                <TableCell style={{ maxWidth: '50px' }} className={classes.tableRightBorder}></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper> */}
      
      </Box>

      <Box
        style={{
          width: "100%",
          display: "flex",
          flex: 1,
          minHeight: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 5,
          backgroundColor: "#fff",
          marginTop: '20px'
        }}
      >
        <Button  variant="contained" color="primary" onClick={onCapture}> Download PDF </Button>
      </Box>
    </>
    
  );
});
