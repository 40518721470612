import { observer } from "mobx-react-lite";
import { webStore } from "../../../stores/webStore";
import {
  Filter,
  User,
  UserFromAPI,
  UsersQuarantine,
  UsersQuarantineList,
  SmartInfirmaryUserList,
  Ward,
  TitleName,
  Medicine,
  Disease,  Value, ReloadValue
} from "../../../types/user";
import _, { parseInt } from "lodash";
import { userDevice } from "../../../types/device";
import { useCallAPI, useCallAPISpacetrax } from "../../useCallAPI";
import { processServerUser } from "./useReadUser";
// import { MOCKUP_USER } from "./useReadUser";

// export const useListUser = (filter?: Filter) => {
//   console.log("filter", filter);
//   // const MOCKUP_USERS: User[] = Array.from(Array(10).keys()).map((v, index) => {
//   //   return {
//   //     ...MOCKUP_USER,
//   //     id: index.toString(),
//   //     latest_lat: 10 + index / 10,
//   //     latest_lng: 10 + index / 10,
//   //   };
//   // });

//   // return {
//   //   result_userList: MOCKUP_USERS,
//   //   loading_userList: false,
//   //   error_userList: false,
//   // };
//   const [{ data: res, loading, error }, fire] = useCallAPI<{
//     data?: {
//       total?: number;
//       user_quarantines?: UsersQuarantine[];
//     };
//   }>({
//     url: `/api/v1/dashboard/group-quarantines`,
//     method: "GET",
//     // data: {
//     //   filter: filter,
//     // },
//     params: {
//       page: filter?.page || 1,
//       perPage: filter?.perPage || 10,
//     },
//   });

//   return {
//     result_userList: res?.data?.user_quarantines || [],
//     total: res?.data?.total || 0,
//     loading_userList: loading,
//     error_userList: error,
//   };
// };

export const useListUser = (filter?: Filter) => {
  // console.log("filter", filter);
  // const MOCKUP_USERS: User[] = Array.from(Array(10).keys()).map((v, index) => {
  //   return {
  //     ...MOCKUP_USER,
  //     id: index.toString(),
  //     latest_lat: 10 + index / 10,
  //     latest_lng: 10 + index / 10,
  //   };
  // });

  // return {
  //   result_userList: MOCKUP_USERS,
  //   loading_userList: false,
  //   error_userList: false,
  // };
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: {
      total?: number;
      user_quarantine?: UsersQuarantine[];
    };
  }>({
    url: `/v2/dashboard/user/list`,
    method: "GET",
    // data: {
    //   filter: filter,
    // },
    params: {
      page: filter?.page || 1,
      limit: filter?.perPage || 10,
    },
  });

  return {
    result_userList: res?.data?.user_quarantine || [],
    total: res?.data?.total || 0,
    loading_userList: loading,
    error_userList: error,
  };
};

export const useListUserQuarantine = (filter?: Filter) => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: {
      total?: number;
      user_quarantine?: UsersQuarantine[];
    };
  }>({
    url: `/v2/dashboard/user/list-status/in_quarantine`,
    method: "GET",
    // data: {
    //   filter: filter,
    // },
    params: {
      page: filter?.page || 1,
      limit: filter?.perPage || 10,
    },
  });
  return {
    result_userList: res?.data?.user_quarantine || [],
    total: res?.data?.total || 0,
    loading_userList: loading,
    error_userList: error,
  };
};

export const UserList = (filter?: Filter) => {
  console.log("filter", filter);

  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: {
      total?: number;
      user_quarantines?: UsersQuarantineList[];
    };
  }>({
    url: `/v2/dashboard/user/list`,
    method: "GET",
    // data: {
    //   filter: filter,
    // },
    params: {
      page: filter?.page || 1,
      perPage: filter?.perPage || 10,
    },
  });

  return {
    result_userList: res?.data?.user_quarantines || [],
    total: res?.data?.total || 0,
    loading_userList: loading,
    error_userList: error,
  };
};

export const UserListSI = (filter?: Filter, reloadValue?: ReloadValue) => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: {
      total?: number;
      users?: SmartInfirmaryUserList[];
    };
  }>({
    url: `/v2/smart-infirmary/users/active`,
    method: "GET",
    params: {
      page: filter?.page || 1,
      limit: filter?.perPage || 10,
      keyword: filter?.filter_Keyword || "",
      reload: reloadValue?.reload
    },
  });
  let total : number = 0;
  if(filter?.page === 1) {
    total = res?.data?.total || 0 ;
    webStore.setTotalUser(total);
  }
  return {
    result_userlist_raw: res?.data || {total: 0 , users: [], page: 0, perpage: 0},
    result_userList: res?.data?.users || [],
    total: webStore.totalUser || 0,
    loading_userList: loading,
    error_userList: error,
  };
};

export const UserListSIDischarge = (filter?: Filter, valueTab?: Value, reloadValue?: ReloadValue) => {
  
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: {
      total?: number;
      users?: SmartInfirmaryUserList[];
    };
  }>({
    url: `/v2/smart-infirmary/users/${valueTab?.filter_waiting}`,
    method: "GET",
    params: {
      page: filter?.waitingPage || 1,
      limit: filter?.waitingPerPage || 10,
      keyword: filter?.filter_Keyword || "",
      reload: reloadValue?.reload
    },
  });
  let total : number = 0;
  if(filter?.waitingPage === 1) {
    total = res?.data?.total || 0 ;
    webStore.setTotalUserDischarge(total);
  }
  return {
    result_userlist_discharge_raw: res?.data || {total: 0 , users: [], page: 0, perpage: 0},
    result_userList_discharge: res?.data?.users || [],
    total_discharge: webStore.totalUserDischarge || 0 ,
    loading_userList_discharge: loading,
    error_userList_discharge: error,
  };
};


// https://api.space-trax.com/v2/dashboard/ward
export const GetWardList = () => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: Ward[];
  }>({
    url: `/v2/dashboard/ward`,
    method: "GET"
  });
  const result_wardlist = res?.data;
  let wardList;
  if (result_wardlist) {
    wardList = _.sortBy(
      (result_wardlist || []).map(
        ({ id, name_th }) => {
          return {
            value: id,
            name: name_th
          };
        }
      ),
      "id","name_th"
    );
  }

  return {
    result_wardlist: res?.data || [],
    wardList: wardList || [], 
    loading_wardList: loading,
    error_wardList: error,
  };
};

// https://api.space-trax.com/v2/dashboard/title-name
export const GetTitleName = () => {
  const [{data: res, loading, error}, fire ] = useCallAPISpacetrax<{
    data?: TitleName[];
  }>({
    url: `/v2/dashboard/title-name`,
    method: "GET"
  });
  const result_titleName = res?.data;
  let titleName;
  if(result_titleName) {
    titleName = _.sortBy(
      (result_titleName || []).map(
        ({ id, name_th }) => {
          return {
            value: id,
            name: name_th
          };
        }
      ),
      "id","name_th"
    )
  }
  return{
    result_titleName: res?.data ||  [],
    titleName: titleName || [],
    loading_titleName: loading,
    error_titleName: error,
  }
}

// https://api.space-trax.com/v2/dashboard/disease
export const GetMedicineList = () => {
  const [{data: res, loading, error}, fire ] = useCallAPISpacetrax<{
    data?: Medicine[];
  }>({
    url: `/v2/dashboard/medicines`,
    method: "GET"
  });
  const result_medicine = res?.data;
  let medicine;
  if(result_medicine) {
    medicine = _.sortBy(
      (result_medicine || []).map(
        ({ id, name_en }) => {
          return {
            value: id,
            name: name_en
          };
        }
      ),
      "id","name_en"
    )
  }
  return{
    result_medicine: res?.data ||  [],
    medicineList: medicine || [],
    loading_medicine: loading,
    error_medicine: error,
  }
}

export const GetDiseaseList = () => {
  const [{data: res, loading, error}, fire ] = useCallAPISpacetrax<{
    data?: Disease[];
  }>({
    url: `/v2/dashboard/disease`,
    method: "GET"
  });
  const result_disease = res?.data;
  let disease;
  if(result_disease) {
    disease = _.sortBy(
      (result_disease || []).map(
        ({ id, name_en }) => {
          return {
            value: id,
            name: name_en
          };
        }
      ),
      "id","name_en"
    )
  }
  return{
    result_disease: res?.data ||  [],
    diseaseList: disease || [],
    loading_disease: loading,
    error_disease: error,
  }
}