import {
    ListItem,
    ListItemText,
    List,
    Divider,
    Button,
    Avatar,
    IconButton,
    TextField,
    CircularProgress,
    Grid,
    Box,
    Modal,
    OutlinedInput,
    InputAdornment,
  } from "@material-ui/core";
  import React, { Fragment, useEffect, useState } from "react";
  import { User, UserProfile } from "../../types/user";
  import EditIcon from "@material-ui/icons/Edit";
  import CheckIcon from "@material-ui/icons/Check";
  import CancelIcon from "@material-ui/icons/Cancel";
  import { observer } from "mobx-react-lite";
  
  import { dateStr, dateStrAPI ,DateString } from "../../utils";
  import { useSnackbar } from "notistack";
  import { useUpdateUser } from "../../services/api/user/useUpdateUser";
  import FileCopyIcon from "@material-ui/icons/FileCopy";
  import BorderColorIcon from "@material-ui/icons/BorderColor";
  import CloseIcon from "@material-ui/icons/Close";
  import { InputDate } from "./UserCreate";
  import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
  import { keyframes } from "@emotion/react";
  import { useClipboard } from "use-clipboard-copy";
  
  const Field = observer(
    ({
      label,
      value,
      copy,
      edit,
      ...rest
    }: {
      label?: string;
      value: any;
      copy?: boolean;
      edit?: any;
    }) => {
      const { enqueueSnackbar } = useSnackbar();
      const clipboard = useClipboard();
      return (
        <Box my={1}>
          <Box fontSize={14} css={{ color: "rgba(255, 255, 255, 0.5)" }}>
            {label}
          </Box>
  
          <Box fontSize={18} display="flex" alignItems="center" style={{whiteSpace: 'pre-wrap'}}>
            {copy ? (
              <Box
                css={{ cursor: "pointer"}}
                onClick={() => {
                  clipboard.copy(value);
                  enqueueSnackbar(`copy ${value}`, {
                    variant: "success",
                    autoHideDuration: 3000,
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  });
                }}
              >
                {value}
                <FileCopyIcon
                  css={{ fontSize: 14, marginLeft: 5, cursor: "pointer" }}
                />
              </Box>
            ) : (
              value
            )}
            {edit && (
              <BorderColorIcon
                css={{ fontSize: 14, marginLeft: 5, cursor: "pointer" }}
                onClick={edit}
              />
            )}
          </Box>
        </Box>
      );
    }
  );
  
  export const EditField = observer(
    ({
      isEdit,
      label,
      value,
      field,
      onClick,
      setIsEdit,
      type = "text",
      setValue,
      buttonName = "SAVE",
    }: {
      isEdit: boolean;
      setIsEdit: any;
      label: string;
      field: string;
      value: any;
      onClick?: any;
      type?: string;
      setValue: any;
      buttonName?: string;
    }) => {
      return (
        <Modal
          open={isEdit}
          onClose={() => {}}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            css={{ backgroundColor: "#fff", width: 300, borderRadius: 5 }}
            p={2}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Box css={{ color: "#4B4C56" }} fontSize={14}>
                {label}
              </Box>
              <Box
                onClick={() => {
                  setIsEdit(false);
                }}
              >
                <CloseIcon css={{ cursor: "pointer" }} />
              </Box>
            </Box>
            <Box>
              {type === "text" && (
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={value}
                  defaultValue={value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue({
                      label: label,
                      type: type,
                      field: field,
                      value: event.target.value,
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={onClick}
                        color="primary"
                        size="small"
                        variant="text"
                      >
                        {buttonName}
                      </Button>
                    ),
                  }}
                />
              )}
              {type === "date" && (
                <Box display="flex">
                  <InputDate
                    input={{ label: "", name: "start_date" }}
                    value={value}
                    onChange={(date: MaterialUiPickersDate) => {
                      setValue({
                        label: label,
                        type: type,
                        field: field,
                        value: date ? new Date(date.toString()) : null,
                      });
                    }}
                    error={false}
                  />
                  <Button
                    onClick={onClick}
                    color="primary"
                    size="small"
                    variant="text"
                  >
                    {buttonName}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Modal>
      );
    }
  );

 export const calculateAge = (date : any) => {
   if(date){
    let birthDate = new Date(date?.toString());
    let difference = Date.now() - birthDate.getTime();
    
    let ageDate = new Date(difference);
    let calculatedAge = Math.abs(ageDate.getUTCFullYear() -1970);
    return calculatedAge;
   }
  }
  
  export const UserInfoCardSI = ({
    user,
    userRaw,
    reload,
  }: {
    user: any;
    userRaw?: any;
    reload: any;
  }) => {
    console.log("user", userRaw);
    const [isEdit, setIsEdit] = useState(false);
    const [editValue, setEditValue] = useState<any>();
    const [loading, setLoading] = useState(false);
    const { fire_userUpdate } = useUpdateUser();
    // const [dateStart, setDateStart] = useState(user.date_start || "?");
    // const [dateEnd, setDateEnd] = useState(user.date_end || "?");
    // const [imei, setImei] = useState(user.imei || "?");
    const { enqueueSnackbar } = useSnackbar();
    console.log("user in user Info card:",user);
    console.log("user raw in user info card:", userRaw);
  
    const checkDate = (dateData : any) => {
      let dateFormat;
      // if(dateStrAPI(dateData) === '1-01-01'){
      //   dateFormat = "-"
      // }else {
      //   dateFormat = dateStrAPI(dateData)
      // }
      if(DateString(dateData) === '1-01-01'){
        dateFormat = "-"
      }else {
        dateFormat = DateString(dateData)
      }
      return dateFormat;
    }
  
    return (
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          {/* <Field label="Place Name" value={user.yatch_name?.toString()} /> */}
          {
            user?.onboard?.hospital?.name_en ? 
            <Field label="Hospital" value={user?.onboard?.hospital?.name_en.toString()} />
            :
            <Field label="Hospital" value="-" />
          }
          
        </Grid>

        <Grid item md={3} xs={12}>
          {/* <Field label="Place Name" value={user.yatch_name?.toString()} /> */}
          {
            user?.onboard?.ward?.name_en ? 
            <Field label="Ward" value={user?.onboard?.ward?.name_en.toString()} />
            :
            <Field label="Ward" value="-" />
          }
          
        </Grid>
        <Grid item md={3} xs={12}>
          {
            user.date_of_birth ? 
            <Field label="Date of birth" value={dateStrAPI(user.date_of_birth)} />
            :
            <Field label="Date of birth" value="-" />
          }
          
        </Grid>

        <Grid item md={3} xs={12}>
            {
                user.date_of_birth ? 
                <Field label="Age" value={calculateAge(user.date_of_birth)} />
                :
                <Field label="Age" value="-" />
            }
        </Grid>

        {
          localStorage.getItem("projectType") !== "YACHTQ" ? 
          <Grid item md={3} xs={12}>
            {
              user?.phone_number ? 
              <Field
              label="Tel."
              value={user?.phone_number}
              />
              :
              <Field label="Tel." value="-" />
            }
            
          </Grid> :<></>
        }

        {
           user?.onboard?.bed_number ?
           <>
            <Grid item md={3} xs={12}>
              <Field
                label="Bed Number"
                value={user?.onboard?.bed_number}
                />
            </Grid>
           </>
           :<></>
        }

        {
           user?.onboard?.room_number ?
           <>
            <Grid item md={3} xs={12}>
              <Field
                label="Room Number"
                value={user?.onboard?.room_number}
                />
            </Grid>
           </>
           :<></>
        }
        
        {
          localStorage.getItem("projectType") !== "YACHTQ" ? 
          <Grid item md={3} xs={12}>
            {
              user?.health?.weight ? 
              <Field
              label="Weight"
              value={user?.health?.weight}
              />
              :
              <Field label="Weight" value="-" />
            }
            
          </Grid> : <></>
        }
        
  
          {
            localStorage.getItem("projectType") !== "YACHTQ" ? 
            <Grid item md={3} xs={12}>
              {
                user?.health?.height ? 
                <Field
                label="Height"
                value={user?.health?.height}
                />
                :
                <Field label="Height" value="-" />
              }
              
            </Grid> : <></>
          }
        
        
  
        {
          localStorage.getItem("projectType") !== "YACHTQ" ? 
          <Grid item md={3} xs={12}>
            {
              user?.health?.bmi ? 
              <Field
              label="BMI"
              value={user?.health?.bmi.toFixed(2)}
              />
              :
              <Field label="BMI" value="-" />
            }
            
          </Grid> : <></>
        }   

        {/* {
           user?.health?.body_temp ?
           <>
            <Grid item md={3} xs={12}>
              <Field
                label="Temperature"
                value={user?.health?.body_temp}
                />
            </Grid>
           </>
           :<></>
        }  

        {
           user?.health?.blood_systolic ?
           <>
            <Grid item md={3} xs={12}>
              <Field
                label="Blood Pressure"
                value={user?.health?.blood_systolic + "/" + user?.health?.blood_diastolic}
                />
            </Grid>
           </>
           :<></>
        }   

        {
           user?.health?.heart_rate ?
           <>
            <Grid item md={3} xs={12}>
              <Field
                label="Heart Rate"
                value={user?.health?.heart_rate}
                />
            </Grid>
           </>
           :<></>
        }  

        {
           user?.health?.blood_sugar ?
           <>
            <Grid item md={3} xs={12}>
              <Field
                label="Blood Sugar"
                value={user?.health?.blood_sugar}
                />
            </Grid>
           </>
           :<></>
        }     
        

        {
           user?.health?.blood_oxygen ?
           <>
            <Grid item md={3} xs={12}>
              <Field
                label="Blood Oxygen"
                value={user?.health?.blood_oxygen}
                />
            </Grid>
           </>
           :<></>
        }    */}

        {
           user?.onboard?.patient_info?.medicine_regularly ?
           <>
            <Grid item md={4} xs={12}>
              <Field
                label="Current Taking Medicine"
                value={user?.onboard?.patient_info?.medicine_regularly}
                />
            </Grid>
           </>
           :<></>
        }    

        {
           user?.onboard?.patient_info?.medical_condition ?
           <>
            <Grid item md={4} xs={12}>
              <Field
                label="Medical Condition"
                value={user?.onboard?.patient_info?.medical_condition}
                />
            </Grid>
           </>
           :<></>
        }   


          {
           user?.onboard?.patient_info?.medicine_allergies ?
           <>
            <Grid item md={4} xs={12}>
              <Field
                label="Medicine Allergy"
                value={user?.onboard?.patient_info?.medicine_allergies}
                />
            </Grid>
           </>
           :<></>
        }   



  
        <EditField
          {...editValue}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setValue={setEditValue}
          onClick={async () => {
            setLoading(true);
            await reload();
            setIsEdit(false);
            setLoading(false);
            enqueueSnackbar(`${editValue.label} success`, {
              key: "updateSuccess",
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          }}
        />
      </Grid>
    );
  };
  
  export const UserProfileDetail = ({ user, userRaw, reload }: { user: any; userRaw?:any, reload: any }) => {
    console.log("userProfile", user);
    return (
      <Box display="flex" alignItems="center">
        <Box mr={3}>
          <Avatar
            src={user.avatar}
            alt={user.name}
            css={{ width: "120px !important", height: "120px !important" }}
          />
        </Box>
        <Box>
          <Box fontSize="h6.fontSize" fontWeight={600}>
            {`${
                user.name ? user.name.toString() : "-"
              } ${
                user.surname ? user.surname.toString() : "-"
              }`}
          </Box>
          <Box fontSize={14}>{`${
            user.gender == "m"
              ? "Male"
              : user.gender == "M"
              ? "Male"
              : user.gender == "Male"
              ? "Male"
              : user.gender == "male"
              ? "Male"
              : user.gender == "f"
              ? "Female"
              : user.gender == "F"
              ? "Female"
              : user.gender == "female"
              ? "Female"
              : user.gender == "Female"
              ? "Female"
              : user.gender ? user.gender.toString() : "-"
          }, ${user.nationality ? user.nationality.toString() : "-"}`}</Box>
          <br/>
          <Box fontSize={16} fontWeight={600}>
             Disease
          </Box>
          <Box fontSize={14}>{`${user?.health?.disease || "-"}`}</Box>
        </Box>
      </Box>
    );
  };
  