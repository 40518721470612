import { useCallAPISpacetrax } from "../../useCallAPI";

export type FormInput = {
    file: File;
  };

export const DailyFormUpload = () => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    code: 0 | 1;
    message: string;
    data: any;
  }>(
    {
      url: `/v2/dashboard/import/daily-symptom`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    {
      manual: true,
    }
  );
  return {
    result_form_upload: res?.code === 1 ? res?.data : null,
    loading_form_upload: loading,
    error_form_upload: res?.code === 0 ? res?.message : null,
    fire_form_upload: (FormInput: FormInput) => {
      var formData = new FormData();
      formData.append("upload", FormInput.file, FormInput.file.name);
      return new Promise((resolve, reject) => {
        fire({
          data: formData,
        })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
    },
  };
};

export const MentalFormUpload = () => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
      code: 0 | 1;
      message: string;
      data: any;
    }>(
      {
        url: `/v2/dashboard/import/mental`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        manual: true,
      }
    );
    return {
      result_form_upload: res?.code === 1 ? res?.data : null,
      loading_form_upload: loading,
      error_form_upload: res?.code === 0 ? res?.message : null,
      fire_form_upload: (FormInput: FormInput) => {
        var formData = new FormData();
        formData.append("upload", FormInput.file, FormInput.file.name);
        return new Promise((resolve, reject) => {
          fire({
            data: formData,
          })
            .then(({ data: { data, code, message } }) => {
              if (code === 0) {
                reject(message);
              } else {
                resolve(data);
              }
            })
            .catch((ex) => {
              reject(ex.toString());
            });
        });
      },
    };
  };